import Moment from "moment";

export function isNull(val: any): val is null {
    return val === null;
}

export function isUndefined(val: any): val is undefined {
    return (typeof val === 'undefined');
}

export function isZero(val: any): val is 0 {
    return val === 0;
}

export function isEmptyArray(val: any): val is [] {
    return (Array.isArray(val) && (val.length === 0));
}

export function isEmptyString(val: any): val is '' {
    return val === '';
}

export function isNullOrUndefined(val: any): val is undefined | null {
    return isNull(val) || isUndefined(val);
}

export function isNullOrUndefinedOrZero(val: any): val is undefined | null | 0 {
    return isNull(val) || isUndefined(val) || isZero(val);
}

export function isNullOrUndefinedOrEmptyArray(val: any): val is undefined | null | [] {
    return isNull(val) || isUndefined(val) || isEmptyArray(val);
}

export function isNullOrUndefinedOrEmptyString(val: any): val is undefined | null | '' {
    return isNull(val) || isUndefined(val) || isEmptyString(val);
}

export function generateHslaColors (saturation:number, lightness: number, alpha: any, amount: any) {
    let colors:any[] = []
    let huedelta = Math.trunc(360 / amount)
  
    for (let i = 0; i < amount; i++) {
      let hue = i * huedelta
      colors.push(`hsla(${hue},${saturation}%,${lightness}%,${alpha})`)
    }
  
    return colors
}

export function randomHsl() {
    return 'hsla(' + (Math.random() * 360) + ', 100%, 50%, 0.3)';
}
function formatDate(createdAt:any) {
    return Moment(createdAt[Object.keys(createdAt)[0]]) as any;
}
export function sortByDateArray(array: any) {
    return array.sort((a:any,b:any) => {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return (new Date(formatDate(b.created_at as any)) as any - (new Date(formatDate(a.created_at)) as any));
      });
}
import { action } from 'typesafe-actions';

import { IPermission, IUserActionTypes, IUserInfo } from './Types';

export const login = (username: string, password: string) => action(IUserActionTypes.LOGIN, [username, password]);
export const logout = () => action(IUserActionTypes.LOGOUT);
export const loginSuccess = (username: string, token: string, expires: number,  userInfo: IUserInfo, permissions: IPermission) =>
    action(IUserActionTypes.LOGIN_SUCCESS, [username, token, expires, userInfo, permissions]);
export const logoutSuccess = () =>
    action(IUserActionTypes.LOGOUT_SUCCESS);
export const loginError = () => action(IUserActionTypes.LOGIN_ERROR);
export const saveExternalToken = (token: string) => action(IUserActionTypes.SAVE_EXTERNAL_TOKEN, token);
export const verifyExternalToken = () => action(IUserActionTypes.VERIFY_EXTERNAL_TOKEN);
export const verifyExternalTokenError = () => action(IUserActionTypes.VERIFY_EXTERNAL_TOKEN_ERROR);
export const verifyExternalTokenSuccess = (username: string, token: string, expires: number, user_info: any, permissions: any) =>
    action(IUserActionTypes.VERIFY_EXTERNAL_TOKEN_SUCCESS, [username, token, expires, user_info, permissions]);
export const getTokenPlayer = (music: boolean, readjusted: boolean, username: string) => {
    return action(IUserActionTypes.GET_TOKEN_PLAYER, [music, readjusted, username])}
export const getTokenPlayerSuccess = (expires:number, token: string) => {
        return action(IUserActionTypes.GET_TOKEN_PLAYER_SUCCESS, [expires, token])
    }
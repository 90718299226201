import * as bunyan from 'browser-bunyan'

export enum Environment {
  DEV = 'dev',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export interface IConfig {
  environment: Environment

  // Backend API URL
  backendAPIURL: string
  // How many retries we should do for WS reconnection backoff, -1 for unlimited
  // The delay starts at 5 seconds and is doubled every try until capping reached.
  backendRetries: number
  // Backend retry delay capping, in milliseconds
  backendRetriesCapping: number
  // Backend retry starting delay
  backendRetriesStart: number
  // Loglevel
  logLevel: 'trace' | 'debug' | 'info' | 'warn' | 'error' | 'fatal'
  // API for user pwd modifications
  UsersUrl: string
  StreamerUrl: string
  CatalogUrl: string
}

export let Config: IConfig

switch (process.env.REACT_APP_STAGE) {
  case Environment.PRODUCTION:
    // tslint:disable: no-console
    if ('console' in window && typeof console.log === 'function') {
      console.log('Using production build')
    }
    console.log(process.env.REACT_APP_STAGE)
    // tslint:disable: no-console
    Config = {
      // No leading / !
      backendAPIURL: 'https://ws-adtrack-prod.yacast.fr',
      UsersUrl: 'https://users.yacast.fr',
      StreamerUrl: 'https://streamer.yacast.fr/v0/public/file',
      CatalogUrl: 'https://catalog-manager.yacast.fr',
      backendRetries: -1,
      backendRetriesCapping: 1000,
      backendRetriesStart: 1000,
      environment: Environment.PRODUCTION,
      logLevel: 'debug',
    }
    break
  case Environment.STAGING:
    // tslint:disable: no-console
    if ('console' in window && typeof console.log === 'function') {
      console.log('Using development build')
    }
    // tslint:disable: no-console
    Config = {
      // No leading / !
      backendAPIURL: 'https://adtrack-rf-dev-back.yacast.fr',
      backendRetries: -1,
      backendRetriesCapping: 1000,
      backendRetriesStart: 1000,
      environment: Environment.STAGING,
      logLevel: 'debug',
      UsersUrl: 'https://users-stg.yacast.fr',
      StreamerUrl: 'https://streamer-stg.yacast.fr/v0/public/file',
      CatalogUrl: 'https://catalog-manager-stag.yacast.fr',
    }
    break
  case Environment.DEV:
    // tslint:disable: no-console
    if ('console' in window && typeof console.log === 'function') {
      console.log('Using development build')
    }
    // tslint:disable: no-console
    Config = {
      // No leading / !
      backendAPIURL: 'https://adtrack-rf-dev-back.yacast.fr',
      backendRetries: -1,
      backendRetriesCapping: 1000,
      backendRetriesStart: 1000,
      environment: Environment.DEV,
      logLevel: 'debug',
      UsersUrl: 'https://users-stg.yacast.fr',
      StreamerUrl: 'https://streamer-stg.yacast.fr/v0/public/file',
      CatalogUrl: 'https://catalog-manager-stag.yacast.fr',
    }
    break
  default:
    // tslint:disable: no-console
    if ('console' in window && typeof console.log === 'function') {
      console.log('Using dev build')
    }
    // tslint:disable: no-console
    Config = {
      // No leading / !
      backendAPIURL: 'http://192.168.12.226:5000',
      backendRetries: -1,
      backendRetriesCapping: 1000,
      backendRetriesStart: 1000,
      environment: Environment.DEV,
      logLevel: 'debug',
      UsersUrl: 'https://users-stg.yacast.fr',
      StreamerUrl: 'https://streamer-stg.yacast.fr/v0/public/file',
      CatalogUrl: 'https://catalog-manager-stag.yacast.fr',
    }
}

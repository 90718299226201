import { action } from 'typesafe-actions'

import {
  FormulaireActionTypes,
  FormulairesResponse,
  IFormulaireFilters,
  IFormulaireFranceState,
  IFormulaireGemaState,
  IOrderBy,
} from './Types'
import { IUserInfo } from '../User/Types'
import { COUNTRY_OPTIONS, IMedia } from '../Search/Types'
import { IFingerprintBucketPair } from '../SpotDetails/Types'

export const getFormulaireList = (
  page: number,
  perPage: number,
  filters?: IFormulaireFilters,
  orderBy?: IOrderBy,
  refresh_status?: boolean,
) =>
  action(FormulaireActionTypes.GET_FORMULAIRE_LIST, [
    page,
    perPage,
    filters,
    orderBy,
    refresh_status,
  ])
export const getFormulaireListError = () =>
  action(FormulaireActionTypes.GET_FORMULAIRE_LIST_ERROR)
export const getFormulaireListSuccess = (results: FormulairesResponse) =>
  action(FormulaireActionTypes.GET_FORMULAIRE_LIST_SUCCESS, results)

export const getFormulairePreview = (
  regrouping_id: string,
  bucket_id: number,
  spot_id: number,
  fingerprint_list: IFingerprintBucketPair[]
) =>
  action(FormulaireActionTypes.GET_FORMULAIRE_PREVIEW, [
    regrouping_id,
    bucket_id,
    spot_id,
    fingerprint_list,
  ])
export const getFormulairePreviewError = () =>
  action(FormulaireActionTypes.GET_FORMULAIRE_PREVIEW_ERROR)
export const getFormulairePreviewSuccess = (
  regrouping_id: string,
  bucketId: number,
  results: any,
  user: IUserInfo,
  fingerprint_list: IFingerprintBucketPair[],
  media: IMedia,
  identicSpots: any,
  country: COUNTRY_OPTIONS
) =>
  action(FormulaireActionTypes.GET_FORMULAIRE_PREVIEW_SUCCESS, [
    regrouping_id,
    bucketId,
    results,
    user,
    fingerprint_list,
    media,
    identicSpots,
    country,
  ])

export const fetchFormulaire = (fingerprint_list: IFingerprintBucketPair[]) =>
  action(FormulaireActionTypes.FETCH_FORMULAIRE, fingerprint_list)
export const fetchFormulaireError = () =>
  action(FormulaireActionTypes.FETCH_FORMULAIRE_ERROR)
export const fetchFormulaireSuccess = (
  results: any,
  country: COUNTRY_OPTIONS
) => action(FormulaireActionTypes.FETCH_FORMULAIRE_SUCCESS, [results, country])
export const fetchFormulaireSuccessPDF = (
  resultPDF
) => action(FormulaireActionTypes.FETCH_FORMULAIRE_SUCCESS_PDF, resultPDF)
export const fetchFormulaireFailurePDF = (
) => action(FormulaireActionTypes.FETCH_FORMULAIRE_FAILURE_PDF)

export const saveFormulaire = (
  params: IFormulaireFranceState | IFormulaireGemaState
) => action(FormulaireActionTypes.SAVE_FORMULAIRE, params)
export const saveFormulaireError = (message: any) =>
  action(FormulaireActionTypes.SAVE_FORMULAIRE_ERROR, message)
export const saveFormulaireSuccess = (results: any, country: COUNTRY_OPTIONS) =>
  action(FormulaireActionTypes.SAVE_FORMULAIRE_SUCCESS, [results, country])

export const downloadPdf = () => action(FormulaireActionTypes.DOWNLOAD_PDF)

export const setMediaName = (mediaInfo: IMedia) =>
  action(FormulaireActionTypes.SET_MEDIA_NAME, mediaInfo)
export const fetchExportsDeclarations = (
  perPage?: number,
  filters?: IFormulaireFilters,
  orderBy?: IOrderBy
) =>
  action(FormulaireActionTypes.FETCH_EXPORTS_DECLARATIONS, [
    perPage,
    filters,
    orderBy,
  ])
export const exportsDeclarationsSuccess = (res: any) =>
  action(FormulaireActionTypes.EXPORTS_DECLARATIONS_SUCCESS, res)
export const exportsDeclarationsError = () =>
  action(FormulaireActionTypes.EXPORTS_DECLARATIONS_ERROR)
export const cleanFileDownload = () =>
  action(FormulaireActionTypes.CLEAN_FILE_DOWNLOAD)

import { Reducer } from 'redux';
import Moment from 'moment';

import { Logger } from '../../Utils/Logger';
import { TypedStorage } from '../../Utils/TypedStorage';
import {
    ISpotDetailsActionTypes,
    ISpotDetailsState,
    CSpotDetailsInitialState,
    IBroadcastPassage,
    ESpotDetailsSource,
    ISpotDetailsRequest,
    CSpotMetadatasInfoInitialState,
    CMetadatasDetailsInitialState,
    IFingerprintBucketPair,
    IBroadcastPassageWeb
} from './Types';
import {
    CMediaInitialState,
    IAdvertiser,
    IMusicBroadcasts,
    ISpotRegroupement,
} from '../Search/Types';
import { isNullOrUndefined } from '../../Utils/Various';
import { dateDiff } from '../../Utils/Time';

const savedSpotDetailsRequest: ISpotDetailsRequest | null = TypedStorage.get('savedSpotDetailsRequest', null);

let initialState = { ...CSpotDetailsInitialState };

if (savedSpotDetailsRequest !== null) {
    Logger.info('Reusing previous search state');
    initialState = {
        ...initialState,
        ...savedSpotDetailsRequest,
    };
}


const reducer: Reducer<ISpotDetailsState> = (state = initialState, action) => {
    switch (action.type) {
        case ISpotDetailsActionTypes.GET_BROADCASTS_PASSAGES: {
            if (action.payload && action.payload.length === 3) {
                return {
                    ...state,
                    isLoadingBroadcastPassages: true,
                    startDate: action.payload[1],
                    endDate: action.payload[2],
                    broadcastPassagesCount: 0,
                    medias: []
                };
            }
            return state;
        }
        case ISpotDetailsActionTypes.GET_BROADCASTS_PASSAGES_SUCCESS: {
            if (action.payload) {
                return {
                    ...state,
                    isLoadingBroadcastPassages: false,
                    broadcastPassages: action.payload[0] as IBroadcastPassage[],
                    broadcastPassagesCount: action.payload[1],
                    medias: action.payload[2]
                };
            }

            return state;
        }
        case ISpotDetailsActionTypes.GET_BROADCASTS_PASSAGES_ERROR: {
            return {
                ...state,
                isLoadingBroadcastPassages: false,
                broadcastPassages: [],
                broadcastPassagesCount: 0,
                medias: []
            };
        }
        case ISpotDetailsActionTypes.GET_BROADCASTS_WEB_PASSAGES: {
            if (action.payload) {
                return {
                    ...state,
                    isLoadingBroadcastPassagesWeb: true,
                    startDate: action.payload[1],
                    endDate: action.payload[2],
                    broadcastPassagesWebCount: 0
                };
            }
            return state;
        }
        case ISpotDetailsActionTypes.GET_BROADCASTS_WEB_PASSAGES_SUCCESS: {
            if (action.payload) {
                return {
                    ...state,
                    isLoadingBroadcastPassagesWeb: false,
                    broadcastPassagesWeb: action.payload[0] as IBroadcastPassageWeb[],
                    broadcastPassagesWebCount: action.payload[1]
                }
            }
            return state;
        }
        case ISpotDetailsActionTypes.GET_BROADCASTS_WEB_PASSAGES_ERROR: {
            return {
                ...state,
                isLoadingBroadcastPassagesWeb: false,
                broadcastPassagesWeb: [],
                broadcastPassagesWebCount: 0
            }
        }
        case ISpotDetailsActionTypes.SET_MEDIA_NAME: {
            if (action.payload) {
                return {
                    ...state,
                    metadatas: {
                        ...state.metadatas,
                        media_id: action.payload.id,
                        media: {
                            ...CMediaInitialState,
                            ...action.payload
                        }
                    }
                };
            }
            return state;
        }
        case ISpotDetailsActionTypes.SET_MEDIA_NAME: {
            if (action.payload) {
                return {
                    ...state,
                    metadatas: {
                        ...state.metadatas,
                        media_id: action.payload.id,
                        media: {
                            ...CMediaInitialState,
                            ...action.payload
                        }
                    }
                };
            }
            return state;
        }
        case ISpotDetailsActionTypes.SET_ADVERTISER: {
            if (action.payload && state.metadatas.metadatas) {
                let advertiser: IAdvertiser = action.payload;
                return {
                    ...state,
                    metadatas: {
                        ...state.metadatas,
                        metadatas: {
                            ...state.metadatas.metadatas,
                            advertiser: {
                                address: advertiser.address || "",
                                city: advertiser.city || "",
                                code: advertiser.code || "",
                                country_code: advertiser.country_code || "",
                                name: advertiser.name || "",
                                tva_number: advertiser.tva_number || ""
                            }
                        }
                    }
                };
            }
            return state;
        }
        case ISpotDetailsActionTypes.SET_ADVERTISER: {
            if (action.payload && state.metadatas.metadatas) {
                let advertiser: IAdvertiser = action.payload;
                return {
                    ...state,
                    metadatas: {
                        ...state.metadatas,
                        metadatas: {
                            ...state.metadatas.metadatas,
                            advertiser: {
                                address: advertiser.address || "",
                                city: advertiser.city || "",
                                code: advertiser.code || "",
                                country_code: advertiser.country_code || "",
                                name: advertiser.name || "",
                                tva_number: advertiser.tva_number || ""
                            }
                        }
                    }
                };
            }
            return state;
        }
        case ISpotDetailsActionTypes.SET_SPOT_FINGERPRINT_BUCKET_LIST: {
            if (action.payload) {
                return {
                    ...state,
                    fingerprintBucketList: action.payload
                }
            } else return { ...state }
        }

        case ISpotDetailsActionTypes.SET_SPOT_DETAILS: {
            if (action.payload) {
                const spotRegroupement = action.payload[0] as ISpotRegroupement;
                const startDate = action.payload[1];
                const endDate = action.payload[2];

                const ads = spotRegroupement.ads;

                if (!(ads && ads.metadatas && ads.metadatas.length > 0)) {
                    return state;
                }
                const spotDetails = ads.metadatas[0];
                const spotId = spotDetails.fingerprint_id;

                let fingerprintBucketList: IFingerprintBucketPair[] = []

                for (let i = 0; i < ads.metadatas.length; i += 1) {
                    fingerprintBucketList.push({
                        fgp_id: ads.metadatas[i].fingerprint_id,
                        bucket_id: ads.metadatas[i].bucket_id
                    })
                }

                const savedSpotDetailsRequest: ISpotDetailsRequest = {
                    spotId: spotId,
                    bucketId: spotDetails.bucket_id,
                }

                TypedStorage.set('savedSpotDetailsRequest', savedSpotDetailsRequest);

                return {
                    ...state,
                    broadcastPassagesCount: 0,
                    broadcastPassagesWebCount: 0,
                    medias: [],
                    bucketId: spotDetails.bucket_id,
                    groupId: -1,
                    fingerprint_id: spotId,
                    startDate: startDate,
                    endDate: endDate,
                    metadatas: CSpotMetadatasInfoInitialState,
                    broadcastPassages: [],
                    broadcastPassagesWeb: [],
                    isLoadingBroadcastPassages: false,
                    isLoadingBroadcastPassagesWeb: false,
                    spotDetailsLoading: false,
                    formulaireList: [],
                    loadingFormulaireList: false,
                    identicSpots: [],
                    identicSpotsNielsen: [],
                    fingerprintBucketList: fingerprintBucketList,
                    regrouping_id: spotRegroupement.id,
                    selectedMusicID: spotId.toString(),
                    regions: {},
                    surfer: null,
                }
            }
            return state;
        }

        case ISpotDetailsActionTypes.GET_IDENTIC_SPOTS_SUCCESS: {
            if (action.payload) {
                return {
                    ...state,
                    identicSpots: action.payload
                }
            }
            return state;
        }
        case ISpotDetailsActionTypes.GET_IDENTIC_SPOTS_NIELSEN_SUCCESS: {
            if (action.payload) {
                return {
                    ...state,
                    identicSpotsNielsen: action.payload
                }
            }
            return state;
        }
        case ISpotDetailsActionTypes.GET_SPOT_FORMULAIRES: {
            if (action.payload) {
                return {
                    ...state,
                    formulaireList: [],
                    loadingFormulaireList: true
                }
            }
            return state;
        }
        case ISpotDetailsActionTypes.GET_SPOT_FORMULAIRES_SUCCESS: {
            if (action.payload) {
                return {
                    ...state,
                    formulaireList: action.payload,
                    loadingFormulaireList: false
                }
            }
            return state;
        }

        case ISpotDetailsActionTypes.GET_SPOT_DETAILS: {
            return {
                ...CSpotDetailsInitialState,
                spotDetailsLoading: true
            };
        }

        case ISpotDetailsActionTypes.GET_SPOT_DETAILS_ERROR: {
            return {
                ...state,
                spotDetailsLoading: false,
                metadatas: CSpotMetadatasInfoInitialState
            }
        }
        case ISpotDetailsActionTypes.GET_SPOT_DETAILS_SUCCESS: {
            if (action.payload) {
                if (action.payload.length > 1) {
                    const bucketId = action.payload[0];
                    const result = action.payload[1][0];
                    const groupId = action.payload[2];
                    let creative_agency_metadata = result.creative_agency_metadata;
                    let duration = 0;
                    let start_date = '';
                    let end_date = '';
                    let agency_name = '';
                    let advertiser_address = '';
                    let advertiser_city = '';
                    let advertiser_code = '';
                    let advertiser_country_code = '';
                    let advertiser_name = '';
                    let advertiser_tva_number = '';
                    let fgp_id: number = 0;
                    let group_id = -1;
                    let film_id_label = '';
                    let product_code = '';
                    let product_label = '';
                    let title = '';
                    let filepath = '';
                    let broadcasts = result.group ? result.group.broadcasts : null;
                    let broadcast: any = null;
                    if (broadcasts !== null && broadcasts !== undefined) {
                        broadcast = broadcasts[0];
                    }
                    if (bucketId === ESpotDetailsSource.KANTAR) {
                        broadcast = result.broadcast
                    }
                    if (bucketId === ESpotDetailsSource.FR_RADIO) {
                        let broadcasts = result.group ? result.group.music_broadcasts : null;
                        broadcast = broadcasts[0];
                    }
                    // France
                    if (!isNullOrUndefined(creative_agency_metadata) && creative_agency_metadata.duration) {
                        duration = creative_agency_metadata.duration
                    } else if (broadcast !== null && broadcast !== undefined) {
                        duration = dateDiff(new Date(broadcast.end_date), new Date(broadcast.start_date)).sec;
                    }
                    if (bucketId === ESpotDetailsSource.FR_RADIO) {
                        duration = result.duration
                    }
                    if (broadcast !== null && broadcast !== undefined) {
                        fgp_id = broadcast.fgp_id === null ? result.id : broadcast.fgp_id;
                        start_date = broadcast.start_date;
                        end_date = broadcast.end_date;
                    }
                    if (!result.advertiser) {
                        if (result.creative_agency_metadata) {

                            title = result.creative_agency_metadata.title;

                            if (result.creative_agency_metadata.product) {
                                product_code = result.creative_agency_metadata.product.product_code;
                                product_label = result.creative_agency_metadata.product.internal_name;
                            }
                            if (result.creative_agency_metadata.advertiser) {
                                advertiser_address = result.creative_agency_metadata.advertiser.address1 + ' ' + result.creative_agency_metadata.advertiser.address2;
                                advertiser_city = result.creative_agency_metadata.advertiser.city;
                                advertiser_code = result.creative_agency_metadata.advertiser.postal_code;
                                advertiser_country_code = result.creative_agency_metadata.advertiser.country_code;
                                advertiser_name = result.creative_agency_metadata.advertiser.internal_name;
                                if (result.creative_agency_metadata.advertiser.national_company_identifier && result.creative_agency_metadata.advertiser.national_company_identifier.vat_number) {
                                    advertiser_tva_number = result.creative_agency_metadata.advertiser.national_company_identifier.vat_number;
                                }
                            }

                            if (result.creative_agency_metadata.creative_agency && result.creative_agency_metadata.creative_agency.internal_name) {
                                agency_name = result.creative_agency_metadata.creative_agency.internal_name;
                            }
                        }

                        film_id_label = result.filmid_label;
                        if (!isNullOrUndefined(result.group_id)) {
                            group_id = result.group_id;
                        }

                        if (bucketId === ESpotDetailsSource.ARPP) {
                            filepath = result.master_video_filepath === null ? result.master_filepath : result.master_video_filepath;
                            if (result.group) {
                                filepath = result.group.master_video_filepath === null ? result.group.master_filepath : result.group.master_video_filepath;
                            }
                        } else if (bucketId === ESpotDetailsSource.KANTAR || bucketId === ESpotDetailsSource.FR_RADIO) {
                            filepath = (result.master_filepath).includes('/media/') ?
                                "/" + (result.master_filepath).split('/media/')[1] : result.master_filepath;
                        } else filepath = result.master_filepath;
                        let music_state: any = result.state;
                        let details: any = {
                            bucket_id: bucketId,
                            bucket_name: '',
                            broadcast: broadcast,
                            duration: duration,
                            has_formulaire: false,
                            start_date: Moment(start_date),
                            end_date: Moment(end_date),
                            filepath,
                            thumbnail_path: result.group ? result.group.master_video_thumbnail : "",
                            id: -1,
                            media: CMediaInitialState,
                            media_id: -1,
                            metadatas: {
                                ...CMetadatasDetailsInitialState,
                                first_broadcast: Moment(start_date),
                                agency_name: agency_name,
                                advertiser: {
                                    address: advertiser_address,
                                    city: advertiser_city,
                                    code: advertiser_code,
                                    country_code: advertiser_country_code,
                                    name: advertiser_name,
                                    tva_number: advertiser_tva_number,
                                },
                                music_broadcast: result.group ? result.group.music_broadcasts : [],
                                duration,
                                group_id,
                                film_id_label,
                                product_code,
                                product_label,
                                title: title
                            }
                        }
                        if (music_state) {
                            details = {
                                ...details,
                                music_state: music_state
                            }
                        }
                        return {
                            ...state,
                            //fingerprint_id: fgp_id,
                            metadatas: details,
                            spotDetailsLoading: false,
                            selectedMusicID: fgp_id.toString()
                        }
                    } else if (result.advertiser) {
                        // Germany
                        let start_date = Moment(result.first_air_date);
                        let end_date = start_date.add(duration, 'seconds');
                        agency_name = result.creative_agency !== null ? result.creative_agency.title : '';
                        duration = result.duration;
                        advertiser_name = result.advertiser.title;
                        product_label = result.product.name;
                        title = result.ad_title;
                        let music_broadcasts = result.music_broadcasts || [];
                        if (result.group && result.group.music_broadcasts && result.group.music_broadcasts.length > 0) {
                            music_broadcasts = result.group.music_broadcasts.filter((x: any) => x.music_state !== "DELETED")
                        }
                        let details = {
                            bucket_id: -1,
                            bucket_name: '',
                            duration: result.duration,
                            has_formulaire: false,
                            start_date: Moment(result.first_air_date),
                            end_date: end_date,
                            filepath: result.master_path,
                            thumbnail_path: result.master_video_thumbnail,
                            id: result.id,
                            media: {
                                id: -1,
                                codeiso: '',
                                country: '',
                                name: '',
                                type: result.media_type,
                            },
                            media_id: -1,
                            metadatas: {
                                ...CMetadatasDetailsInitialState,
                                first_broadcast: Moment(result.first_air_date),
                                agency_name: agency_name,
                                advertiser: {
                                    address: advertiser_address,
                                    city: advertiser_city,
                                    code: advertiser_code,
                                    country_code: advertiser_country_code,
                                    name: advertiser_name,
                                    tva_number: advertiser_tva_number,
                                },
                                music_broadcast: music_broadcasts,
                                duration,
                                // group_id,
                                // film_id_label,
                                // product_code,
                                product_label,
                                title
                            },
                            music_state: result.group ? result.group.state : ''
                        }
                        return {
                            ...state,
                            fingerprint_id: result.id,
                            groupId: groupId,
                            metadatas: details,
                            spotDetailsLoading: false,
                            selectedMusicID: (result.id).toString()
                        }
                    }
                }
            }
            return {
                ...state,
                spotDetailsLoading: false,
                metadatas: CSpotMetadatasInfoInitialState
            }
        }

        case ISpotDetailsActionTypes.SET_SPOT_START_DATE: {
            if (state.metadatas.metadatas !== undefined) {
                return {
                    ...state,
                    metadatas: {
                        ...state.metadatas,
                        metadatas: {
                            ...state.metadatas.metadatas,
                            start_date: action.payload
                        }
                    }
                }
            } else return state
        }
        case ISpotDetailsActionTypes.SET_MUSIC_BROADCAST: {
            let newState = { ...state }
            if (newState.metadatas.metadatas) {
                newState.metadatas.metadatas.music_broadcast = action.payload
            }
            return newState
        }

        case ISpotDetailsActionTypes.SELECT_MUSIC: {
            const { id } = action.payload;
            return {
                ...state,
                selectedMusicID: id
            };
        }
        case ISpotDetailsActionTypes.SET_SPOT_FGPID: {
            const id = action.payload;
            return {
                ...state,
                fingerprint_id: id
            };
        }
        case ISpotDetailsActionTypes.UPDATE_SELECTED_MUSIC_INTERVAL: {
            const { id, start, end } = action.payload;
            if (state && state.metadatas.metadatas !== undefined) {
                let music_broadcast = state.metadatas.metadatas.music_broadcast
                let metadatas = { ...state.metadatas }
                let music = [...state.metadatas.metadatas.music_broadcast];
                for (let i = 0; i < music_broadcast.length; i += 1) {
                    if (music[i].id === id && metadatas.metadatas !== undefined) {
                        music[i].start_time = parseFloat(start.toFixed(2));
                        music[i].end_time = parseFloat(end.toFixed(2));
                        metadatas.duration = parseFloat((end - start).toFixed(2));
                        metadatas.metadatas.music_broadcast = music
                        let newState = {
                            ...state,
                            metadatas: metadatas
                        }
                        //music_broadcast.sort((a: IMusicBroadcasts, b: IMusicBroadcasts) => parseInt(a.start_time) - parseInt(b.start_time))

                        if (newState.metadatas.metadatas !== undefined) newState.metadatas.metadatas.music_broadcast = newState.metadatas.metadatas.music_broadcast.sort((a: IMusicBroadcasts, b: IMusicBroadcasts) => a.start_time - b.start_time)
                        return newState;
                    }
                }
            }
            return state;
        }
        case ISpotDetailsActionTypes.SAVE_REGIONS_SURFER: {
            return {
                ...state,
                regions: action.payload[0],
                surfer: action.payload[1]
            }
        }
        case ISpotDetailsActionTypes.DOWNLOAD_CSV: {
            return {
                ...state,
                file_downloaded: null
            }
        }
        case ISpotDetailsActionTypes.DOWNLOAD_CSV_SUCCESS: {
            return {
                ...state,
                file_downloaded: action.payload
            }
        }
        default: {
            return state;
        }

    }
};

export { reducer as SpotDetailsReducer };

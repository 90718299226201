import * as MD from '@material-ui/core'
import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import CloseIcon from '@material-ui/icons/Close'

import { IApplicationState } from '../Store'
import { INotificationBarState } from '../Store/NotificationBar/Types'
import {
  setError,
  setInfoMessage,
  setSuccessMessage,
} from '../Store/NotificationBar/Actions'

import { getTheme } from '../Themes'
import withStyles, { WithStyles } from '@material-ui/styles/withStyles'
const theme = getTheme()

const styles = MD.createStyles({
  closeIcon: {
    color: '#fff',
  },
  error: {
    background: theme.Adtrack.colors.snackErrorBG,
  },
  errorMessage: {
    color: theme.Adtrack.colors.snackErrorText,
  },
  info: {
    background: theme.Adtrack.colors.snackInfoBG,
  },
  infoMessage: {
    color: theme.Adtrack.colors.snackInfoText,
  },
  success: {
    background: theme.Adtrack.colors.snackSuccessBG,
  },
  successMessage: {
    color: theme.Adtrack.colors.snackSuccessText,
  },
})

interface IPropsFromState {
  notificationBar: INotificationBarState
}

interface IPropsFromDispatch {
  setError: typeof setError
  setInfoMessage: typeof setInfoMessage
  setSuccessMessage: typeof setSuccessMessage
}

interface IState {}

type AllProps = WithStyles<typeof styles> & IPropsFromState & IPropsFromDispatch

class NotificationBarComponent extends React.Component<AllProps, IState> {
  private handleSnackBarClose = () => {
    if (this.props.notificationBar.error !== null) {
      this.props.setError(null)
    }
    if (this.props.notificationBar.successMessage !== null) {
      this.props.setSuccessMessage(null)
    }
    if (this.props.notificationBar.infoMessage !== null) {
      this.props.setInfoMessage(null)
    }
  }

  public render(): React.ReactNode {
    const { classes, notificationBar } = this.props
    let opened = false
    let notificationClass = classes.success
    let message = ''
    let messageClass = classes.successMessage

    if (notificationBar.error !== null) {
      notificationClass = classes.error
      messageClass = classes.errorMessage
      message = notificationBar.error.message
    } else if (notificationBar.successMessage !== null) {
      message = notificationBar.successMessage
      notificationClass = classes.success
    } else if (notificationBar.infoMessage !== null) {
      message = notificationBar.infoMessage
      notificationClass = classes.info
    }

    if (
      notificationBar.error !== null ||
      notificationBar.successMessage !== null ||
      notificationBar.infoMessage
    ) {
      opened = true
    }
    return (
      <MD.Snackbar
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        autoHideDuration={3000}
        TransitionProps={{ onExited: this.handleSnackBarClose.bind(this) }}
        onClose={this.handleSnackBarClose}
        open={opened}
      >
        <MD.SnackbarContent
          className={notificationClass}
          action={[
            <MD.IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={this.handleSnackBarClose}
            >
              <CloseIcon className={classes.closeIcon} />
            </MD.IconButton>,
          ]}
          message={<span className={messageClass}>{message}</span>}
        />
      </MD.Snackbar>
    )
  }
}

const mapStateToProps = ({ notificationBar }: IApplicationState) => ({
  notificationBar,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setError: (message: Error | null) => dispatch(setError(message)),
  setInfoMessage: (message: string | null) => dispatch(setInfoMessage(message)),
  setSuccessMessage: (message: string | null) =>
    dispatch(setSuccessMessage(message)),
})

export const NotificationBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NotificationBarComponent))

import * as effects from 'redux-saga/effects'

import { Backend } from '../../Backend/Backend'
import { Logger } from '../../Utils/Logger'
import { TypedStorage } from '../../Utils/TypedStorage'
import { setAuthError } from '../NotificationBar/Actions'
import { searchReinitParams, updateCountryParams } from '../Search/Actions'

import {
  getTokenPlayer,
  getTokenPlayerSuccess,
  login,
  loginError,
  loginSuccess,
  verifyExternalToken,
  verifyExternalTokenError,
  verifyExternalTokenSuccess,
} from './Actions'
import { logout, logoutSuccess } from './Actions'
import {
  CPermissionInitial,
  initialUserInfo,
  IPermission,
  IUserActionTypes,
  IUserInfo,
} from './Types'
import jwt from 'jwt-decode'
import { CInitialSearchParams } from '../Search/Types'

function* handleLogin(action: ReturnType<typeof login>) {
  const backend = Backend.getInstance()

  try {
    const res = yield effects.apply(
      backend,
      backend.login,
      action.payload as any
    )

    Logger.info('Successfully logged in')
    let userResult = res[2]
    let userInfo: IUserInfo = TypedStorage.get('userInfo', initialUserInfo)
    let permissions: IPermission = CPermissionInitial
    if (userResult) {
      userInfo = userResult.user_info
      // TODO Remove filter on "Array" after fix bug on userManagement
      let localisation: any = userInfo.localisation
      if (
        localisation &&
        localisation.length > 0 &&
        localisation.indexOf('Array') > -1
      ) {
        localisation = localisation.filter((x: any) => x !== 'Array')
        userInfo = {
          ...userInfo,
          localisation,
        }
      }
      let country: any = userInfo.country
      if (country && country.length > 0 && country.indexOf('Array') > -1) {
        country = localisation.filter((x: any) => x !== 'Array')
        userInfo = {
          ...userInfo,
          country,
        }
      }
      yield effects.put(updateCountryParams(country[0]))
      // END TODO
      permissions = userResult.permissions
    }
    yield effects.put(
      loginSuccess(action.payload[0], res[0], res[1], userInfo, permissions)
    )
    // yield effects.put(getTokenPlayer(true, true, TypedStorage.get('userName','')))
    yield effects.put(setAuthError(null))
  } catch (err) {
    yield effects.put(setAuthError(err))
    Logger.warn({ err }, 'Failed login')
    yield effects.put(loginError())
  }
}

function* handleLogout(action: ReturnType<typeof logout>) {
  const backend = Backend.getInstance()

  try {
    yield effects.apply(backend, backend.logout, [])
    TypedStorage.set('savedSearchedParamsState', CInitialSearchParams)
    Logger.info('Successfully logged out')
    yield effects.put(logoutSuccess())
  } catch (err) {
    Logger.warn({ err }, 'Failed logout')
    yield effects.put(loginError())
  }
}
function* handleLogoutSuccess(action: ReturnType<typeof logoutSuccess>) {
  TypedStorage.reset()
  yield effects.put(searchReinitParams())
}
function* handleVerifyExternalToken(
  action: ReturnType<typeof verifyExternalToken>
) {
  const backend = Backend.getInstance()
  try {
    let tokenExternal = yield effects.select(
      (state) => state.user.tokenExternal
    )
    if (tokenExternal !== null) {
      const res = yield effects.apply(backend, backend.verifyToken, [
        tokenExternal,
      ])
      let token_decoded: any = jwt(res['token'])
      if (
        res.hasOwnProperty('token') &&
        token_decoded.hasOwnProperty('username') &&
        token_decoded.hasOwnProperty('exp') &&
        res.hasOwnProperty('results')
      ) {
        let userInfo: any = res.results.user_info
        let permissions: any = res.results.permissions
        yield effects.put(
          verifyExternalTokenSuccess(
            token_decoded['username'],
            res.token,
            token_decoded['exp'] * 1000,
            userInfo,
            permissions
          )
        )
      } else {
        yield effects.put(verifyExternalTokenError())
      }
    } else {
      yield effects.put(verifyExternalTokenError())
    }
  } catch (err) {
    Logger.warn({ err }, 'Failed token verification')
    yield effects.put(verifyExternalTokenError())
  }
}

function* handleVerifyExternalTokenError(
  action: ReturnType<typeof verifyExternalTokenError>
) {
  yield effects.put(loginError())
  window.document.location.href = '/'
}

function* handleVerifyExternalTokenSuccess(
  action: ReturnType<typeof verifyExternalTokenSuccess>
) {
  const username = action.payload[0] as string
  const token = action.payload[1] as string
  const expires = action.payload[2] as number
  const user_info = action.payload[3] as any
  const permissions = action.payload[4] as any
  yield effects.put(
    loginSuccess(username, token, expires, user_info, permissions)
  )
}
function* handleGetTokenPlayer(action: ReturnType<typeof getTokenPlayer>) {
  const backend = Backend.getInstance()
  try {
    const res = yield effects.apply(
      backend,
      backend.getTokenPlayer,
      action.payload as any
    )

    Logger.info('Successfully get TokenPlayer')

    if (res) {
      yield effects.put(getTokenPlayerSuccess(res.expires, res.token))
    }
  } catch (err) {
    Logger.warn({ err }, 'Failed getTokenPlayer')
    yield effects.put(loginError())
  }
}
function* watchLogin() {
  yield effects.takeEvery(IUserActionTypes.LOGIN, handleLogin)
  yield effects.takeEvery(IUserActionTypes.LOGOUT, handleLogout)
  yield effects.takeEvery(IUserActionTypes.LOGOUT_SUCCESS, handleLogoutSuccess)
  yield effects.takeEvery(
    IUserActionTypes.VERIFY_EXTERNAL_TOKEN,
    handleVerifyExternalToken
  )
  yield effects.takeEvery(
    IUserActionTypes.VERIFY_EXTERNAL_TOKEN_ERROR,
    handleVerifyExternalTokenError
  )
  yield effects.takeEvery(
    IUserActionTypes.VERIFY_EXTERNAL_TOKEN_SUCCESS,
    handleVerifyExternalTokenSuccess
  )
  yield effects.takeEvery(
    IUserActionTypes.GET_TOKEN_PLAYER,
    handleGetTokenPlayer
  )
}

export function* UserSaga() {
  yield effects.all([effects.fork(watchLogin)])
}

import * as MD from '@material-ui/core'
import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import Moment from 'moment'

// Material components
import MuiTablePagination from '@material-ui/core/TablePagination'
import MuiDialogTitle from '@material-ui/core/DialogTitle'

//Icons
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import RefreshIcon from '@material-ui/icons/Refresh'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import DoneIcon from '@material-ui/icons/Done'
import SendIcon from '@material-ui/icons/Send'
import HelpIcon from '@material-ui/icons/Help'
import EditIcon from '@material-ui/icons/Edit'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import GetAppIcon from '@material-ui/icons/GetApp'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import MoreIcon from '@material-ui/icons/More'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import RadioIcon from '@material-ui/icons/Radio'
import TvIcon from '@material-ui/icons/Tv'

import { I18N, ILocaleInfos } from '../../Store/I18n/Types'
import { IApplicationState } from '../../Store'
import {
  CustomTooltip,
  TableCell,
  TableHeaderCell
} from '../../Themes/StyledElements'
import { AdtrackTheme, getTheme } from '../../Themes'

//Router
import { StyledLink } from '../../Themes/StyledElements'
import {
  IFormulaireList, IOrderBy,
  IOrder,
  IFormulaireFilters,
  Formulaire
} from '../../Store/Formulaire/Types'
import {
  getFormulaireList,
  fetchFormulaire,
  fetchExportsDeclarations,
  cleanFileDownload,
} from '../../Store/Formulaire/Actions'
import {
  IFingerprintBucketPair
} from '../../Store/SpotDetails/Types'
import {
  COUNTRY_OPTIONS, ISearchState
} from '../../Store/Search/Types'
import withStyles, { WithStyles } from '@material-ui/styles/withStyles'
import { withTheme } from '@material-ui/styles'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import { ILayoutState } from '../../Store/Layout/Types'
import { isNullOrUndefined } from '../../Utils/Various'
// Date Pickers
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import DateFnsUtils from '@date-io/date-fns'
import { enGB, fr, de } from 'date-fns/locale'
import { getDateHourZero } from '../../Utils/Time'
import { hasPermissionExportDeclarations } from '../../Utils/Rights'
import { IUserState } from '../../Store/User/Types'
import { PaperComponent } from '../HelpFunction'

const theme = getTheme()

const styles = MD.createStyles({
  searchButton: {
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '5px',
    paddingBottom: '5px',
    cursor: 'pointer',
  },
  datePickerInput: {
    border: '1px solid gray',
    borderRadius: '4px',
    color: theme.palette.text.secondary,
    marginBottom: '-1px',
    width: '100%',
  },
  datePickerLabel: {
    fontSize: '13px',
    fontWeight: 300,
  },
  containerDivText: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    textTransform: 'uppercase',
    marginTop: '20px',
    marginBottom: '20px',
    fontSize: '16px',
  },
  inputLabelPropsStyle: {
    top: '-5px',
    fontSize: '11px',
    padding: '0',
    height: '15px',
  },
  inputPropsStyle: {
    height: '33px',
    fontSize: '12px',
    padding: '0 11px',
  },
  smallX: {
    width: '10%',
    textAlign: 'center',
  },
  small: {
    width: '20%',
    textAlign: 'center',
  },
  textAlign: {
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '5px',
    color: theme.palette.primary.main,
  },
  contentTableRow: {
    borderCollapse: 'separate',
    borderRadius: '5px',
    marginBottom: '1px',
  },
  detailsIcon: {
    transform: 'rotate(0.5turn) rotateX(45deg)',
    display: 'block',
    margin: '0 auto',
  },
  iconStyle: {
    fontSize: '17px',
  },
  paperOverflow: {
    maxHeight: '81vh',
    overflow: 'auto',
    background: 'none',
  },
  videoPlayer: {
    maxWidth: '100%',
    margin: '0 auto',
    marginTop: '20px',
    display: 'block',
  },
})

interface IPropsFromState {
  i18n: I18N
  formulaireList: IFormulaireList
  searchResults: ISearchState
  layout: ILayoutState
  localeInfos: ILocaleInfos
  user: IUserState
}

interface IPropsTheme {
  theme: any
}

interface IPropsFromDispatch {
  getFormulaireList: typeof getFormulaireList
  fetchFormulaire: typeof fetchFormulaire
  fetchExportsDeclarations: typeof fetchExportsDeclarations
  cleanFileDownload: typeof cleanFileDownload
}

type AllProps = WithStyles<typeof styles> &
  IPropsFromState &
  IPropsTheme &
  IPropsFromDispatch

interface IState {
  perPage: number
  currentPage: number
  videoDialogOpened: boolean
  currentVideoPlayingSource: string | null
  order: IOrder
  orderBy: string
  openedCriteriaPanel: boolean
  codeArpp: any
  product: any
  email: any
  codePubID: any
  user_group: any
  openDialogDownloadCSV: boolean
  startDate: Date | null
  endDate: Date | null
}

interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  totalPages: number
  i18n: I18N
  theme: any
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void
}

const TablePagination = withStyles({
  toolbar: {
    padding: 0,
  },
})(MuiTablePagination)

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
  i18n: I18N
  theme: AdtrackTheme
  layout: any
}

const DialogTitle = MD.withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, i18n, onClose, theme, layout, ...other } = props
  return (
    <MuiDialogTitle
      {...other}
      style={{
        cursor: 'move',
        color: theme.palette.primary.main,
        padding: theme.spacing(2),
        textAlign: 'center',
      }}
    >
      <MD.Typography>{children}</MD.Typography>
      {onClose ? (
        <MD.Tooltip title={i18n._('Close')}>
          <MD.IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseRoundedIcon />
          </MD.IconButton>
        </MD.Tooltip>
      ) : null}
    </MuiDialogTitle>
  )
})

export function TablePaginationActions(props: TablePaginationActionsProps) {
  // const { count, page, rowsPerPage, onPageChange } = props;
  const { count, page, rowsPerPage, totalPages, onPageChange, theme } = props

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (page >= 1) {
      onPageChange(event, page - 1)
    }
  }

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div style={{ flexShrink: 0 }}>
      <MD.IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </MD.IconButton>
      <MD.IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </MD.IconButton>
      <p
        style={{
          fontSize: '11px',
          fontFamily: 'sans-serif',
          display: 'inline-block',
        }}
      >
        {props.i18n._('Page')} {page + 1} / {totalPages}
      </p>
      <MD.IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </MD.IconButton>
      {!(page >= Math.ceil(count / rowsPerPage) - 1) && (
        <MD.IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </MD.IconButton>
      )}
    </div>
  )
}

export class DeclarationContentComponent extends React.Component<
  AllProps,
  IState
> {
  public constructor(props: AllProps) {
    super(props)
    this.state = {
      perPage: 25,
      currentPage: 0,
      videoDialogOpened: false,
      currentVideoPlayingSource: null,
      order: 'desc',
      orderBy: 'name',
      openedCriteriaPanel: false,
      codeArpp: undefined,
      product: undefined,
      email: undefined,
      codePubID: undefined,
      user_group: undefined,
      openDialogDownloadCSV: false,
      startDate: null,
      endDate: null,
    }
  }

  public componentDidMount() {
    this.props.getFormulaireList(
      this.state.currentPage + 1,
      this.state.perPage,
      {
        product: this.state.product,
        code_arpp: this.state.codeArpp,
        email: this.state.email,
        code_pub_id: this.state.codePubID,
        user_group: this.state.user_group,
        created_at_min: this.state.startDate,
        created_at_max: this.state.endDate,
      },
      {
        field: 'created_at',
        sorting: 'desc',
      }
    )
  }
  private handleRequestSort(ev: any, value: any) {
    let property: string = arguments[1]
    let order: IOrder = this.state.order === 'asc' ? 'desc' : 'asc'
    this.setState({ order: order, orderBy: property })
    this.props.getFormulaireList(
      this.state.currentPage + 1,
      this.state.perPage,
      {
        product: this.state.product,
        email: this.state.email,
        code_arpp: this.state.codeArpp,
        code_pub_id: this.state.codePubID,
        user_group: this.state.user_group,
        created_at_min: this.state.startDate,
        created_at_max: this.state.endDate,
      },
      {
        field: property,
        sorting: order,
      }
    )
  }
  private drawResultsTableHeaders = () => {
    const { order, orderBy } = this.state

    let headCells: any = []
    if (this.props.searchResults.params.country === COUNTRY_OPTIONS.FRANCE) {
      headCells = [
        {
          key: 0,
          id: '',
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Media'),
        },
        { key: 1, id: '', numeric: false, disablePadding: true, label: '' },
        {
          key: 4,
          id: 'product_name',
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Product'),
        },
        {
          key: 5,
          id: 'codes_arpp',
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Arpp Code'),
        },
        {
          key: 5,
          id: 'codes_pub_id',
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Code PubID'),
        },
        {
          key: 6,
          id: '',
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Statut'),
        },
        {
          key: 7,
          id: 'version',
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Version'),
        },
        {
          key: 8,
          id: 'created_at',
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Created at'),
        },
        {
          key: 9,
          id: 'last_modified',
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Modified at'),
        },
        {
          key: 10,
          id: 'user_email',
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Created By'),
        },
        {
          key: 11,
          id: 'user_group',
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('User Group'),
        },
        {
          key: 12,
          id: '',
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Details'),
        },
      ]
    } else {
      headCells = [
        {
          key: 0,
          id: '',
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Media'),
        },
        { key: 1, id: '', numeric: false, disablePadding: true, label: '' },
        {
          key: 4,
          id: 'product_name',
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Product'),
        },
        {
          key: 5,
          id: 'codes_arpp',
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Arpp Code'),
        },
        {
          key: 5,
          id: '',
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Agency'),
        },
        {
          key: 6,
          id: '',
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Statut'),
        },
        {
          key: 8,
          id: 'created_at',
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Created at'),
        },
        {
          key: 9,
          id: 'last_modified',
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Modified at'),
        },
        {
          key: 10,
          id: '',
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Details'),
        },
      ]
    }

    return (
      <MD.TableHead>
        <MD.TableRow>
          {headCells.map((headCell: any, index: number) => (
            <TableHeaderCell
              key={index}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <MD.Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                wrap="nowrap"
              >
                <MD.Grid item>
                  {headCell.id !== '' ? (
                    <MD.TableSortLabel
                      active={orderBy === headCell.id}
                      direction={order}
                      onClick={(e) => this.handleRequestSort(e, headCell.id)}
                    >
                      {headCell.label}
                    </MD.TableSortLabel>
                  ) : (
                    headCell.label
                  )}
                </MD.Grid>
              </MD.Grid>
            </TableHeaderCell>
          ))}
        </MD.TableRow>
      </MD.TableHead>
    )
  }

  private fetchFormulaire = (fingerprint_list: IFingerprintBucketPair[]) => {
    //this.props.fetchFormulaire(fingerprint_list);
  }

  private handleShowVideoPlayerClick = (filepath: string) => {
    this.setState({
      currentVideoPlayingSource: filepath,
      videoDialogOpened: true,
    })
  }

  private handleClose = () => {
    this.setState({ videoDialogOpened: false })
  }

  private showVideoPlayer = () => {
    const { classes, theme } = this.props
    let videoSource = this.state.currentVideoPlayingSource
    if (videoSource) {
      return (
        <MD.Dialog
          onClose={this.handleClose.bind(this)}
          open={this.state.videoDialogOpened}
          maxWidth="lg"
          style={{ overflow: 'hidden' }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle
            layout={this.props.layout}
            theme={theme}
            i18n={this.props.i18n}
            id="draggable-dialog-title"
            onClose={this.handleClose.bind(this)}
          >
            Title
          </DialogTitle>
          <MD.DialogContent style={{ padding: '2px 12px' }}>
            <video
              autoPlay
              controls
              className={classes.videoPlayer}
              src={videoSource}
            ></video>
          </MD.DialogContent>
        </MD.Dialog>
      )
    }
    return null
  }

  private displayDeclarationStatus = (item: Formulaire) => {
    if (item.envelope) {
      const status = item.envelope
      if (item.is_signed) {
        return (<MD.Tooltip title={'le document a été signé électroniquement et envoyé à la SACEM'}>
          <DoneIcon htmlColor={this.props.theme.Adtrack.colors.snackSuccessBG} />
        </MD.Tooltip>)
      }
      return (
        <MD.Tooltip title={status === 'completed' ? 'la déclaration a été signée électroniquement et peut être envoyé à la sacem' : status === 'sent' ? 'la déclaration a été envoyé pour signature électronique et n\'a pas encore été signé' : `erreur: le status ${status} n'est pas dans les status connu`}>
          {status === 'completed' ? <DoneIcon color="primary" /> : status === 'sent' ? <SendIcon color="primary" /> : <HelpIcon color="primary" />}
        </MD.Tooltip>
      )
    } else {
      return (
        <MD.Tooltip title={'La déclaration peut être édité au besoin'}>
          <EditIcon color="primary" />
        </MD.Tooltip>
      )
    }
  }

  private drawDeclarationItemRow = (item: Formulaire, index: any) => {
    const { classes } = this.props

    let lastModified: any = item.last_modified
    let createdAt: any = item.created_at

    lastModified = Moment(lastModified[Object.keys(lastModified)[0]])
    createdAt = Moment(createdAt[Object.keys(createdAt)[0]])

    return (
      <MD.TableRow key={index} className={classes.contentTableRow}>
        <TableCell align="center">
          {item.media.type === 'Video' ? (
            <TvIcon color="secondary" className={classes.iconStyle} />
          ) : null}
          {item.media.type === 'Radio' ? (
            <RadioIcon color="secondary" className={classes.iconStyle} />
          ) : null}
        </TableCell>

        <TableCell align="center">
          <MD.IconButton
            style={{ padding: '6px' }}
            onClick={() => {
              this.handleShowVideoPlayerClick(item.filepath)
            }}
          >
            <PlayCircleFilledIcon
              className={classes.iconStyle}
              color="secondary"
            />
          </MD.IconButton>
        </TableCell>

        <TableCell className={classes.smallX}>{item.product_name}</TableCell>

        <TableCell className={classes.smallX}>{item.codes_arpp}</TableCell>

        <TableCell className={classes.small}>
          {this.props.searchResults.params.country === COUNTRY_OPTIONS.FRANCE
            ? item.codes_pub_id
            : item.agency}
        </TableCell>

        <TableCell align="center">
          {this.displayDeclarationStatus(item)}
        </TableCell>

        {this.props.searchResults.params.country === COUNTRY_OPTIONS.FRANCE ? (
          <TableCell align="center">{item.version}</TableCell>
        ) : null}

        <TableCell align="center">
          {createdAt.format('DD MMM YYYY HH:mm')}
        </TableCell>

        <TableCell>{lastModified.format('DD MMM YYYY HH:mm')}</TableCell>
        {this.props.searchResults.params.country === COUNTRY_OPTIONS.FRANCE && (
          <TableCell id="user_email" className={classes.textAlign}>
            {isNullOrUndefined(item.user_email) &&
              !isNullOrUndefined(item.society)
              ? item.society.email
              : item.user_email}
          </TableCell>
        )}
        {this.props.searchResults.params.country === COUNTRY_OPTIONS.FRANCE && (
          <TableCell id="user_group" className={classes.textAlign}>
            {isNullOrUndefined(item.user_group) &&
              !isNullOrUndefined(item.society) &&
              !isNullOrUndefined(item.society.user_group_info)
              ? item.society.user_group_info.name
              : item.user_group}
          </TableCell>
        )}
        <TableCell align="center" style={{ width: '10%' }}>
          <MD.Grid container alignItems="center" justifyContent="center">
            {item.filepath ? (
              <MD.IconButton
                style={{ padding: '6px' }}
                href={'exports_pdf/' + item.filepath}
                download
              >
                <GetAppIcon color="secondary" className={classes.iconStyle} />
              </MD.IconButton>
            ) : null}

            <StyledLink
              to={'/formulaire/' + item.bucket_id + '/' + item.spot_id}
              onClick={this.fetchFormulaire.bind(this, item.fingerprint_list)}
            >
              <MD.IconButton style={{ padding: '2px' }}>
                <InsertDriveFileIcon
                  color="secondary"
                  className={classes.detailsIcon}
                />
              </MD.IconButton>
            </StyledLink>
            <StyledLink to={'/details/' + item.bucket_id + '/' + item.spot_id}>
              <MD.IconButton style={{ padding: '2px' }}>
                <MoreIcon color="secondary" className={classes.detailsIcon} />
              </MD.IconButton>
            </StyledLink>
          </MD.Grid>
        </TableCell>
      </MD.TableRow>
    )
  }

  private onChangePageResultsTable = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    this.setState({ currentPage: page })
    this.props.getFormulaireList(page + 1, this.state.perPage, {
      product: this.state.product,
      code_arpp: this.state.codeArpp,
      email: this.state.email,
      code_pub_id: this.state.codePubID,
      user_group: this.state.user_group,
      created_at_min: this.state.startDate,
      created_at_max: this.state.endDate,
    })
  }

  private onChangeRowsPerPageResultsTable = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const perPage = parseInt(event.target.value)
    this.setState({ perPage: perPage, currentPage: 0 })
    this.props.getFormulaireList(1, perPage, {
      product: this.state.product,
      code_arpp: this.state.codeArpp,
      email: this.state.email,
      code_pub_id: this.state.codePubID,
      user_group: this.state.user_group,
      created_at_min: this.state.startDate,
      created_at_max: this.state.endDate,
    })
  }

  private drawDeclarationsTable = () => {
    const { classes, formulaireList, i18n } = this.props
    const { formulaires } = formulaireList

    if (formulaires.status === '') {
      return (
        <div>
          {this.props.i18n._('Loading')} ...
        </div>
      )
    }

    if (formulaires.status !== 'OK') {
      return (
        <div>
          {this.props.i18n._('An error occured')}
        </div>
      )
    }

    if (formulaires.status === 'OK' && formulaires.results.length === 0) {
      return (
        <div>
          {this.props.i18n._('No results')}
        </div>
      )
    }

    return (
      <MD.TableContainer>
        <MD.Paper className={classes.paperOverflow}>
          <MD.Table
            stickyHeader
            aria-label="sticky table"
            style={{ borderCollapse: 'collapse' }}
          >
            {this.drawResultsTableHeaders()}
            <MD.TableBody>
              {formulaires.results.map((item: Formulaire, index: any) =>
                this.drawDeclarationItemRow(item, index)
              )}
            </MD.TableBody>
          </MD.Table>
        </MD.Paper>
      </MD.TableContainer>
    )
  }

  private onStartDateChange = (date: any) => {
    this.setState({ startDate: getDateHourZero(date) })
  }
  private onEndDateChange = (date: any) => {
    this.setState({ endDate: getDateHourZero(date) })
  }

  private displaySearchWithCriterias = () => {
    const { classes, i18n, localeInfos } = this.props

    let format: string = '',
      locale: Locale = fr
    if (localeInfos.momentLocale === 'en-us') {
      format = 'MM/dd/yyyy'
      locale = enGB
    } else if (localeInfos.momentLocale === 'fr') {
      format = 'dd/MM/yyyy'
      locale = fr
    } else if (localeInfos.momentLocale === 'de') {
      format = 'dd/MM/yyyy'
      locale = de
    }
    return (
      <MD.Grid
        container
        direction="column"
        wrap="nowrap"
        style={{ paddingLeft: '10px' }}
      >
        <MD.Grid direction="row" container item spacing={1}>
          <MD.Grid item xs={2}>
            <MD.TextField
              fullWidth
              value={this.state.product}
              label={this.props.i18n._('Product')}
              onChange={(event: any) => {
                let product = event.target.value
                this.setState({
                  product: product.trim(),
                })
              }}
              id="ad-title"
              variant="outlined"
              InputProps={{ classes: { input: classes.inputPropsStyle } }}
              InputLabelProps={{ className: classes.inputLabelPropsStyle }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  this.launchSearch()
                }
              }}
            />
          </MD.Grid>

          <MD.Grid item xs={2}>
            <MD.TextField
              fullWidth
              value={this.state.codeArpp}
              label={this.props.i18n._('Code Arpp')}
              onChange={(event: any) => {
                let codeArpp = event.target.value
                this.setState({
                  codeArpp: codeArpp.trim(),
                })
              }}
              id="ad-announcer"
              variant="outlined"
              InputProps={{ classes: { input: classes.inputPropsStyle } }}
              InputLabelProps={{ className: classes.inputLabelPropsStyle }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  this.launchSearch()
                }
              }}
            />
          </MD.Grid>
          <MD.Grid item xs={2}>
            <MD.TextField
              fullWidth
              value={this.state.codePubID}
              label={this.props.i18n._('CodePubID')}
              onChange={(event: any) => {
                let codepub = event.target.value
                this.setState({
                  codePubID: codepub.trim(),
                })
              }}
              id="ad-product"
              variant="outlined"
              InputProps={{ classes: { input: classes.inputPropsStyle } }}
              InputLabelProps={{ className: classes.inputLabelPropsStyle }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  this.launchSearch()
                }
              }}
            />
          </MD.Grid>

          <MD.Grid item xs={2}>
            <MD.TextField
              fullWidth
              value={this.state.email}
              label={this.props.i18n._('Email')}
              onChange={(event: any) => {
                let email = event.target.value
                this.setState({
                  email: email.trim(),
                })
              }}
              id="ad-product"
              variant="outlined"
              InputProps={{ classes: { input: classes.inputPropsStyle } }}
              InputLabelProps={{ className: classes.inputLabelPropsStyle }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  this.launchSearch()
                }
              }}
            />
          </MD.Grid>
          <MD.Grid item xs={2}>
            <MD.TextField
              fullWidth
              value={this.state.user_group}
              label={this.props.i18n._('Groupe')}
              onChange={(event: any) => {
                let user_group = event.target.value
                this.setState({
                  user_group: user_group.trim(),
                })
              }}
              id="ad-product"
              variant="outlined"
              InputProps={{ classes: { input: classes.inputPropsStyle } }}
              InputLabelProps={{ className: classes.inputLabelPropsStyle }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  this.launchSearch()
                }
              }}
            />
          </MD.Grid>
        </MD.Grid>
        <MD.Grid direction="row" container item spacing={1}>
          <MD.Grid item xs={2}>
            <MD.Typography
              style={{
                marginTop: 10,
                color: this.props.theme.palette.text.secondary,
              }}
              className={classes.inputLabelPropsStyle}
            >
              {' '}
              {i18n._('Created at start')}{' '}
            </MD.Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
              <KeyboardDatePicker
                className={classes.datePickerInput}
                autoOk={true}
                disableToolbar
                variant="inline"
                format={format}
                margin="dense"
                id="date-picker-start"
                value={this.state.startDate}
                InputProps={{
                  disableUnderline: true,
                  className: classes.inputPropsStyle,
                }}
                InputLabelProps={{ className: classes.inputLabelPropsStyle }}
                onChange={(e: any) => this.onStartDateChange(e)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </MD.Grid>
          <MD.Grid item xs={2}>
            <MD.Typography
              style={{
                marginTop: 10,
                color: this.props.theme.palette.text.secondary,
              }}
              className={classes.inputLabelPropsStyle}
            >
              {' '}
              {this.props.i18n._('Created at end')}{' '}
            </MD.Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
              <KeyboardDatePicker
                className={classes.datePickerInput}
                autoOk={true}
                disableToolbar
                variant="inline"
                format={format}
                margin="dense"
                id="date-picker-start"
                value={this.state.endDate}
                InputProps={{
                  disableUnderline: true,
                  className: classes.inputPropsStyle,
                }}
                InputLabelProps={{ className: classes.inputLabelPropsStyle }}
                onChange={(e: any) => this.onEndDateChange(e)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </MD.Grid>
        </MD.Grid>
        {this.drawSearchButton()}
      </MD.Grid>
    )
  }

  private drawFilters() {
    return (
      <Accordion
        expanded={this.state.openedCriteriaPanel}
        onChange={() => {
          let openedCriteriaPanel = this.state.openedCriteriaPanel
          this.setState({
            openedCriteriaPanel: !openedCriteriaPanel,
          })
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ height: '60px', minHeight: '60px' }}
        >
          <MD.Typography variant="subtitle2" gutterBottom>
            {this.props.i18n._('Panneau de recherche')}
          </MD.Typography>
        </AccordionSummary>

        <AccordionDetails>{this.displaySearchWithCriterias()}</AccordionDetails>
      </Accordion>
    )
  }

  private launchSearch() {
    this.props.getFormulaireList(
      this.state.currentPage + 1,
      this.state.perPage,
      {
        product: this.state.product,
        code_arpp: this.state.codeArpp,
        email: this.state.email,
        code_pub_id: this.state.codePubID,
        user_group: this.state.user_group,
        created_at_min: this.state.startDate,
        created_at_max: this.state.endDate,
      },
      {
        field: 'created_at',
        sorting: 'desc',
      }
    )
  }
  private resetFilters() {
    this.setState(
      {
        codeArpp: '',
        product: '',
        email: '',
        codePubID: '',
        user_group: '',
        startDate: null,
        endDate: null,
      },
      () =>
        this.props.getFormulaireList(
          this.state.currentPage + 1,
          this.state.perPage,
          {
            product: undefined,
            code_arpp: undefined,
            email: undefined,
            code_pub_id: undefined,
            user_group: undefined,
            created_at_min: null,
            created_at_max: null,
          },
          {
            field: 'created_at',
            sorting: 'desc',
          }
        )
    )
  }
  private drawSearchButton() {
    return (
      <MD.Grid
        direction="row"
        xs={12}
        container
        item
        spacing={2}
        style={{ marginTop: '0' }}
      >
        <MD.Grid item xs style={{ textAlign: 'right' }}>
          <MD.Button
            variant="contained"
            color="secondary"
            onClick={this.launchSearch.bind(this)}
          >
            {this.props.i18n._('Rechercher')}
          </MD.Button>
        </MD.Grid>
        <MD.Grid item xs style={{ textAlign: 'left' }}>
          <MD.Button
            variant="contained"
            color="secondary"
            onClick={this.resetFilters.bind(this)}
          >
            {this.props.i18n._('Réinitialiser')}
          </MD.Button>
        </MD.Grid>
      </MD.Grid>
    )
  }
  private openDialogDownloadCSV() {
    const { i18n, formulaireList } = this.props
    return (
      <MD.Dialog
        aria-labelledby="draggable-title"
        onClose={this.handleCloseDownloadCSV.bind(this)}
        open={this.state.openDialogDownloadCSV}
      >
        <DialogTitle
          i18n={this.props.i18n}
          layout={this.props.layout}
          theme={this.props.theme}
          id="draggable-title"
          onClose={this.handleCloseDownloadCSV.bind(this)}
        >
          Export XLS
        </DialogTitle>
        <MD.DialogContent
          style={{ padding: theme.spacing(2), width: '220px' }}
          dividers
        >
          {formulaireList.file_downloaded === null ? (
            <MD.Typography
              gutterBottom
              className={this.props.classes.containerDivText}
              style={{ color: this.props.theme.palette.text.primary }}
            >
              {i18n._('Loading')}...
            </MD.Typography>
          ) : (
            <MD.Typography
              gutterBottom
              className={this.props.classes.containerDivText}
              style={{ color: this.props.theme.palette.text.primary }}
            >
              <a
                style={{ color: theme.palette.text.primary }}
                href={formulaireList.file_downloaded}
                download={formulaireList.file_downloaded.split('/')[3]}
              >
                {i18n._('Click to download')}
              </a>
            </MD.Typography>
          )}
        </MD.DialogContent>
      </MD.Dialog>
    )
  }
  private handleClickDownloadCSV() {
    const data = this.props.formulaireList.results
    this.setState(
      {
        openDialogDownloadCSV: true,
      },
      () => {
        this.props.fetchExportsDeclarations(
          undefined,
          {
            product: this.state.product,
            email: this.state.email,
            code_arpp: this.state.codeArpp,
            code_pub_id: this.state.codePubID,
            user_group: this.state.user_group,
            created_at_min: this.state.startDate,
            created_at_max: this.state.endDate,
          },
          {
            field: this.state.orderBy,
            sorting: this.state.order,
          }
        )
      }
    )
  }
  private handleCloseDownloadCSV = () => {
    this.setState(
      {
        openDialogDownloadCSV: false,
      },
      () => this.props.cleanFileDownload()
    )
  }
  private drawExports() {

    const { formulaireList, i18n } = this.props
    //let fl = sortByDateArray(formulaireList.results);
    const { formulaires } = formulaireList

    return (
      <MD.Grid
        xs={12}
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <MD.Grid item xs={2}>
          <CustomTooltip title={this.props.i18n._('Refresh envelope status')}>
            <MD.Button
              type="button"
              href="#"
              className={this.props.classes.searchButton}
              color="secondary"
              onClick={() => this.props.getFormulaireList(
                this.state.currentPage + 1,
                this.state.perPage,
                {
                  product: this.state.product,
                  code_arpp: this.state.codeArpp,
                  email: this.state.email,
                  code_pub_id: this.state.codePubID,
                  user_group: this.state.user_group,
                  created_at_min: this.state.startDate,
                  created_at_max: this.state.endDate,
                },
                {
                  field: 'created_at',
                  sorting: 'desc',
                },
                true,
              )}
              variant="contained"
              startIcon={<RefreshIcon />}
            >
              <span style={{ fontSize: 'small' }}>REFRESH LIST</span>
            </MD.Button>
          </CustomTooltip>
        </MD.Grid>
        {hasPermissionExportDeclarations(this.props.user) && <MD.Grid item xs={2}>
          <CustomTooltip title={this.props.i18n._('Download XLS')}>
            <MD.Button
              type="button"
              href="#"
              className={this.props.classes.searchButton}
              color="secondary"
              onClick={() => this.handleClickDownloadCSV()}
              variant="contained"
              startIcon={<CloudDownloadIcon />}
            >
              <span style={{ fontSize: 'small' }}>EXPORT XLS</span>
            </MD.Button>
          </CustomTooltip>
        </MD.Grid>}
        {!formulaireList.loadingFormulaireList && <MD.Grid item xs={8}>
          <TablePagination
            style={{ marginRight: 0 }}
            rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]}
            labelRowsPerPage={this.props.i18n._('Rows per page')}
            count={formulaires.total_count}
            rowsPerPage={this.state.perPage}
            page={this.state.currentPage}
            labelDisplayedRows={({ from, to, count }: any) =>
              `${i18n._('Showing')} ${from} ${i18n._(
                'to'
              )} ${to} ${i18n._('of')} ${count} ${i18n._('entries')}`
            }
            onPageChange={this.onChangePageResultsTable}
            onRowsPerPageChange={this.onChangeRowsPerPageResultsTable}
            ActionsComponent={(subProps: any) => (
              <TablePaginationActions
                {...subProps}
                theme={theme}
                i18n={this.props.i18n}
                totalPages={formulaires.total_pages}
              />
            )}
          />
        </MD.Grid>}
      </MD.Grid>
    )
  }
  public render(): React.ReactNode {
    return (
      <MD.Grid container direction="row" spacing={1} xs={12}>
        {this.state.videoDialogOpened && this.showVideoPlayer()}
        {this.state.openDialogDownloadCSV && this.openDialogDownloadCSV()}
        <MD.Grid item xs={12}>
          {this.drawFilters()}
        </MD.Grid>
        <MD.Grid item xs={12}>
          {this.drawExports()}
        </MD.Grid>
        <MD.Grid item xs={12}>
          {this.drawDeclarationsTable()}
        </MD.Grid>
      </MD.Grid>
    )
  }
}

const mapStateToProps = ({
  i18n,
  formulaireList,
  searchResults,
  layout,
  user,
}: IApplicationState) => ({
  i18n: i18n.i18n,
  formulaireList,
  searchResults,
  layout,
  localeInfos: i18n.localeInfos,
  user,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getFormulaireList: (
    page: number,
    perPage: number,
    filters?: IFormulaireFilters,
    order_by?: IOrderBy,
    refresh_status?: boolean
  ) => dispatch(getFormulaireList(page, perPage, filters, order_by, refresh_status)),
  fetchFormulaire: (fingerprint_list: IFingerprintBucketPair[]) =>
    dispatch(fetchFormulaire(fingerprint_list)),
  fetchExportsDeclarations: (
    perPage?: number,
    filters?: IFormulaireFilters,
    orderBy?: IOrderBy
  ) => dispatch(fetchExportsDeclarations(perPage, filters, orderBy)),
  cleanFileDownload: () => dispatch(cleanFileDownload()),
})

export const DeclarationContent = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withStyles(styles)(DeclarationContentComponent)))

import React from 'react';

interface ISVGProps {
    bookfill?: string;
    bookside?: string;
    bookfront?: string;
    color?: string;
    height?: string;
    width?: string;
}

type AllProps = ISVGProps;

export class AdtrackLogoIcon extends React.Component<AllProps> {
   
    render() {
        let {color} = this.props;
        if (!color) {
            color="#FFFFFF";
        }
        const color1 = "#c54cfb";
        const color2 = "#765ce2";
        //const color3 = "#42475e";
        return (
            <svg width="153" height="25" viewBox="0 0 1530 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill={color1} d="M155.93,40.05l82.24,136a2,2,0,0,0,3.47.05l26.92-41.91a2.05,2.05,0,0,0,0-2.16L211.83,37.59a2,2,0,0,0-1.76-1l-52.41.34A2,2,0,0,0,155.93,40.05Z"/>
                <path fill={color1} d="M241.5,39.77l40.65,67.61a2.05,2.05,0,0,0,3.48.05L329,39.72a2,2,0,0,0-1.72-3.16l-84.06.11A2,2,0,0,0,241.5,39.77Z"/>
                <path fill={color2} d="M189.78,233c24.32-11.84,31.87-25.52,33.82-30.16a3.32,3.32,0,0,0-.21-3L127,38.12a2.05,2.05,0,0,0-1.76-1H72.86a2,2,0,0,0-1.75,3.1L186.17,231.89A2.78,2.78,0,0,0,189.78,233Z"/>
                <path fill={color2} d="M159.34,236.28a2,2,0,0,0,1.65-3.11l-39-62a2,2,0,0,0-3.48,0L80.28,233.24a2,2,0,0,0,1.7,3.09C95.18,236.48,141.06,236.79,159.34,236.28Z"/>
                <path fill={color} stroke={color} strokeMiterlimit={10} strokeWidth={'2px'} d="M523.38,225l-11.55-33.72H445.57L434,225H406.91L468.45,57.08H489L550.5,225ZM479.29,95.28l-26.17,74.5h51.63Z"/>
                <path fill={color} stroke={color} strokeMiterlimit={10} strokeWidth={'2px'} d="M640.09,225V212.22q-6.84,8.26-14.26,11.2a46.74,46.74,0,0,1-17.33,3,47.56,47.56,0,0,1-16.63-2.83,34.38,34.38,0,0,1-12.61-7.78,35.22,35.22,0,0,1-7.19-10.37,59.64,59.64,0,0,1-4.13-12.62A89.18,89.18,0,0,1,566.17,179q-.34-7.08-.35-13.91t.35-13.91a86.54,86.54,0,0,1,1.77-13.68A60,60,0,0,1,572.07,125a35.4,35.4,0,0,1,7.19-10.38,34.49,34.49,0,0,1,12.5-7.78,46.7,46.7,0,0,1,16.5-2.83,48.94,48.94,0,0,1,17.21,2.83q7.54,2.83,14.15,10.61V57.08h24V225Zm-1.3-75.1a39.49,39.49,0,0,0-3.42-12.61,20.53,20.53,0,0,0-7.54-8.61q-5-3.18-13-3.18-8.25,0-13.08,3.18a21.64,21.64,0,0,0-7.55,8.61,37.53,37.53,0,0,0-3.54,12.61,141.47,141.47,0,0,0,0,30.42A38.79,38.79,0,0,0,594.23,193a21.49,21.49,0,0,0,7.55,8.72q4.83,3.18,13.08,3.18,8,0,13-3.18a20.42,20.42,0,0,0,7.54-8.72,40.84,40.84,0,0,0,3.42-12.73,139.78,139.78,0,0,0,0-30.42Z"/>
                <path fill={color} stroke={color} strokeMiterlimit={10} strokeWidth={'2px'} d="M761.51,80V225H736.05V80H689.84V57.08H807.72V80Z"/>
                <path fill={color} stroke={color} strokeMiterlimit={10} strokeWidth={'2px'} d="M888.83,133.47a32.38,32.38,0,0,0-7.9-6,21.91,21.91,0,0,0-10-2,22.57,22.57,0,0,0-16.85,7.19q-7,7.2-7,19.69V225H823.05V105.42h23.58v13A34.2,34.2,0,0,1,859.83,108a43.11,43.11,0,0,1,18.63-4A43.65,43.65,0,0,1,894,106.6a37,37,0,0,1,13,8.72Z"/>
                <path fill={color} stroke={color} strokeMiterlimit={10} strokeWidth={'2px'} d="M988.57,225V214.11a41.34,41.34,0,0,1-13.68,9.43q-7.07,2.84-18.15,2.83-11.32,0-19-2.71a34.43,34.43,0,0,1-13.09-8.14q-9.43-9.9-9.43-25.7,0-15.07,10.26-24.75t31-9.67h31.59v-8.49q0-11.31-6-16.85t-20.16-5.55q-9.9,0-15.56,2.6a29.13,29.13,0,0,0-10.61,9L920,121.21q8.47-9.9,18.27-13.55T962.87,104q49.27,0,49.27,41V225Zm-.48-52.82H960q-10.62,0-16,4.48t-5.42,12.73q0,8.27,5.31,12.62t16.62,4.36a64,64,0,0,0,12.26-1.06,19.73,19.73,0,0,0,10.14-5.78q5.18-5.18,5.18-17.68Z"/>
                <path fill={color} stroke={color} strokeMiterlimit={10} strokeWidth={'2px'} d="M1118,222.36q-9.19,4-21.7,4a59.78,59.78,0,0,1-19.68-3.3,44.86,44.86,0,0,1-17-10.61,53.82,53.82,0,0,1-12-19q-4.61-11.67-4.6-28.41t4.6-28.41a52.56,52.56,0,0,1,12-18.86,45.59,45.59,0,0,1,17-10.5,59.78,59.78,0,0,1,19.68-3.3q12.5,0,21.7,3.89t17.68,12.85l-16.5,16a40.33,40.33,0,0,0-10.73-8.73,26.38,26.38,0,0,0-12.15-2.59q-14.14,0-22.16,10.61a28.22,28.22,0,0,0-5.54,11.67,80.42,80.42,0,0,0-1.53,17.33,82.15,82.15,0,0,0,1.53,17.45,28.2,28.2,0,0,0,5.54,11.78q8,10.62,22.16,10.61a26.38,26.38,0,0,0,12.15-2.59,40.27,40.27,0,0,0,10.73-8.72l16.5,15.79A54.78,54.78,0,0,1,1118,222.36Z"/>
                <path fill={color} stroke={color} strokeMiterlimit={10} strokeWidth={'2px'} d="M1238.72,225l-33.48-55.18-17.68,20V225h-24V57.08h24V160.59L1234,105.42h29.24l-41.5,46.92L1268.43,225Z"/>
            </svg>
        )

    }
};
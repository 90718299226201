import { action } from 'typesafe-actions';
import { ISpotDetailsActionTypes, IBroadcastPassage, ESpotDetailsSource, IIdenticSpot, IMediaInfo, IFingerprintBucketPair, IReportSpotDetails, IBroadcastPassageWeb, IPaginationParams, IMediasList } from './Types';
import { Moment } from 'moment';
import { ISpotDetails } from './Types';
import { IFormulaireState } from '../Formulaire/Types';
import { ISpotRegroupement } from '../Search/Types';

export const getBroadcastsPassages = (fgpBucketPair: IFingerprintBucketPair[], startDate: Moment, endDate: Moment) => 
    action(ISpotDetailsActionTypes.GET_BROADCASTS_PASSAGES, [fgpBucketPair, startDate, endDate]);
export const getBroadcastsPassagesSuccess = (results: IBroadcastPassage[], count:number, medias: IMediasList[]) => action(ISpotDetailsActionTypes.GET_BROADCASTS_PASSAGES_SUCCESS, [results, count, medias]);
export const getBroadcastsPassagesError = () => action(ISpotDetailsActionTypes.GET_BROADCASTS_PASSAGES_ERROR);

export const getBroadcastsPassagesByFgpID = (fgpid: number, bucket_id: number, startDate: Moment, endDate: Moment, params: IPaginationParams, media_id?: number[]) => 
    action(ISpotDetailsActionTypes.GET_BROADCASTS_PASSAGES_BY_FGID, [fgpid, bucket_id, startDate, endDate, params, media_id]);
export const getBroadcastsPassagesByFgpIDSuccess = (results: IBroadcastPassage[], count:number) => action(ISpotDetailsActionTypes.GET_BROADCASTS_PASSAGES_BY_FGID_SUCCESS, [results, count]);
export const getBroadcastsPassagesByFgpIDError = () => action(ISpotDetailsActionTypes.GET_BROADCASTS_PASSAGES_BY_FGID_ERROR);
 
export const getBroadcastWebPassages = (startDate:Moment, endDate: Moment, ad_id: any, page: number, limit: number) => 
    action(ISpotDetailsActionTypes.GET_BROADCASTS_WEB_PASSAGES, [startDate, endDate, ad_id, page, limit]);
export const getBroadcastsWebPassagesSuccess = (results: IBroadcastPassageWeb[], count: number) => action(ISpotDetailsActionTypes.GET_BROADCASTS_WEB_PASSAGES_SUCCESS, [results, count]);
export const getBroadcastsWebPassagesError = () => action(ISpotDetailsActionTypes.GET_BROADCASTS_WEB_PASSAGES_ERROR);
 
export const getBroadcastsWebPassagesById = (id: number) => 
    action(ISpotDetailsActionTypes.GET_BROADCASTS_WEB_PASSAGES_BY_ID, id);
export const getBroadcastsWebPassagesByIdSuccess = (results: any[]) => action(ISpotDetailsActionTypes.GET_BROADCASTS_WEB_PASSAGES_BY_ID_SUCCESS, results);
export const getBroadcastsWebPassagesByIdError = () => action(ISpotDetailsActionTypes.GET_BROADCASTS_WEB_PASSAGES_BY_ID_ERROR);
 
export const setSpotDetails = (details: ISpotRegroupement, startDate: string, endDate: string) => 
    action(ISpotDetailsActionTypes.SET_SPOT_DETAILS, [details, startDate, endDate]);

export const setMediaName = (mediaInfo: IMediaInfo) => 
    action(ISpotDetailsActionTypes.SET_MEDIA_NAME, mediaInfo);
export const setAdvertiser = (advertiser: any) => 
    action(ISpotDetailsActionTypes.SET_ADVERTISER, advertiser);

export const setSpotFingerprintBucketList = (fingerprintBucketList: IFingerprintBucketPair[]) => 
    action(ISpotDetailsActionTypes.SET_SPOT_FINGERPRINT_BUCKET_LIST, fingerprintBucketList);

export const getSpotDetails = (bucketId: ESpotDetailsSource, spotId: number) => 
    action(ISpotDetailsActionTypes.GET_SPOT_DETAILS, [bucketId, spotId]);
export const getSpotDetailsSuccess = (bucketId: ESpotDetailsSource, details: ISpotDetails, group_id: number) => 
    action(ISpotDetailsActionTypes.GET_SPOT_DETAILS_SUCCESS, [bucketId, details, group_id]);
export const getSpotDetailsError = () => 
    action(ISpotDetailsActionTypes.GET_SPOT_DETAILS_ERROR);

export const getSpotDetailsFromRegrouping = (bucketId: ESpotDetailsSource, spotId: number) => 
    action(ISpotDetailsActionTypes.GET_SPOT_DETAILS_REGROUPEMENT, [bucketId, spotId]);
export const getSpotDetailsFromRegroupingSuccess = (details: ISpotDetails) => 
    action(ISpotDetailsActionTypes.GET_SPOT_DETAILS_REGROUPEMENT_SUCCESS, details);
export const getSpotDetailsFromRegroupingError = () => 
    action(ISpotDetailsActionTypes.GET_SPOT_DETAILS_REGROUPEMENT_ERROR);

export const getIdenticSpots = (spotId: number, bucketId: ESpotDetailsSource) => 
    action(ISpotDetailsActionTypes.GET_IDENTIC_SPOTS, [spotId, bucketId]);
export const getIdenticSpotsSuccess = (spots: any[]) => 
    action(ISpotDetailsActionTypes.GET_IDENTIC_SPOTS_SUCCESS, spots);
export const getIdenticSpotsError = () => 
    action(ISpotDetailsActionTypes.GET_IDENTIC_SPOTS_ERROR);

    export const getIdenticSpotsNielsen = (spotId: number, bucketId: ESpotDetailsSource) => 
    action(ISpotDetailsActionTypes.GET_IDENTIC_SPOTS_NIELSEN, [spotId, bucketId]);
export const getIdenticSpotsNielsenSuccess = (spots: IIdenticSpot[]) => 
    action(ISpotDetailsActionTypes.GET_IDENTIC_SPOTS_NIELSEN_SUCCESS, spots);
export const getIdenticSpotsNielsenError = () => 
    action(ISpotDetailsActionTypes.GET_IDENTIC_SPOTS_NIELSEN_ERROR);

export const getSpotFormulaires = (fingerprintBucketList: IFingerprintBucketPair[]) => 
    action(ISpotDetailsActionTypes.GET_SPOT_FORMULAIRES, fingerprintBucketList);
export const getSpotFormulairesSuccess = (formuliareList: IFormulaireState[]) => 
    action(ISpotDetailsActionTypes.GET_SPOT_FORMULAIRES_SUCCESS, formuliareList);
export const getSpotFormulairesError = () => 
    action(ISpotDetailsActionTypes.GET_SPOT_FORMULAIRES_ERROR);

export const downloadCSV = (startDate: Moment, endDate: Moment) => 
    action(ISpotDetailsActionTypes.DOWNLOAD_CSV, [startDate, endDate]);
export const downloadCSVSuccess = (res:any) => 
    action(ISpotDetailsActionTypes.DOWNLOAD_CSV_SUCCESS, res);
export const downloadCSVError = () => action(ISpotDetailsActionTypes.DOWNLOAD_CSV_ERROR);

export const selectMusic = (id: string | null) => action(ISpotDetailsActionTypes.SELECT_MUSIC, {id});
export const updateSelectedMusicInterval = (id: string, start: number, end: number) =>
    action(ISpotDetailsActionTypes.UPDATE_SELECTED_MUSIC_INTERVAL, { id, start, end });
export const saveRegionsSurfer = (regions: any, surfer:any) => action(ISpotDetailsActionTypes.SAVE_REGIONS_SURFER, [regions, surfer]);
export const saveRegions = (regions: any) => action(ISpotDetailsActionTypes.SAVE_REGIONS, regions);
export const reportSpotProblem = (params: IReportSpotDetails) => action(ISpotDetailsActionTypes.REPORT_SPOT_PROBLEM, params);
export const reportSpotProblemSuccess = (res: any) => action(ISpotDetailsActionTypes.REPORT_SPOT_PROBLEM_SUCCESS, res);
export const reportSpotProblemError = (err: any) => action(ISpotDetailsActionTypes.REPORT_SPOT_PROBLEM_ERROR, err)
export const setMusicBroacast = (music_broadcast: any[]) => action(ISpotDetailsActionTypes.SET_MUSIC_BROADCAST, music_broadcast)
export const setSpotStartDate = (start_date: any) => action(ISpotDetailsActionTypes.SET_SPOT_START_DATE, start_date)
export const setSpotFgpid = (fgpid: number) => action(ISpotDetailsActionTypes.SET_SPOT_FGPID, fgpid)

import * as React from 'react';
import * as MD from '@material-ui/core';
import { Dispatch } from 'redux';
import { IApplicationState } from '../Store';
import { connect } from 'react-redux';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { I18N, ILocaleInfos } from '../Store/I18n/Types';

import { ILayoutState } from '../Store/Layout/Types';
import { setDrawerContent } from '../Store/Layout/Actions';
import { fromUTC, getDateOneDayBeforeTodayHourZero, getDateTodayHourZero } from '../Utils/Time';
import { IDateType, ISearchState, ITimeFilters } from '../Store/Search/Types';
import { saveTimeFilters } from '../Store/Search/Actions';
import {
  DatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { de, enGB, fr } from 'date-fns/locale';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { getTheme } from '../Themes';

const styles = (theme:any) => MD.createStyles(
  {
    datePickerLabel: {
      fontSize: '13px',
      fontWeight: 100,
      color: theme.palette.text.secondary,
      textTransform: 'capitalize'
    },
    datePickerInputSmallFont: {
      fontSize: '12px',
      "& input": {
        textAlign: 'center',
        padding: '8px 4px',
        color: theme.palette.text.secondary,
    }
    },
    inputLabelPropsStyle:{
      top: '-10px',
      fontSize: '12px'
  },
  inputPropsStyle: {
      height: '26px',
      padding: '0 5px',
      fontSize: '13px',
  },
    datePickerInput: {
      border:'none',
      marginRight: '10px',
      background: theme.palette.background.paper,
      color: theme.palette.text.secondary,
      "& .MuiFormLabel-root": {
        color: theme.palette.text.primary,
        fontSize: "12px",
        margintTop: "5px"
      }
    },
  monthButton: {
    padding: '0px 9.5px', fontSize: '11px',
    minWidth: "100%", width: "100%",
    maxHeight: '15px', minHeight: '25px',
    borderRadius: '0px', border: '1px solid gray', borderTop: '0px', borderRight: '0px'
  },
    buttonCalendar: {
      maxHeight: '15px', minHeight: '25px',
      fontSize: '11px',
      borderRadius: '0px', 
      textTransform:'none',
      padding: "0px",
      width: "100%",
      minWidth: "100%",
      border: "1px solid gray"
    },
    buttonYear: {
      borderRadius: '0px', 
      border: '1px solid gray', 
      borderBottom: '0px', 
      fontSize: 11,
      marginBottom: "-10px"
    },
});

interface IPropsFromDispatch {
  saveTimeFilters: typeof saveTimeFilters
}

interface IOwnProps {
  saveDates: (startDate:Date, endDate:Date) => void
}
interface IAppState {
  selectedYear: number;
  startDate: Date;
  endDate: Date;
  maxDateRange: Date;
  election_update: boolean;
  maxDate: Date;
  endDateToSend: Date,
  isFullYearSelected: boolean;
  isSem1Activated: boolean;
  isSem2Activated: boolean;
  isTrim1Selected: boolean;
  isTrim2Selected: boolean;
  isTrim3Selected: boolean;
  isTrim4Selected: boolean;
  selectedMonthIndex: any;
  timeFiltersLoading: boolean;
  dateType: IDateType;
  addAnotherYear: boolean;
}
type PeriodSelectionAllProps = 
MD.WithTheme &
MD.WithStyles<typeof styles> &
IPropsFromDispatch &
IPropsFromState &
IOwnProps;


interface IPropsFromState {
    i18n: I18N;
    layout: ILayoutState;
    localeInfos: ILocaleInfos;
    search: ISearchState;
}
const initialState:any = {
  maxDate: new Date(),
  endDateToSend: new Date(),
  isSem1Activated: false,
  isSem2Activated: false,
  isFullYearSelected: true,
  isTrim1Selected: false,
  isTrim2Selected: false,
  isTrim3Selected: false,
  isTrim4Selected: false,
  selectedMonthIndex: null,
  dateType: "annual",
  timeFiltersLoading: false,
  addAnotherYear: false
  
}
export class CalendarComponent extends React.Component<PeriodSelectionAllProps, IAppState> {
    
    public constructor(props: PeriodSelectionAllProps) {
      super(props);
      let endDate:Date = new Date(props.search.params.end_date as any);
      this.state = {
        ...initialState,
        selectedYear: fromUTC(this.compareToToday(endDate)).getFullYear(),
        startDate: new Date(props.search.params.start_date as any),
        endDate: fromUTC(this.compareToToday(endDate)),
        maxDateRange: endDate
      };
    }

    public componentDidMount() {
      this.updateCalendarTheme()
    }
    static getDerivedStateFromProps(props: PeriodSelectionAllProps, state: IAppState) {
      if (props.search.timeFilters !== null) {
        let params = props.search.timeFilters;
        state.isSem1Activated = params.isSem1Activated;
        state.isSem2Activated = params.isSem2Activated;
        state.isFullYearSelected = params.isFullYearSelected;
        state.isTrim1Selected = params.isTrim1Selected;
        state.isTrim2Selected = params.isTrim2Selected;
        state.isTrim3Selected = params.isTrim3Selected;
        state.isTrim4Selected = params.isTrim4Selected;
        state.selectedMonthIndex = params.selectedMonthIndex;
        state.dateType = params.dateType;
        state.selectedYear = params.endDate.getFullYear()
      }
      return state
    }
    
    private updateCalendarTheme() {
      const { theme } = this.props;

      let elems:any = document.getElementsByClassName("rdrDayDisabled");
      if (elems !== null) {
        for(let i = 0; i < elems.length; i++) {
          elems[i].style.fontWeight = 500
        }
      }
      elems = document.getElementsByClassName("rdrDateDisplayWrapper");
      if (elems !== null) {
        for(let i = 0; i < elems.length; i++) {
          elems[i].style.backgroundColor = theme.palette.background.default
          elems[i].style.fontWeight = 500
        }
      }
      elems = document.getElementsByClassName("rdrDateDisplayItem");
      if (elems !== null) {
        for(let i = 0; i < elems.length; i++) {
          if (elems[i].getElementsByTagName("input").length > 0) {
            elems[i].getElementsByTagName("input")[0].style.color = theme.palette.text.primary
          }
          elems[i].style.backgroundColor = theme.palette.background.paper
          elems[i].style.border = "0px"
          elems[i].style.fontWeight = 500
        }
      }

      elems = document.getElementsByClassName("rdrMonthAndYearPickers");
      if (elems !== null) {
        for(let i = 0; i < elems.length; i++) {
          elems[i].style.color = theme.palette.text.primary;
          elems[i].style.fontWeight = 500
          elems[i].style.fontSize = '14px'
        }
      }

      elems = document.getElementsByClassName("rdrWeekDay");
      if (elems !== null) {
        for(let i = 0; i < elems.length; i++) { 
          elems[i].style.fontSize = '14px'
          elems[i].style.color = theme.palette.text.primary
        }
      }
    }
    private compareToToday(date: Date):Date {
      if (getDateTodayHourZero() <= date) {
        return new Date()
      } else return date;
    }
    private updateSem1() {
        const last_day_of_this_sem = fromUTC(new Date(this.state.selectedYear, 6, 1));
        let endDate:Date = this.compareToToday(last_day_of_this_sem);
        this.setState({
            startDate: new Date(this.state.selectedYear, 0, 1), 
            endDate: endDate,
            endDateToSend: new Date(this.state.selectedYear, 6, 1),
            isSem1Activated: true,
            isSem2Activated: false,
            isFullYearSelected: false,
            isTrim3Selected: false,
            isTrim4Selected: false,
            selectedMonthIndex: -1,
            dateType: 'semester'
        })
        this.props.saveDates(
          new Date(this.state.selectedYear, 0, 1), 
          this.compareToToday(new Date(this.state.selectedYear, 6, 1))
        )
        this.props.saveTimeFilters({
            startDate: new Date(this.state.selectedYear, 0, 1), 
            endDate: endDate,
            endDateToSend: new Date(this.state.selectedYear, 6, 1),
            isSem1Activated: true,
            isSem2Activated: false,
            isFullYearSelected: false,
            isTrim1Selected: false,
            isTrim2Selected: false,
            isTrim3Selected: false,
            isTrim4Selected: false,
            selectedMonthIndex: -1,
            dateType: 'semester',
            selectedYear: this.state.selectedYear
        })
    }
    private updateSem2() {
        let endDate = this.compareToToday(fromUTC(new Date(this.state.selectedYear, 12, 1)));
        this.setState({
            startDate: new Date(this.state.selectedYear, 6, 1), 
            endDate,
            endDateToSend: new Date(this.state.selectedYear, 12, 1),
            isSem1Activated: false,
            isSem2Activated: true,
            isFullYearSelected: false,
            isTrim1Selected: false,
            isTrim2Selected: false,
            selectedMonthIndex: -1,
            dateType: 'semester'
        })
        this.props.saveDates(
          new Date(this.state.selectedYear, 6, 1),
          this.compareToToday(new Date(this.state.selectedYear, 12, 1))
        )
        this.props.saveTimeFilters({
            startDate: new Date(this.state.selectedYear, 6, 1), 
            endDate,
            endDateToSend: new Date(this.state.selectedYear, 12, 1),
            isSem1Activated: false,
            isSem2Activated: true,
            isFullYearSelected: false,
            isTrim1Selected: false,
            isTrim2Selected: false,
            isTrim3Selected: false,
            isTrim4Selected: false,
            selectedMonthIndex: -1,
            dateType: 'semester',
            selectedYear: this.state.selectedYear
        })
    }
    changeDetailIndex = (newYear:number) => {
        this.setState({ selectedYear: newYear });
    }
    moveNext = () => {
      let newState = {...this.state};
      let selectedYear = newState.selectedYear;
        let endDate = this.state.isFullYearSelected || this.state.dateType === 'annual' ? 
        new Date(selectedYear +1, 12, 1) :
        this.state.isSem1Activated ? 
        new Date(selectedYear +1, 6, 1) :
        this.state.isSem2Activated ?
        new Date(selectedYear +1, 12, 1) :
        this.state.isTrim1Selected ?
        new Date(selectedYear +1, 3, 1) :
        this.state.isTrim2Selected ?
        new Date(selectedYear +1, 6, 1) :
        this.state.isTrim3Selected ?
        new Date(selectedYear +1, 9, 1) :
        this.state.isTrim4Selected ?
        new Date(selectedYear +1, 12, 1) :
        this.state.dateType === 'month' ?
        new Date(selectedYear +1, this.state.startDate.getMonth()+1, 1) :
        new Date(selectedYear +1, this.state.endDate.getMonth(), this.state.endDate.getDate())
      let monthIndex = newState.selectedMonthIndex 
      let startDate = new Date(selectedYear + 1, this.state.startDate.getMonth(), this.state.startDate.getDate());
      
      if (startDate > new Date(endDate.getFullYear(), endDate.getMonth(), 1)) {
        startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1)
        monthIndex = endDate.getMonth()
      }
      this.props.saveDates(
        startDate,
        this.compareToToday(endDate),
      )
      
      this.props.saveTimeFilters({
        selectedYear: selectedYear + 1,
        startDate: startDate,
        endDate: fromUTC(new Date(endDate)),
        selectedMonthIndex: monthIndex,
        endDateToSend: new Date(selectedYear, 12, 1),
        isSem1Activated: this.state.isSem1Activated,
        isSem2Activated: this.state.isSem2Activated,
        isFullYearSelected: this.state.isFullYearSelected,
        isTrim1Selected: this.state.isTrim1Selected,
        isTrim2Selected: this.state.isTrim2Selected,
        isTrim3Selected: this.state.isTrim3Selected,
        isTrim4Selected: this.state.isTrim4Selected,
        dateType: this.state.dateType
      })
      this.setState({ 
        selectedYear: selectedYear + 1,
        startDate: startDate,
        endDate: this.compareToToday(fromUTC(endDate)),
        maxDateRange: endDate,
        selectedMonthIndex: monthIndex
      });
    }
    movePrev = () => {
      let endDate = this.state.isFullYearSelected || this.state.dateType === 'annual' ? 
                    new Date(this.state.selectedYear -1, 12, 1) :
                    this.state.isSem1Activated ? 
                    new Date(this.state.selectedYear -1, 6, 1) :
                    this.state.isSem2Activated ?
                    new Date(this.state.selectedYear -1, 12, 1) :
                    this.state.isTrim1Selected ?
                    new Date(this.state.selectedYear -1, 3, 1) :
                    this.state.isTrim2Selected ?
                    new Date(this.state.selectedYear -1, 6, 1) :
                    this.state.isTrim3Selected ?
                    new Date(this.state.selectedYear -1, 9, 1) :
                    this.state.isTrim4Selected ?
                    new Date(this.state.selectedYear -1, 12, 1) :
                    this.state.dateType === "month" ?
                    new Date(this.state.selectedYear -1, this.state.startDate.getMonth()+1, 1) :
                    new Date(this.state.selectedYear -1, this.state.endDate.getMonth(), this.state.endDate.getDate())
      let selectedYear = this.state.selectedYear
      this.props.saveTimeFilters({
        selectedYear: selectedYear - 1,
        startDate: new Date(this.state.selectedYear - 1, this.state.startDate.getMonth(), this.state.startDate.getDate()),
        endDate: fromUTC(new Date(endDate)),
        endDateToSend: this.state.endDateToSend,
        isSem1Activated: this.state.isSem1Activated,
        isSem2Activated: this.state.isSem2Activated,
        isFullYearSelected: this.state.dateType === 'annual' ? true : this.state.isFullYearSelected,
        isTrim1Selected: this.state.isTrim1Selected,
        isTrim2Selected: this.state.isTrim2Selected,
        isTrim3Selected: this.state.isTrim3Selected,
        isTrim4Selected: this.state.isTrim4Selected,
        selectedMonthIndex: this.state.selectedMonthIndex ? this.state.selectedMonthIndex : -1,
        dateType: this.state.dateType
      })
      this.setState({ 
        selectedYear: selectedYear - 1,
        startDate: new Date(this.state.selectedYear - 1, this.state.startDate.getMonth(), this.state.startDate.getDate()),
        endDate: fromUTC(new Date(endDate)),
        maxDateRange: endDate,
      });
      this.props.saveDates(
        new Date(this.state.selectedYear - 1, this.state.startDate.getMonth(), 1),
        this.compareToToday(new Date(endDate)),
      )
    }
    handleYearChange = () => {
      let endDate = this.compareToToday(fromUTC(new Date(this.state.selectedYear, 12, 1)));
        this.setState({
            isFullYearSelected: true,
            startDate: new Date(this.state.selectedYear, 0, 1),
            endDate: endDate,
            endDateToSend: new Date(this.state.selectedYear, 12, 1),
            maxDateRange: endDate,
            dateType: 'annual'
        })
        this.props.saveTimeFilters({
            isFullYearSelected: true,
            startDate: new Date(this.state.selectedYear, 0, 1),
            endDate: endDate,
            dateType: "annual",
            endDateToSend: new Date(this.state.selectedYear, 12, 1),
            selectedYear: this.state.selectedYear,
            isSem1Activated: this.state.isSem1Activated,
            isSem2Activated: this.state.isSem2Activated,
            isTrim1Selected: this.state.isTrim1Selected,
            isTrim2Selected: this.state.isTrim2Selected,
            isTrim3Selected: this.state.isTrim3Selected,
            isTrim4Selected: this.state.isTrim4Selected,
            selectedMonthIndex: this.state.selectedMonthIndex,
        })
        this.props.saveDates(
          new Date(this.state.selectedYear, 0, 1),
          this.compareToToday(new Date(this.state.selectedYear, 12, 1))
        )
    }
    private updateTrim1() {
          let endDate = this.compareToToday(fromUTC(new Date(this.state.selectedYear, 3, 1)));

          this.setState({
            isTrim1Selected: true,
            isSem1Activated: false,
            isSem2Activated: false,
            isTrim2Selected: false,
            isTrim3Selected: false,
            isTrim4Selected: false,
            isFullYearSelected: false,
            selectedMonthIndex: -1,
            startDate: new Date(this.state.selectedYear, 0, 1),
            endDate: endDate,
            endDateToSend: new Date(this.state.selectedYear, 3, 1),
            maxDateRange: endDate,
            dateType: 'quarter'
        })
        this.props.saveTimeFilters({
            isTrim1Selected: true,
            isSem1Activated: false,
            isSem2Activated: false,
            isTrim2Selected: false,
            isTrim3Selected: false,
            isTrim4Selected: false,
            isFullYearSelected: false,
            selectedMonthIndex: -1,
            startDate: new Date(this.state.selectedYear, 0, 1),
            endDate: endDate,
            endDateToSend: new Date(this.state.selectedYear, 3, 1),
            dateType: 'quarter',
            selectedYear: this.state.selectedYear
        })
        this.props.saveDates(
          new Date(this.state.selectedYear, 0, 1),
          this.compareToToday(new Date(this.state.selectedYear, 3, 1))
        )
    }
    private updateTrim2() {
          let endDate = this.compareToToday(fromUTC(new Date(this.state.selectedYear, 6, 1)));
          this.setState({
            isTrim2Selected: true,
            isSem1Activated: false,
            isSem2Activated: false,
            isTrim1Selected: false,
            isTrim3Selected: false,
            isTrim4Selected: false,
            isFullYearSelected: false,
            selectedMonthIndex: -1,
            startDate: new Date(this.state.selectedYear, 3, 1),
            endDate: endDate,
            endDateToSend: new Date(this.state.selectedYear, 6, 1),
            maxDateRange: endDate,
            dateType: 'quarter'
          })
          this.props.saveTimeFilters({
            isTrim2Selected: true,
            isSem1Activated: false,
            isSem2Activated: false,
            isTrim1Selected: false,
            isTrim3Selected: false,
            isTrim4Selected: false,
            isFullYearSelected: false,
            selectedMonthIndex: -1,
            startDate: new Date(this.state.selectedYear, 3, 1),
            endDate: endDate,
            endDateToSend: new Date(this.state.selectedYear, 6, 1),
            dateType: 'quarter',
            selectedYear: this.state.selectedYear
          })
          this.props.saveDates(
            new Date(this.state.selectedYear, 3, 1),
            this.compareToToday(new Date(this.state.selectedYear, 6, 1))
          )
    }
    private updateTrim3() {
        let endDate = this.compareToToday(fromUTC(new Date(this.state.selectedYear, 9, 1)));
        this.setState({
            isTrim3Selected: true,
            isSem1Activated: false,
            isSem2Activated: false,
            isTrim1Selected: false,
            isTrim2Selected: false,
            isTrim4Selected: false,
            isFullYearSelected: false,
            selectedMonthIndex: -1,
            startDate: new Date(this.state.selectedYear, 6, 1),
            endDate: endDate,
            endDateToSend: new Date(this.state.selectedYear, 9, 1),
            maxDateRange: endDate,
            dateType: 'quarter'
        })
        this.props.saveTimeFilters({
            isTrim3Selected: true,
            isSem1Activated: false,
            isSem2Activated: false,
            isTrim1Selected: false,
            isTrim2Selected: false,
            isTrim4Selected: false,
            isFullYearSelected: false,
            selectedMonthIndex: -1,
            startDate: new Date(this.state.selectedYear, 6, 1),
            endDate: endDate,
            endDateToSend: new Date(this.state.selectedYear, 9, 1),
            dateType: 'quarter',
            selectedYear: this.state.selectedYear
        })
        this.props.saveDates(
          new Date(this.state.selectedYear, 6, 1), 
          this.compareToToday(new Date(this.state.selectedYear, 9, 1))
        )
    }
    private updateTrim4() {
      let endDate = this.compareToToday(fromUTC(new Date(this.state.selectedYear, 12, 1)));
        this.setState({
            isTrim4Selected: true,
            isSem1Activated: false,
            isSem2Activated: false,
            isTrim1Selected: false,
            isTrim3Selected: false,
            isTrim2Selected: false,
            isFullYearSelected: false,
            selectedMonthIndex: -1,
            startDate: new Date(this.state.selectedYear, 9, 1),
            endDate: endDate,
            endDateToSend: new Date(this.state.selectedYear, 12, 1),
            maxDateRange: endDate,
            dateType: 'quarter'
        })
        this.props.saveTimeFilters({
            isTrim4Selected: true,
            isSem1Activated: false,
            isSem2Activated: false,
            isTrim1Selected: false,
            isTrim3Selected: false,
            isTrim2Selected: false,
            isFullYearSelected: false,
            selectedMonthIndex: -1,
            startDate: new Date(this.state.selectedYear, 9, 1),
            endDate: endDate,
            endDateToSend: new Date(this.state.selectedYear, 12, 1),
            selectedYear: this.state.selectedYear,
            dateType: 'quarter'
        })
        this.props.saveDates(
          new Date(this.state.selectedYear, 9, 1), 
          this.compareToToday(new Date(this.state.selectedYear, 12, 1))
        )
    }
    private updateMonth(index: number) {
        let endDate = this.compareToToday(fromUTC(new Date(this.state.selectedYear, index + 1, 1)));
        this.setState({
            selectedMonthIndex: index,
            isFullYearSelected: false,
            isSem1Activated: false,
            isSem2Activated: false,
            isTrim1Selected: false,
            isTrim2Selected: false,
            isTrim3Selected: false,
            isTrim4Selected: false,
            startDate: new Date(this.state.selectedYear, index, 1),
            endDate: endDate,
            dateType: 'month',
            endDateToSend: new Date(this.state.selectedYear, index + 1, 1)
        })
        this.props.saveTimeFilters({
            selectedMonthIndex: index,
            isFullYearSelected: false,
            isSem1Activated: false,
            isSem2Activated: false,
            isTrim1Selected: false,
            isTrim2Selected: false,
            isTrim3Selected: false,
            isTrim4Selected: false,
            startDate: new Date(this.state.selectedYear, index, 1),
            endDate: endDate,
            endDateToSend: this.state.endDateToSend,
            selectedYear: this.state.selectedYear,
            dateType: 'month'
        })
        this.props.saveDates(
          new Date(this.state.selectedYear, index, 1),
          this.compareToToday(new Date(this.state.selectedYear, index + 1, 1))
        )
    }
    
  private isDisabled = (index:number) => {
    let endDate = getDateOneDayBeforeTodayHourZero(getDateTodayHourZero())
    
    return(
      index > endDate.getUTCMonth() && this.state.selectedYear === endDate.getFullYear()
    )
  } 

  private onStartDateChange = (date: any) => {
    this.setState({startDate: date});
    this.props.saveDates(date, this.state.endDate)
  }
  private onEndDateChange = (date: any) => {
    let endDate = this.compareToToday(date)
    this.setState({endDate: endDate});
    this.props.saveDates(this.state.startDate, endDate)
  }
  private drawSelectedDates() {
    const {classes, localeInfos} = this.props;
    let locale:Locale = fr;
    if (localeInfos.momentLocale === "en-us"){
        locale=enGB;
    } else if(localeInfos.momentLocale === "fr"){
        locale=fr
    } else if(localeInfos.momentLocale === "de"){
      locale=de
    }

    const materialTheme = createTheme({
      ...getTheme(this.props.layout.theme),
      overrides: {
        MuiPickersBasePicker: {
          pickerView: {
            maxWidth: "125px",
            minWidth: "125px"
          },
        },
      }
    })

    return(
      <MD.Grid container id='selected-dates' spacing={2}>
        <MD.Grid item xs={6} id='start-date' style={{marginTop: "-20px"}}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            <MD.FormLabel className={classes.inputLabelPropsStyle}>
              {this.props.i18n._("Start date")}
            </MD.FormLabel>
            <ThemeProvider theme={materialTheme}>
            <DatePicker
                autoOk
                disableToolbar
                className={classes.datePickerInput}
                views={["year"]}
                openTo={"year"}
                variant="inline"
                format={"dd MMM yyyy"}
                id="date-picker-dialog"
                value={this.state.startDate}
                maxDate={this.state.endDate}
                onChange={this.onStartDateChange.bind(this)}
                InputProps={{ disableUnderline: true, classes: { input: classes.inputPropsStyle}}}
                InputLabelProps={{ className: classes.inputLabelPropsStyle }}
            />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </MD.Grid>
        <MD.Grid item xs={6} id='end-date' style={{marginTop: "-20px"}}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          {/* <MD.TextField
            fullWidth
            className={classes.datePickerInput}
            variant='outlined'
            label={this.props.i18n._("Start date")}
            value={moment(this.state.startDate).format("DD MMM YYYY")}
            inputProps={{ 'aria-label': 'search' }}
            //onChange={this.onStartChange.bind(this)}
            disabled
            InputProps={{ classes: { input: classes.inputPropsStyle}}}
            InputLabelProps={{ className: classes.inputLabelPropsStyle }}
          /> */}
            <MD.FormLabel className={classes.inputLabelPropsStyle}>
              {this.props.i18n._("End date")}
            </MD.FormLabel>
            <ThemeProvider theme={materialTheme}>
            <DatePicker
                autoOk
                disableToolbar
                className={classes.datePickerInput}
                disableFuture
                views={["year"]}
                openTo={"year"}
                variant="inline"
                format={"dd MMM yyyy"}
                id="date-picker-dialog"
                value={this.state.endDate}
                minDate={this.state.startDate}
                onChange={this.onEndDateChange.bind(this)}
                InputProps={{ disableUnderline: true, classes: { input: classes.inputPropsStyle}}}
                InputLabelProps={{ className: classes.inputLabelPropsStyle }}
            />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
          {/* <MD.TextField
              fullWidth
              className={classes.datePickerInput}
              variant='outlined'
              label={this.props.i18n._("End date")}
              value={moment(this.state.endDate).format("DD MMM YYYY")}
              inputProps={{ 'aria-label': 'search' }}
              //onChange={this.onEndChange.bind(this)}
              disabled
              InputProps={{ classes: { input: classes.inputPropsStyle}}}
              InputLabelProps={{ className: classes.inputLabelPropsStyle }}
          /> */}
        </MD.Grid>
      </MD.Grid>
    )
  }

  private drawYearSelect() {
    const {classes, theme} = this.props;
    let endDate = new Date();
    return (
          <>
            <MD.IconButton
                onClick={this.movePrev.bind(this)}
                aria-label="first page"
                style={{paddingBottom: 0, paddingRight: 0, paddingLeft: 0}}
            >
                <NavigateBeforeIcon />
            </MD.IconButton>
            <MD.Button
            className={classes.buttonYear}
            id='year'
            style={{
            backgroundColor: this.state.isFullYearSelected ? this.props.theme.palette.secondary.main : undefined,
            color: this.state.isFullYearSelected ? theme.palette.secondary.dark : undefined,
            padding: '2px 15px'
          }}
            variant="outlined"
            onClick={this.handleYearChange.bind(this)}
            >
                {this.state.selectedYear}
            </MD.Button>
            <MD.IconButton
              onClick={this.moveNext.bind(this)}
              disabled={this.state.selectedYear >= endDate.getFullYear()}
              aria-label="first page"
              style={{paddingBottom: 0, paddingLeft: 0}}
            >
              <NavigateNextIcon />
          </MD.IconButton>
          </>
    )

  }
  private drawCalendar() {
    const {classes, theme} = this.props;
    return (
      <div id="calendar" style={{flexGrow: 1}}>
        <MD.Grid container spacing={0}>
          <MD.Grid item xs={12} style={{textAlign: 'center'}} >
            {this.drawYearSelect()}
          </MD.Grid>
          <MD.Grid item xs={6}>
              <MD.Button 
              className={classes.buttonCalendar}
              id='sem1'
              disabled={this.isDisabled(0)}
              style={{
                borderBottom: 0, borderRight: 0,
                backgroundColor: this.state.dateType !== 'calendar' && !this.isDisabled(0) && (this.state.isSem1Activated || this.state.isFullYearSelected) ? theme.palette.secondary.main : undefined,
                color: !this.isDisabled(0) && (this.state.isSem1Activated || this.state.isFullYearSelected) ? theme.palette.secondary.dark : undefined,
              }}
              variant = "outlined"
              onClick = {() => this.updateSem1()}>
                  {this.props.i18n._("1e Sem.")}
              </MD.Button>
            </MD.Grid>
          <MD.Grid item xs={6}>
              <MD.Button
              className={classes.buttonCalendar}
              id='sem2'
              disabled={this.isDisabled(6)}
              style={{
                borderBottom: 0,
                backgroundColor: this.state.dateType !== 'calendar' && !this.isDisabled(6) && (this.state.isSem2Activated || this.state.isFullYearSelected) ? theme.palette.secondary.main : undefined,
                color: !this.isDisabled(6) && (this.state.isSem2Activated || this.state.isFullYearSelected) ? theme.palette.secondary.dark : undefined
              }}
              variant = "outlined"
              onClick = {() => this.updateSem2()}
              >
                  {this.props.i18n._("2e Sem.")}
              </MD.Button>
          </MD.Grid>
          <MD.Grid item xs={3}>
            <MD.Button 
              className={classes.buttonCalendar}
              id='trim1'
              disabled={this.isDisabled(0)}
              style={{
                borderRight: 0,
                backgroundColor: this.state.dateType !== 'calendar' && !this.isDisabled(0) && (this.state.isTrim1Selected || this.state.isSem1Activated || this.state.isFullYearSelected) ? theme.palette.secondary.main : undefined,
                color: !this.isDisabled(0) && (this.state.isTrim1Selected || this.state.isSem1Activated || this.state.isFullYearSelected) ? theme.palette.secondary.dark : undefined,
              }}
              variant = "outlined"
              onClick = {() => this.updateTrim1()}>
                  {this.props.i18n._("1e Trim.")}
              </MD.Button>
          </MD.Grid>
          <MD.Grid item xs={3}>
            <MD.Button
            className={classes.buttonCalendar}
            id='trim2'
            disabled={this.isDisabled(3)}
            style={{
              borderRight: 0,
              backgroundColor: this.state.dateType !== 'calendar' && !this.isDisabled(3) && (this.state.isTrim2Selected || this.state.isSem1Activated || this.state.isFullYearSelected) ? theme.palette.secondary.main : undefined,
              color: !this.isDisabled(3) && (this.state.isTrim2Selected || this.state.isSem1Activated || this.state.isFullYearSelected) ? theme.palette.secondary.dark : undefined,
            }}
            variant = "outlined"
            onClick = {() => this.updateTrim2()}
            >
              {this.props.i18n._("2e Trim.")}
          </MD.Button>
          </MD.Grid>
          <MD.Grid item xs={3}>
            <MD.Button 
            className={classes.buttonCalendar}
            id='trim3'
            disabled={this.isDisabled(6)}
            style={{
              borderRight: 0,
              backgroundColor: this.state.dateType !== 'calendar' && !this.isDisabled(6) && (this.state.isTrim3Selected || this.state.isSem2Activated || this.state.isFullYearSelected) ? theme.palette.secondary.main : undefined,
              color: !this.isDisabled(6) && (this.state.isTrim3Selected || this.state.isSem2Activated || this.state.isFullYearSelected) ? theme.palette.secondary.dark : undefined
            }}
            variant = "outlined"
            onClick = {() => this.updateTrim3()}>
              {this.props.i18n._("3e Trim.")}
          </MD.Button>
          </MD.Grid>
          <MD.Grid item xs={3}>
            <MD.Button
            className={classes.buttonCalendar}
            id='trim4'
            disabled={this.isDisabled(9)}
            style={{
              backgroundColor: this.state.dateType !== 'calendar' && !this.isDisabled(9) && (this.state.isTrim4Selected ||this.state.isSem2Activated || this.state.isFullYearSelected) ? theme.palette.secondary.main : undefined,
              color: !this.isDisabled(9) && (this.state.isTrim4Selected ||this.state.isSem2Activated || this.state.isFullYearSelected) ? theme.palette.secondary.dark : undefined, 
            }}
            variant = "outlined"
            onClick = {() => this.updateTrim4()}
            >
              {this.props.i18n._("4e Trim.")}
          </MD.Button>
        </MD.Grid>
          <MD.Grid item xs={1}>
            <MD.Button 
            className={classes.monthButton}
            disabled={this.isDisabled(0) }
            style={{
              backgroundColor: this.state.dateType !== 'calendar' && !this.isDisabled(0) && (this.state.selectedMonthIndex === 0 || this.state.isTrim1Selected || this.state.isSem1Activated || this.state.isFullYearSelected) ? theme.palette.secondary.main : undefined,
              color: !this.isDisabled(0) && (this.state.selectedMonthIndex === 0 || this.state.isTrim1Selected || this.state.isSem1Activated || this.state.isFullYearSelected) ? theme.palette.secondary.dark : undefined
            }}
            variant = "outlined"
            onClick = {() => this.updateMonth(0)}>
              J
            </MD.Button>
          </MD.Grid>
          <MD.Grid item xs={1}>
            <MD.Button
            className={classes.monthButton}
            disabled={this.isDisabled(1) }
            style={{
              backgroundColor: this.state.dateType !== 'calendar' && !this.isDisabled(1) && (this.state.selectedMonthIndex === 1 ||this.state.isTrim1Selected ||this.state.isSem1Activated || this.state.isFullYearSelected) ? theme.palette.secondary.main : undefined,
              color: !this.isDisabled(1) && (this.state.selectedMonthIndex === 1 ||this.state.isTrim1Selected ||this.state.isSem1Activated || this.state.isFullYearSelected) ? theme.palette.secondary.dark : undefined
            }}
            variant = "outlined"
            onClick = {() => this.updateMonth(1)}
            >
                F
            </MD.Button>
          </MD.Grid>
          <MD.Grid item xs={1}>
            <MD.Button 
            className={classes.monthButton}
            disabled={this.isDisabled(2) }
            style={{
              backgroundColor: this.state.dateType !== 'calendar' && !this.isDisabled(2) && (this.state.selectedMonthIndex === 2 || this.state.isTrim1Selected || this.state.isSem1Activated || this.state.isFullYearSelected) ? theme.palette.secondary.main : undefined,
              color: !this.isDisabled(2) && (this.state.selectedMonthIndex === 2 || this.state.isTrim1Selected || this.state.isSem1Activated || this.state.isFullYearSelected) ? theme.palette.secondary.dark : undefined
            }}
            variant = "outlined"
            onClick = {() => this.updateMonth(2)}>
              M
          </MD.Button>
          </MD.Grid>
          <MD.Grid item xs={1}>
            <MD.Button
            className={classes.monthButton}
            disabled={this.isDisabled(3)}
            style={{
              backgroundColor: this.state.dateType !== 'calendar' && !this.isDisabled(3) && (this.state.selectedMonthIndex === 3 ||this.state.isTrim2Selected || this.state.isSem1Activated || this.state.isFullYearSelected) ? theme.palette.secondary.main : undefined,
              color: !this.isDisabled(3) && (this.state.selectedMonthIndex === 3 ||this.state.isTrim2Selected || this.state.isSem1Activated || this.state.isFullYearSelected) ? theme.palette.secondary.dark : undefined
            }}
            variant = "outlined"
            onClick = {() => this.updateMonth(3)}
            >
              A
          </MD.Button>
          </MD.Grid>
          <MD.Grid item xs={1}>
            <MD.Button
            className={classes.monthButton}
            disabled={this.isDisabled(4) }
            style={{
              backgroundColor: this.state.dateType !== 'calendar' && !this.isDisabled(4) && (this.state.selectedMonthIndex === 4 || this.state.isTrim2Selected || this.state.isSem1Activated || this.state.isFullYearSelected) ? theme.palette.secondary.main : undefined,
              color: !this.isDisabled(4) && (this.state.selectedMonthIndex === 4 || this.state.isTrim2Selected || this.state.isSem1Activated || this.state.isFullYearSelected) ? theme.palette.secondary.dark : undefined
            }}
            variant = "outlined"
            onClick = {() => this.updateMonth(4)}
            >
              M
          </MD.Button>
          </MD.Grid>
          <MD.Grid item xs={1}>
            <MD.Button
            className={classes.monthButton}
            disabled={this.isDisabled(5) }
            style={{
              backgroundColor: this.state.dateType !== 'calendar' && !this.isDisabled(5) && (this.state.selectedMonthIndex === 5 || this.state.isTrim2Selected || this.state.isSem1Activated || this.state.isFullYearSelected) ? theme.palette.secondary.main : undefined,
              color: !this.isDisabled(5) && (this.state.selectedMonthIndex === 5 || this.state.isTrim2Selected || this.state.isSem1Activated || this.state.isFullYearSelected) ? theme.palette.secondary.dark : undefined
            }}
            variant = "outlined"
            onClick = {() => this.updateMonth(5)}
            >
              J
          </MD.Button>
          </MD.Grid>
          <MD.Grid item xs={1}>
            <MD.Button 
            className={classes.monthButton}
            disabled={this.isDisabled(6) }
            style={{
              backgroundColor: this.state.dateType !== 'calendar' && !this.isDisabled(6) && (this.state.selectedMonthIndex === 6 || this.state.isTrim3Selected || this.state.isSem2Activated || this.state.isFullYearSelected) ? theme.palette.secondary.main : undefined,
              color: !this.isDisabled(6) && (this.state.selectedMonthIndex === 6 || this.state.isTrim3Selected || this.state.isSem2Activated || this.state.isFullYearSelected) ? theme.palette.secondary.dark : undefined
            }}
            variant = "outlined"
            onClick = {() => this.updateMonth(6)}>
              J
          </MD.Button>
          </MD.Grid>
          <MD.Grid item xs={1}>
            <MD.Button
            className={classes.monthButton}
            disabled={this.isDisabled(7)}
            style={{
              backgroundColor: this.state.dateType !== 'calendar' && !this.isDisabled(7) && (this.state.selectedMonthIndex === 7 || this.state.isTrim3Selected || this.state.isSem2Activated || this.state.isFullYearSelected) ? theme.palette.secondary.main : undefined,
              color: !this.isDisabled(7) && (this.state.selectedMonthIndex === 7 || this.state.isTrim3Selected || this.state.isSem2Activated || this.state.isFullYearSelected) ? theme.palette.secondary.dark : undefined
            }}
            variant = "outlined"
            onClick = {() => this.updateMonth(7)}
          >
              A
          </MD.Button>
          </MD.Grid>
          <MD.Grid item xs={1}>
            <MD.Button
            className={classes.monthButton}
            disabled={this.isDisabled(8)}
            style={{
              backgroundColor: this.state.dateType !== 'calendar' && !this.isDisabled(8) && (this.state.selectedMonthIndex === 8 || this.state.isTrim3Selected || this.state.isSem2Activated || this.state.isFullYearSelected) ? theme.palette.secondary.main : undefined,
              color: !this.isDisabled(8) && (this.state.selectedMonthIndex === 8 || this.state.isTrim3Selected || this.state.isSem2Activated || this.state.isFullYearSelected) ? theme.palette.secondary.dark : undefined
            }}
            variant = "outlined"
            onClick = {() => this.updateMonth(8)}>
              S
          </MD.Button>
          </MD.Grid>
          <MD.Grid item xs={1}>
            <MD.Button
            className={classes.monthButton}
            disabled={this.isDisabled(9) }
            style={{
              backgroundColor: this.state.dateType !== 'calendar' && !this.isDisabled(9) && (this.state.selectedMonthIndex === 9 || this.state.isTrim4Selected || this.state.isSem2Activated || this.state.isFullYearSelected) ? theme.palette.secondary.main : undefined,
              color: !this.isDisabled(9) && (this.state.selectedMonthIndex === 9 || this.state.isTrim4Selected || this.state.isSem2Activated || this.state.isFullYearSelected) ? theme.palette.secondary.dark : undefined
            }}
            variant = "outlined"
            onClick = {() => this.updateMonth(9)}
          >
              O
          </MD.Button>
          </MD.Grid>
          <MD.Grid item xs={1}>
           <MD.Button
            className={classes.monthButton}
            disabled={ this.isDisabled(10) }
            style={{
              backgroundColor: this.state.dateType !== 'calendar' && !this.isDisabled(10) && (this.state.selectedMonthIndex === 10 || this.state.isTrim4Selected || this.state.isSem2Activated || this.state.isFullYearSelected) ? theme.palette.secondary.main : undefined,
              color: (this.state.selectedMonthIndex === 10 || this.state.isTrim4Selected || this.state.isSem2Activated || this.state.isFullYearSelected) && ! this.isDisabled(10) ? theme.palette.secondary.dark : undefined
            }}
            variant = "outlined"
            onClick = {() => this.updateMonth(10)}
          >
              N
          </MD.Button>
          </MD.Grid>
          <MD.Grid item xs={1}>
            <MD.Button
            className={classes.monthButton}
            disabled={this.isDisabled(11) }
            style={{
              backgroundColor: this.state.dateType !== 'calendar' && !this.isDisabled(11) && (this.state.selectedMonthIndex === 11 || this.state.isTrim4Selected || this.state.isSem2Activated || this.state.isFullYearSelected )? theme.palette.secondary.main : undefined, borderRight: "1px solid gray",
              color: !this.isDisabled(11) && (this.state.selectedMonthIndex === 11 || this.state.isTrim4Selected || this.state.isSem2Activated || this.state.isFullYearSelected ) ? theme.palette.secondary.dark : undefined
            }}
            variant = "outlined"
            onClick = {() => this.updateMonth(11)}
            >
                D
            </MD.Button>
            </MD.Grid>
        </MD.Grid>
      </div>
            
    )
  }
  public render(): React.ReactNode {
      //console.log("startDate: ", this.state.startDate)
      //console.log("endDate: ", this.state.endDate)
    return (
        <MD.Grid container justifyContent="space-around" style={{marginTop: 10}}>
          {
            this.drawSelectedDates()
          }
          { 
            this.drawCalendar()
          }
        </MD.Grid>
      );
  }
}

const mapStateToProps = ( { layout, i18n, searchResults } : IApplicationState) => ({
  layout,
  localeInfos: i18n.localeInfos,
  i18n: i18n.i18n,
  search: searchResults
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setDrawerContent: (c: React.ReactNode) => dispatch(setDrawerContent(c)),
  saveTimeFilters: (filters: ITimeFilters) => dispatch(saveTimeFilters(filters)),
});

const Calendar = connect( mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
    (MD.withTheme(MD.withStyles(styles)(CalendarComponent)));

export { Calendar };

import * as MD from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { WaveformContainer } from './Waveform.styled';
//import '../../../node_modules/video.js/dist/video-js.css';
import videojs from 'video.js';
import Wavesurfer from 'videojs-wavesurfer/dist/videojs.wavesurfer.js';
import 'videojs-wavesurfer/dist/css/videojs.wavesurfer.css';
import WaveSurfer from 'wavesurfer.js';
import './style.css';
import { getTheme } from '../../Themes';
import * as WaveSurferRegions from 'wavesurfer.js/dist/plugin/wavesurfer.regions.js';
import * as WaveSurferTimeline from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.js';
import { IApplicationState } from '../../Store';
import { generateHslaColors, isNullOrUndefined, randomHsl } from '../../Utils/Various';
import { Logger } from '../../Utils/Logger';
import { IWaveformState } from '../../Store/Waveform/Types';
import { saveRegionsSurfer, selectMusic, updateSelectedMusicInterval } from '../../Store/SpotDetails/Actions';
import { ISpotDetailsState } from '../../Store/SpotDetails/Types';
import { IMusicBroadcasts } from '../../Store/Search/Types';
import { ButtonDensed } from '../../Themes/StyledElements';

Wavesurfer

const REWIND_STEP = 0.04
const REWIND_STEP_BIG_STEP = 5

const styles = MD.createStyles({
});

interface IWaveformProps {
    master: string;
    videoNode: any;
    broadcastPlaying: any;
    playPauseRegion: (ev?: any, mbId?: number) => void;
};

interface IWaveFormState {
    canvasWidth: any;
    canvasHeight: any;
    regions: any;
}

interface IPropsFromState {
    spot: ISpotDetailsState;
    waveform: IWaveformState;
}

interface IPropsFromDispatch {
    updateSelectedMusicInterval: typeof updateSelectedMusicInterval;
    selectMusic: typeof selectMusic;
    saveRegionsSurfer: typeof saveRegionsSurfer;
}

type AllProps = IWaveformProps & IPropsFromState & IPropsFromDispatch;

const theme = getTheme();
const { innerWidth } = window;
const TIME_INTERVAL_DIVISION = 60;

export class WaveformComponent extends Component<AllProps, IWaveFormState> {
    private player: any;
    private videoNode: React.RefObject<HTMLVideoElement>;
    private videoNodeDOM: any;
    private keyboardHandler: (ev: KeyboardEvent) => void;
    private readyForCapture = false;

    public constructor(props: any) {
        super(props);
        this.state = {
            canvasWidth: '50%',
            canvasHeight: 'auto',
            regions: {}
        }
        this.videoNode = React.createRef();
        this.keyboardHandler = this.handleKeyboardEvent.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keyboardHandler);
        let timeInterval = 0.1;

        this.videoNodeDOM = document.querySelectorAll('#myVideo')[0];
        if (this.videoNodeDOM) {
            this.videoNodeDOM.addEventListener('loadeddata', this.onloadeddata())
        }

        if (this.props.spot) {
            const duration = this.props.spot.metadatas.duration;
            if (duration && duration > 10) {
                timeInterval = duration / TIME_INTERVAL_DIVISION;
            }
        }

        const WaveFormOptions = {
            bigPlayButton: true,
            autoplay: false,
            width: innerWidth,
            height: 340,
            fluid: false,
            controls: true,
            plugins: {
                wavesurfer: {
                    backend: 'MediaElement',
                    displayMilliseconds: true,
                    debug: true,
                    waveColor: theme.palette.text.primary,
                    progressColor: theme.palette.primary.main,
                    cursorColor: theme.palette.primary.light,
                    hideScrollbar: true,
                    container: '#waveform',
                    plugins: [
                        // timeline
                        WaveSurferTimeline.create({
                            container: '#wave-timeline',
                            primaryColor: '#fff',
                            secondaryColor: "#fff",
                            primaryFontColor: '#fff',
                            secondaryFontColor: '#fff',
                            timeInterval: timeInterval
                        }),
                        WaveSurferRegions.create({
                            marginTop: '78px',
                            regionHeight: '65%',
                            isResizing: false,
                            isDragging: false,
                            regions: [],
                        })
                    ]
                },
            }
        }

        this.player = videojs(this.props.videoNode, WaveFormOptions, () => {
            // print version information at startup
            const version_info = 'Using video.js ' + videojs.VERSION +
                ' with videojs-wavesurfer ' + videojs.getPluginVersion('wavesurfer') +
                ', wavesurfer.js ' + WaveSurfer.VERSION + ' and React ' + React.version;
            videojs.log(version_info);
            // load file
            this.player.src({ src: this.props.master, type: 'video/mp4' });
        });

        this.player.on('waveReady', () => {
            if (this.player.wavesurfer() && this.player.wavesurfer().surfer) {
                let elem: any = document.getElementsByTagName('wave')[0];
                if (elem) {
                    elem.style.height = 'inherit'
                }
                this.player.wavesurfer().surfer.on('region-update-end', (event: any) => {
                    if (this.props.spot.selectedMusicID) {
                        this.props.updateSelectedMusicInterval(this.props.spot.selectedMusicID, event.start_date, event.end_date)
                    }
                });
                this.updateRegion();
                this.readyForCapture = true;
            }
        });
        // error handling
        this.player.on('error', (element: any, error: any) => {
            console.error(error);
            document.removeEventListener('keydown', this.keyboardHandler);
        });
    }

    // destroy player on unmount
    componentWillUnmount() {
        document.removeEventListener('keydown', this.keyboardHandler);
        if (this.player) {
            this.player.dispose();
        }
        this.readyForCapture = false;
    }

    private onloadeddata = () => {
        if (this.videoNodeDOM && (this.videoNodeDOM.offsetWidth !== this.state.canvasWidth || this.videoNodeDOM.offsetHeight !== this.state.canvasHeight)) {
            this.setState({
                canvasWidth: this.videoNodeDOM.offsetWidth,
                canvasHeight: this.videoNodeDOM.offsetHeight,
            })
        }
    }

    public setCurrentTime = (value: number) => {
        if (this.player.wavesurfer() && this.player.wavesurfer().surfer) {
            this.player.wavesurfer().surfer.setCurrentTime(value);
        }
    }

    public componentDidUpdate(prevProps: AllProps, prevState: IWaveFormState) {
        this.player.on('fullscreenchange', (e: any) => {
            if (this.player.isFullscreen()) {
                this.player.wavesurfer().redrawWaveform = () => { };
            }
        })

        if (prevProps.waveform.force !== this.props.waveform.force) {
            this.setCurrentTime(this.props.waveform.cursor);
        }

        if (this.props.spot.selectedMusicID === null && this.player.wavesurfer() && this.player.wavesurfer().surfer) {
            this.player.wavesurfer().surfer.clearRegions();
            return;
        }
        if (this.props.spot.selectedMusicID !== prevProps.spot.selectedMusicID) {
            this.updateRegion()
        }
    }

    private handleKeyboardEvent(ev: KeyboardEvent) {
        let charCode = String.fromCharCode(ev.which).toLowerCase();
        if (
            charCode !== 'c' && charCode !== 'v' && (
                (document.activeElement === null) ||
                (document.activeElement.tagName === 'INPUT') ||
                (document.activeElement.tagName === 'TEXTAREA'))
        ) {
            return;
        }

        switch (ev.which) {
            // Spacebar
            case 32:
                ev.stopPropagation();
                ev.preventDefault();
                if (this.player.wavesurfer && this.player.wavesurfer().surfer) {
                    //this.player.wavesurfer().surfer.playPause();
                    this.props.playPauseRegion(ev, this.props.broadcastPlaying)
                }
                break;

            // Left - backward with 0.04s
            case 37:
                ev.stopPropagation();
                ev.preventDefault();
                if (this.player.wavesurfer && this.player.wavesurfer().surfer) {
                    this.player.wavesurfer().surfer.skipBackward(REWIND_STEP);
                }
                break;
            // UP - backward with 5s
            case 38:
                ev.stopPropagation();
                ev.preventDefault();
                if (this.player.wavesurfer && this.player.wavesurfer().surfer) {
                    this.player.wavesurfer().surfer.skipBackward(REWIND_STEP_BIG_STEP);
                }
                break;
            // Right - forward with 0.04s
            case 39:
                ev.stopPropagation();
                ev.preventDefault();
                if (this.player.wavesurfer && this.player.wavesurfer().surfer) {
                    this.player.wavesurfer().surfer.skipForward(REWIND_STEP);
                }
                break;
            // DOWN - forward with 5s
            case 40:
                ev.stopPropagation();
                ev.preventDefault();
                if (this.player.wavesurfer && this.player.wavesurfer().surfer) {
                    this.player.wavesurfer().surfer.skipForward(REWIND_STEP_BIG_STEP);
                }
                break;
            // 'c' disable selection (clear regions)
            case 67:
                //ev.stopPropagation();
                //ev.preventDefault();
                this.props.selectMusic(null)
                break;
            // 'd' - Mark the start of timeline
            case 68:
                this.updateStart();
                break;
            // 'f' - Mark the end of the timeline
            case 70:
                this.updateEnd();
                break;
            // 'm' - Set music duration to MAX (total ad duration)
            case 77:
                ev.stopPropagation();
                ev.preventDefault();
                this.adjustStart();
                this.adjustEnd();
                break;
            // 'p' - play the selection
            case 80:
                //this.playRegion();
                break;
            // 's' - Take a snapshot
            case 83:
                try {
                    this.getSnapshot().then((data: any) => {
                        null;
                    });
                } catch (err) {
                    Logger.warn(err, 'Could not generate snapshot');
                }

                break;
            // 'v' - Adjust music to finish with advertisement
            case 86:
                ev.stopPropagation();
                ev.preventDefault();
                this.adjustEnd();
                break;
            // 'x' - Adjust music to start with advertisement
            case 88:
                ev.stopPropagation();
                ev.preventDefault();
                this.adjustStart();
                break;
            // 'z' - Reset all broadcasts to initial state
            case 90:
                this.restoreMusic()
                break;
        }
    }

    private restoreMusic = () => {
        this.props.selectMusic(null)
    }

    private adjustStart = () => {
        if (this.player.wavesurfer() && this.player.wavesurfer().surfer) {
            const mb: IMusicBroadcasts[] = this.seekSelectedMusic();
            const music = mb[0]
            if (this.props.spot.selectedMusicID && music) {
                this.props.updateSelectedMusicInterval(this.props.spot.selectedMusicID, 0, music.end_time)
                this.updateRegion()
            }
        }
    }

    private adjustEnd = () => {
        if (this.player.wavesurfer() && this.player.wavesurfer().surfer) {
            const end = this.player.wavesurfer().surfer.getDuration()
            const music: IMusicBroadcasts[] = this.seekSelectedMusic();
            if (this.props.spot.selectedMusicID && music) {
                const mb = music[0]
                this.props.updateSelectedMusicInterval(this.props.spot.selectedMusicID, mb.start_time, mb.end_time
                )
                this.updateRegion()
            }
        }
    }

    public getSnapshot(): Promise<string> {
        return new Promise((resolve, reject) => {
            if (!this.readyForCapture) {
                return reject(new Error('Player is not ready for a snapshot'));
            }
            const playerElem = document.getElementsByTagName('video');
            if (playerElem.length === 0) return;

            this.getSnapshotNormal()
                .then(resolve)
                .catch((err) => {
                    reject(err);
                });
        });
    }

    private getSnapshotNormal(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if (!this.readyForCapture) {
                return reject(new Error('Player is not ready for a snapshot'));
            }
            const canvas = document.createElement('canvas') as HTMLCanvasElement;
            const playerElem = document.getElementsByTagName('video');
            if (playerElem.length === 0) return;
            canvas.width = playerElem[0].videoWidth;
            canvas.height = playerElem[0].videoHeight;
            const ctx = canvas.getContext('2d');

            if (isNullOrUndefined(ctx)) {
                return reject(new Error('Could not create a canvas context'));
            }
            ctx.drawImage(playerElem[0], 0, 0, playerElem[0].videoWidth, playerElem[0].videoHeight);
            resolve(canvas.toDataURL('image/png'));
        });
    }

    private playRegion = (id: number) => {
        if (this.player.wavesurfer && this.player.wavesurfer().surfer && this.player.wavesurfer().surfer.regions.list[id]) {
            this.player.wavesurfer().surfer.regions.list[id].play();
        }
    }

    private updateStart = () => {
        if (this.player.wavesurfer() && this.player.wavesurfer().surfer) {
            const currentTime = this.player.wavesurfer().surfer.getCurrentTime()
            const music: IMusicBroadcasts[] = this.seekSelectedMusic();
            if (this.props.spot.selectedMusicID && music) {
                const mb = music[0]
                this.props.updateSelectedMusicInterval(this.props.spot.selectedMusicID, currentTime, mb.end_time)
                this.updateRegion()
            }
        }
    }

    private updateEnd = () => {
        if (this.player.wavesurfer() && this.player.wavesurfer().surfer) {
            const currentTime = this.player.wavesurfer().surfer.getCurrentTime()
            const music: IMusicBroadcasts[] = this.seekSelectedMusic();
            if (this.props.spot.selectedMusicID && music) {
                const mb = music[0]
                this.props.updateSelectedMusicInterval(this.props.spot.selectedMusicID, mb.start_time, currentTime)
                this.updateRegion()
            }
        }
    }

    private updateRegion = () => {
        if (this.props.spot && this.props.spot.selectedMusicID) {
            const music_broadcasts: IMusicBroadcasts[] = this.seekSelectedMusic();
            if (this.player.wavesurfer() && this.player.wavesurfer().surfer && music_broadcasts) {
                //this.player.wavesurfer().surfer.clearRegions();
                let c = generateHslaColors(50, 100, 0.3, music_broadcasts.length)
                for (let i = 0; i < music_broadcasts.length; i++) {
                    let mb = music_broadcasts[i]
                    this.player.wavesurfer().surfer.addRegion({
                        id: mb.id,
                        start: mb.start_time,
                        end: mb.end_time,
                        color: randomHsl(),//c[i],
                        drag: false,
                        resize: false
                    });
                }
                let surfer: any = this.player.wavesurfer().surfer;
                let regions: any = this.player.wavesurfer().surfer.regions.list;
                this.props.saveRegionsSurfer(regions, surfer)
                this.setState({
                    regions: this.player.wavesurfer().surfer.regions.list
                })
            }
        }
    }

    private seekSelectedMusic = (): any => {
        if (this.props.spot !== null) {
            const id = this.props.spot.selectedMusicID;
            let metadatas = this.props.spot.metadatas.metadatas;
            let mb: IMusicBroadcasts[] = metadatas ? metadatas.music_broadcast : []
            return mb
        }
        return null;
    }

    private drawPlayButtons(regions: any) {
        let elem: any = Object.keys(regions).map((key: any) => {
            let region = regions[key]
            return (
                <ButtonDensed
                    style={{ backgroundColor: region.color, marginRight: 10 }}
                    onClick={() => this.playRegion(region.id)}>
                    <span>
                        Play Region {region.id}
                    </span>
                </ButtonDensed>
            )
        })
        return elem
    }

    render() {
        return (
            <WaveformContainer style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column", height: 240 }}>
                <div data-vjs-player style={{ width: '100%', marginTop: "20px" }}>
                    <div id="wave-timeline" style={{ display: 'block', width: "calc(100%)" }}></div>
                    <div id="waveform" style={{ display: 'inline-block', width: "calc(100%)", height: 180 }} ></div>
                </div>
            </WaveformContainer>
        );
    }
}

const mapStateToProps = ({ spotDetails, waveform }: IApplicationState) => ({
    spot: spotDetails,
    waveform
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateSelectedMusicInterval: (id: string, start: number, end: number) => dispatch(updateSelectedMusicInterval(id, start, end)),
    selectMusic: (id: string | null) => dispatch(selectMusic(id)),
    saveRegionsSurfer: (regions: any, surfer: any) => dispatch(saveRegionsSurfer(regions, surfer)),
});

export const Waveform = connect(mapStateToProps, mapDispatchToProps)(MD.withStyles(styles)(WaveformComponent));
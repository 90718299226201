import { AxiosResponse } from 'axios'
import { clearTypedStorage } from '../Utils/TypedStorage'
import { IContentDisposition } from './Types'

export class StatusCodeError extends Error {
  public code: number
  public body: string
  public reason: string
  public message: string

  constructor(code: number, body: string) {
    super(`Unexpected status code: ${code} , ${JSON.parse(body).message}`)
    Object.setPrototypeOf(this, StatusCodeError.prototype)

    this.code = code
    this.body = body
    this.reason = ''
    this.message = ''
    try {
      const data = JSON.parse(body)
      if (data.error === true && typeof data.reason === 'string') {
        this.reason = data.reason
      }
      if (
        data.status &&
        data.status.toLowerCase() === 'error' &&
        typeof data.message === 'string'
      ) {
        this.message = data.message
      }
    } catch (err) {
      /**/
    }
  }
}

/**
 * Returns a promise of fetch() result as arraybuffer or a StatusCodeError if status code is not 20x
 */
export async function StatusCodeFilterAxios(
  res: AxiosResponse<any>
): Promise<ArrayBuffer> {
  if (res.status < 200 || res.status > 299) {
    if (res.status === 401) {
      //window.document.location.href = '/logout'
      return Promise.reject(new Error('You are not authentificated'))
    }
    throw new StatusCodeError(res.status, res.data)
  }
  return res.data
}

export async function StatusCodeFilter(
  res: any
): Promise<ArrayBuffer | IContentDisposition> {
  if (res.status < 200 || res.status > 299) {
    if (res.status === 401) {
      //window.document.location.href = '/logout'
      //clearTypedStorage()
      return Promise.reject(new Error('You are not authentificated'))
    }
    return res.text().then((body: string) => {
      let status = res.status || res.status_code
      throw new StatusCodeError(status, body)
    })
  }
  let contentDisposition = res.headers.get('content-disposition')
  if (contentDisposition) {
    return {
      content: res.blob(),
      contentDisposition: contentDisposition,
    }
  }
  return res.arrayBuffer()
}

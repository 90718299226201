export enum IUserActionTypes {
	LOGIN = '@@user/LOGIN',
	LOGOUT = '@@user/LOGOUT',
	LOGOUT_SUCCESS = '@@user/LOGOUT_SUCCESS',
	LOGIN_SUCCESS = '@@user/LOGIN_SUCCESS',
	LOGIN_ERROR = '@@user/LOGIN_ERROR',
	SAVE_EXTERNAL_TOKEN = '@@user/SAVE_EXTERNAL_TOKEN',
	VERIFY_EXTERNAL_TOKEN = '@@user/VERIFY_EXTERNAL_TOKEN',
	VERIFY_EXTERNAL_TOKEN_ERROR = '@@user/VERIFY_EXTERNAL_TOKEN_ERROR',
	VERIFY_EXTERNAL_TOKEN_SUCCESS = '@@user/VERIFY_EXTERNAL_TOKEN_SUCCESS',
	GET_TOKEN_PLAYER = '@@user/GET_TOKEN_PLAYER',
	GET_TOKEN_PLAYER_SUCCESS = '@@user/GET_TOKEN_PLAYER_SUCCESS',
}
export interface IRights {
	add?: boolean
	delete?: boolean
	edit?: boolean
	read?: boolean
}
export interface IPermission {
	show_media_passages: IRights
	show_all_declarations: IRights
	permissions_export_passages: IRights
	permissions_export_declarations: IRights
}
export interface IUserState {
	readonly username: string
	readonly token: string
	readonly expires: Date
	readonly userInfo: IUserInfo
	readonly tokenExternal: string | null
	readonly permissions: IPermission
	readonly tokenPlayer: string
}

export interface IParentInfo {
	id: number
	name: string
	email: string | null
	phone: string | null
	localisation: string | null
}

export const initialParentInfo: IParentInfo = {
	email: null,
	phone: null,
	id: 0,
	name: '',
	localisation: null,
}
export interface IUpdateUserParams {
	token: string
	society_id: number
	society_email: string
	society_name: string
	society_surname: string
	group_email: string
	group_id: number
	group_name: string
	group_localisation: string[]
}
export interface IUserInfo {
	readonly email: string
	readonly firstname: string
	readonly id: number
	readonly name: string
	readonly position: string | null
	localisation: string[] | null
	readonly country: []
	readonly parent_info: IParentInfo
}

export const initialUserInfo: IUserInfo = {
	email: '',
	firstname: '',
	id: 0,
	name: '',
	position: null,
	country: [],
	localisation: null,
	parent_info: initialParentInfo,
}
export const CPermissionInitial: IPermission = {
	show_media_passages: {},
	show_all_declarations: {},
	permissions_export_passages: {},
	permissions_export_declarations: {},
}
export const initialUserState: IUserState = {
	expires: new Date(0),
	token: '',
	username: '',
	userInfo: initialUserInfo,
	tokenExternal: null,
	permissions: CPermissionInitial,
	tokenPlayer: '',
}

import { ILocaleInfos } from './Types';

export enum Locales {
    English = 'EN',
    French = 'FR',
    Germain = 'DE',
}

export interface ILocaleInfosList {
    [l: string]: ILocaleInfos;
}

export const LocaleInfos: ILocaleInfosList = {
    [Locales.English]: {
        formatLongDate: 'LL',
        formatLongDateTime: 'LLLL',
        formatLongDateTimeWithMilliSeconds: 'LL LTS.SSS',
        formatLongDateTimeWithSeconds: 'LL LTS',
        formatShortDate: 'L',
        formatShortDateTime: 'L LTS',
        iconName: 'gb',
        momentLocale: 'en-us',
        name: Locales.English,
    },
    [Locales.French]: {
        formatLongDate: 'LL',
        formatLongDateTime: 'LLLL',
        formatLongDateTimeWithMilliSeconds: 'LL LTS.SSS',
        formatLongDateTimeWithSeconds: 'LL LTS',
        formatShortDate: 'L',
        formatShortDateTime: 'L LTS',
        iconName: 'fr',
        momentLocale: 'fr',
        name: Locales.French,
    },
    [Locales.Germain]: {
        formatLongDate: 'LL',
        formatLongDateTime: 'LLLL',
        formatLongDateTimeWithMilliSeconds: 'LL LTS.SSS',
        formatLongDateTimeWithSeconds: 'LL LTS',
        formatShortDate: 'L',
        formatShortDateTime: 'L LTS',
        iconName: 'de',
        momentLocale: 'de',
        name: Locales.Germain,
    },
};

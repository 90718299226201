import { StatusCodeFilter } from './StatusCodeError'
import { Config } from '../config'
import { IAuthSuccess, IContentDisposition } from './Types'
import { COUNTRY_OPTIONS, ISearchParams } from '../Store/Search/Types'
import {
  AdGroupNielsenParams,
  IBroadcastPassagesRequest,
  IBroadcastPassagesWebRequest,
  IReportSpotDetails,
} from '../Store/SpotDetails/Types'
import {
  IFetchFormulaireRequest,
  IFormulaireFilters,
  IFormulaireFranceState,
  IFormulaireGemaState,
  IOrderBy,
} from '../Store/Formulaire/Types'
import jwt from 'jwt-decode'
import { diff_minutes } from '../Utils/Time'
import { clearTypedStorage } from '../Utils/TypedStorage'
import { limitText } from '../Utils/String'
import { IUpdateUserParams } from '../Store/User/Types'

export type BackendMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
export class Backend {
  public static getInstance() {
    if (!Backend.instance) {
      Backend.instance = new Backend()
    }
    return Backend.instance
  }

  private static instance: Backend
  private token: string = ''

  public setToken(tok: string) {
    this.token = tok
  }

  isAuthSuccess(object: any): object is IAuthSuccess {
    return 'token' in object && typeof object.token === 'string'
  }

  public async getMediaWeb() {
    return this.callBackend('media/web')
      .then(this.ab2obj)
      .then((res) => {
        if (res && res.results) {
          return res
        }
        return {}
      })
  }

  public async launchSearch(params: ISearchParams) {
    return this.callBackend('search', 'POST', this.obj2ab(params))
      .then(this.ab2obj)
      .then((res) => {
        if (res && res.results) {
          return res
        }
        return {}
      })
  }

  public async getBroadcastPassages(params: any) {
    let url = 'search/broadcasts' //'timetables/planmedia';
    return this.callBackend(url, 'POST', this.obj2ab(params))
      .then(this.ab2obj)
      .then((res) => {
        if (res && res.results) {
          return res.results
        }
        return {}
      })
  }
  public async getBroadcastWebPassages(params: IBroadcastPassagesWebRequest) {
    return this.callBackend(
      'spot/broadcast_web/search',
      'POST',
      this.obj2ab(params)
    )
      .then(this.ab2obj)
      .then((res) => {
        if (res && res.results) {
          return res
        }
        return {}
      })
  }
  public async getBroadcastWebPassagesById(id: any) {
    let url = 'broadcast_web/' + id
    return this.callBackend(url)
      .then(this.ab2obj)
      .then((res) => {
        if (res && res.results) {
          return res
        }
        return {}
      })
  }
  public async getTokenPlayer(
    music: boolean,
    readjusted: boolean,
    username: string
  ) {
    return this.callBackendJSON('/auth/tokenPlayer', {
      music: music,
      readjusted: readjusted,
      username: username,
    })
      .then(this.ab2obj)
      .then((res: any) => {
        if (res) {
          return res
        }
        throw new Error('New Route: Invalid response from backend')
      })
  }
  public async getFormulaireList(
    country: COUNTRY_OPTIONS,
    page: number,
    perPage?: number,
    filters?: IFormulaireFilters,
    orderBy?: IOrderBy,
    is_export?: boolean,
    refresh_status?: boolean
  ) {
    let params: any = {
      country: country,
      page: page,
      limit: perPage,
      order_by: orderBy,
      is_export,
      refresh_status,
    }
    if (filters) {
      params = {
        ...params,
        product: filters.product,
        code_arpp: filters.code_arpp,
        email: filters.email,
        code_pub_id: filters.code_pub_id,
        user_group: filters.user_group,
        created_at_min: filters.created_at_min,
        created_at_max: filters.created_at_max,
      }
    }
    return this.callBackendJSON('/formulaire/list', params)
      .then(this.ab2obj)
      .then((res) => {
        if (res && res.results) {
          return res
        }
        return {}
      })
  }

  public async saveFormulaire(
    params: IFormulaireFranceState | IFormulaireGemaState
  ) {
    return this.callBackend('formulaire', 'POST', this.obj2ab(params))
      .then(this.ab2obj)
      .then((res) => {
        if (res && res.results) {
          return res
        }
        return {}
      })
  }

  public async fetchFormulaire(
    fetchFormulaireRequest: IFetchFormulaireRequest
  ) {
    return this.callBackend(
      'formulaire/fetch',
      'POST',
      this.obj2ab(fetchFormulaireRequest)
    )
      .then(this.ab2obj)
      .then((res) => {
        if (res && res.results) {
          return res
        }
      })
  }
  public async fetchFormulairePDF(
    fetchFormulaireRequest: IFetchFormulaireRequest
  ) {
    return this.callBackend(
      'formulaire/fetchPDF',
      'POST',
      this.obj2ab(fetchFormulaireRequest)
    )
      .then(this.ab2obj)
      .then((res) => {
        if (res.status === "No envelopeID") {
          return;
        }

        if (res.results === null) {
          return;
        }

        var file = new Blob([res], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        return { file, fileURL }
      })
  }

  public async reportSpotProblem(params: IReportSpotDetails) {
    return this.callBackend('spot/report', 'POST', this.obj2ab(params))
      .then(this.ab2obj)
      .then((res) => {
        if (res && res.results) {
          return res
        }
        return {}
      })
  }

  public async getMediaName(media_id: number) {
    return this.callBackend('media/' + media_id)
      .then(this.ab2obj)
      .then((res) => {
        if (res && res.results) {
          return res.results
        }
        return {}
      })
  }
  public async getIDenticSpotsNielsen(params: AdGroupNielsenParams) {
    return this.callBackend(
      'spot/advertisement/ad_group',
      'POST',
      this.obj2ab(params)
    )
      .then(this.ab2obj)
      .then((res) => {
        if (res && res.results) {
          return res
        }
        return {}
      })
  }
  public async getSpotDetails(bucketId: number, spotId: number) {
    return this.callBackend('spot/' + bucketId + '/' + spotId, 'GET')
      .then(this.ab2obj)
      .then((res) => {
        if (res && res.results) {
          return res
        }
        return {}
      })
  }

  public async getIdenticSpots(filmid_label: string) {
    return this.callBackend('spot/group/' + filmid_label, 'GET')
      .then(this.ab2obj)
      .then((res) => {
        if (res && res.results) {
          return res.results[0] ? res.results[0].ads : []
        }
        return {}
      })
  }

  public async getSpotFormulaires(
    fetchFormulaireRequest: IFetchFormulaireRequest
  ) {
    return this.callBackend(
      'formulaire/spot',
      'POST',
      this.obj2ab(fetchFormulaireRequest)
    )
      .then(this.ab2obj)
      .then((res) => {
        if (res && res.results) {
          return res
        }
        return {}
      })
  }

  public async getSpotRegrouping(bucketId: number, spotId: number) {
    return this.callBackend('spot/regrouping/' + bucketId + '/' + spotId)
      .then(this.ab2obj)
      .then((res) => {
        if (res) {
          return res
        }
        return {}
      })
  }

  public async downloadCSV(params: IBroadcastPassagesRequest) {
    return this.callBackend(
      'timetables/downloadcsv',
      'POST',
      this.obj2ab(params)
    ).then((res) => {
      if (res) {
        const result = res as IContentDisposition
        const contentDisposition = result.contentDisposition
        result.content.then((content) => {
          const blob = new Blob([content], { type: 'text/plain' })

          let filename = 'filename.csv'

          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          var matches = filenameRegex.exec(contentDisposition)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }

          var url = window.URL.createObjectURL(blob)
          var a = document.createElement('a')
          a.href = url
          a.download = filename
          document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click()
          a.remove()
        })
        return result
      }
      return {}
    })
  }

  public async exportsDeclarations(data: any) {
    return this.callBackend(
      'formulaire/exports_xls',
      'POST',
      this.obj2ab(data)
    ).then((res) => {
      return res
    })
  }
  private tokenExpiresSoon(token: any) {
    let token_decoded: any = jwt(token)
    if (token_decoded.hasOwnProperty('exp')) {
      let expires: any = token_decoded['exp']

      const expiration_date = new Date(expires * 1000)
      const now: any = new Date()
      const diff = diff_minutes(expiration_date, now)
      if ((diff > 0 && diff <= 15) || now > expiration_date) {
        /*if (diff > 0 && diff <= 15) {
        } else if (now > expiration_date) {
        }*/
        return true
      }
    }
    return false
  }
  private refreshToken(token: string) {
    const url = '/auth/refreshtoken'
    return this.callBackendNoStatusCodeFilter(url)
      .then((res: any) => {
        return res.json()
      })
      .then((data: any) => {
        if (data.hasOwnProperty('token')) {
          this.setToken(data['token'])
        }
      })
  }

  private async callBackend(
    route: string,
    method: BackendMethod = 'GET',
    body: ArrayBuffer | FormData | null = null,
    addHeaders: Headers | null = null
  ): Promise<ArrayBuffer | IContentDisposition> {
    const url =
      route.length === 0 || route[0] !== '/'
        ? `${Config.backendAPIURL}/${route}`
        : `${Config.backendAPIURL}${route}`
    if (this.token !== '' && this.tokenExpiresSoon(this.token)) {
      this.refreshToken(this.token)
    }

    const headers =
      addHeaders === null
        ? this.token
          ? new Headers({
            Authorization: 'Bearer ' + this.token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          })
          : new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
          })
        : addHeaders

    return fetch(url, {
      body,
      cache: 'no-cache',
      headers,
      method,
      mode: 'cors',
      redirect: 'error',
      credentials: 'include',
    }).then(StatusCodeFilter)
  }

  private async callBackendNoStatusCodeFilter(
    route: string,
    method: BackendMethod = 'GET',
    body: ArrayBuffer | FormData | null = null,
    addHeaders: Headers | null = null
  ): Promise<Response> {
    const url =
      route.length === 0 || route[0] !== '/'
        ? `${Config.backendAPIURL}/${route}`
        : `${Config.backendAPIURL}${route}`

    const headers =
      addHeaders === null
        ? this.token
          ? new Headers({
            Authorization: 'Bearer ' + this.token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          })
          : new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
          })
        : addHeaders

    return fetch(url, {
      body,
      cache: 'no-cache',
      headers,
      method,
      mode: 'cors',
      redirect: 'error',
      credentials: 'include',
    }).then((res: any) => {
      if (res.status === 401 || res.status_code === 401) {
        clearTypedStorage()
        //window.document.location.href = '/logout';
      }
      return res
    })
  }

  private async callBackendJSON(
    route: string,
    obj: any,
    addHeaders: Headers | null = null
  ): Promise<ArrayBuffer | IContentDisposition> {
    const data = this.obj2ab(obj)
    const headers = addHeaders === null ? new Headers() : addHeaders

    headers.append('content-type', 'application/json')
    return this.callBackend(route, 'POST', data, headers)
  }

  public async login(username: string, password: string) {
    const headers = new Headers()
    headers.append('content-type', 'application/json')

    return this.callBackendNoStatusCodeFilter(
      'auth/',
      'POST',
      this.obj2ab({ username, password }),
      headers
    )
      .then((res: any) => {
        if (res.status === 401 || res.status_code === 401) {
          throw new Error('Authentication failed')
        }

        let contentDisposition = res.headers.get('content-disposition')
        if (contentDisposition) {
          return {
            content: res.blob(),
            contentDisposition: contentDisposition,
          }
        }
        return res.arrayBuffer()
      })
      .then(this.ab2obj)
      .then((res: any) => {
        // res = res.arrayBuffer();
        if (this.isAuthSuccess(res)) {
          this.setToken(res.token)
          return [res.token, res.expires * 1000, res.results]
        }

        throw new Error('Invalid response from backend')
      })
  }
  public async verifyToken(token: string) {
    return this.callBackendJSON('/auth/verifytoken', { token })
      .then(this.ab2obj)
      .then((res: any) => {
        if (res['token']) {
          this.setToken(token)
        }
        return res
      })
  }
  public async updateUser(params: IUpdateUserParams) {
    return this.callBackendJSON('/formulaire/update_user', {
      token: params.token,
      society_id: params.society_id,
      society_email: params.society_email,
      society_name: params.society_name,
      society_surname: params.society_surname,
      group_email: params.group_email,
      group_id: params.group_id,
      group_name: params.group_name,
      group_localisation: params.group_localisation,
    })
      .then(this.ab2obj)
      .then((res: any) => {
        return res
      })
  }
  public async logout() {
    return this.callBackendJSON('/auth/logout', {})
      .then(this.ab2obj)
      .then((res: any) => {
        return res
      })
  }

  // // converts an object to an array buffer
  private obj2ab(obj: any): ArrayBuffer {
    return new TextEncoder().encode(JSON.stringify(obj))
  }

  // converts an array buffer to object
  private ab2obj(from: ArrayBuffer | IContentDisposition): any {
    const json = new TextDecoder().decode(new Uint8Array(from as ArrayBuffer))

    try {
      return JSON.parse(json)
    } catch {
      return new Uint8Array(from as ArrayBuffer)
    }
    return new Uint8Array(from as ArrayBuffer)
  }
}

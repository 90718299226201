import React from 'react';

interface ISVGProps {
    bookfill?: string;
    bookside?: string;
    bookfront?: string;
    color?: string;
    height?: string;
    width?: string;
}

type AllProps = ISVGProps;

export class YacastLogoIcon extends React.Component<AllProps> {
   
    render() {
        let {color} = this.props;
        if (!color) {
            color="#FFFFFF";
        }
        const color1 = "#c54cfb";
        const color2 = "#765ce2";
        //const color3 = "#42475e";
        return (
            <svg  width="120" height="40" viewBox="0 0 1600 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill={color} d="M155.93,40.05l82.24,136a2,2,0,0,0,3.47.05l26.92-41.91a2.05,2.05,0,0,0,0-2.16L211.83,37.59a2,2,0,0,0-1.76-1l-52.41.34A2,2,0,0,0,155.93,40.05Z"/>
                <path fill={color} d="M241.5,39.77l40.65,67.61a2.05,2.05,0,0,0,3.48.05L329,39.72a2,2,0,0,0-1.72-3.16l-84.06.11A2,2,0,0,0,241.5,39.77Z"/>
                <path fill={color} d="M189.78,233c24.32-11.84,31.87-25.52,33.82-30.16a3.32,3.32,0,0,0-.21-3L127,38.12a2.05,2.05,0,0,0-1.76-1H72.86a2,2,0,0,0-1.75,3.1L186.17,231.89A2.78,2.78,0,0,0,189.78,233Z"/>
                <path fill={color} d="M159.34,236.28a2,2,0,0,0,1.65-3.11l-39-62a2,2,0,0,0-3.48,0L80.28,233.24a2,2,0,0,0,1.7,3.09C95.18,236.48,141.06,236.79,159.34,236.28Z"/>
                <path fill={color} stroke={color} strokeMiterlimit={10} strokeWidth={'2px'} d="M554.07,341.55h-33l25.63-73.63-59.63-159.8h35.43L562,224.65h.64l39.27-116.53h35.58Z"/>
                <path fill={color} stroke={color} strokeMiterlimit={10} strokeWidth={'2px'} d="M742.93,259.75h-.65a27.81,27.81,0,0,1-14.42,12.5q-9.61,4.15-26.61,4.16-27.88-.32-43-14.74-15.87-14.1-15.87-36.06,0-21,13.79-34.78,13.77-14.1,40.39-14.43h46.32V158.93q.32-22-34.14-21.31-12.51,0-19.72,2.56a24.1,24.1,0,0,0-11.86,10.26l-26.28-20Q670,105.4,707.18,106q31.41-.32,50.17,11.86,18.75,12.51,19.07,43V274.49H742.93Zm0-55.78H702.86q-29.18.65-28.85,20,0,8.5,7.53,14.43,7.22,6.57,23.88,6.57,21,.33,29.65-5,7.86-5.28,7.86-24Z"/>
                <path fill={color} stroke={color} strokeMiterlimit={10} strokeWidth={'2px'} d="M929.65,247.57A89.65,89.65,0,0,1,904,267.92q-14.75,8.18-34.14,8.49-29.49,0-51.77-20-23.07-20-23.72-64.91.64-45.36,23.72-65.71,22.29-20,51.77-19.72,19.39.33,34.14,8a84.93,84.93,0,0,1,25.65,21L905,157q-12.66-16.83-33.81-17.47-42.64-.64-43.28,51.93.64,52.25,43.28,51.45,21.15-.33,33.81-17.31Z"/>
                <path fill={color} stroke={color} strokeMiterlimit={10} strokeWidth={'2px'} d="M1044.8,259.75h-.64a27.83,27.83,0,0,1-14.43,12.5q-9.62,4.15-26.6,4.16-27.9-.32-43-14.74-15.87-14.1-15.86-36.06,0-21,13.78-34.78,13.78-14.1,40.39-14.43h46.32V158.93q.32-22-34.14-21.31-12.49,0-19.71,2.56a24,24,0,0,0-11.86,10.26l-26.29-20q19.07-25,56.26-24.36,31.41-.32,50.16,11.86,18.77,12.51,19.08,43V274.49h-33.5Zm0-55.78h-40.07q-29.18.65-28.85,20,0,8.5,7.53,14.43,7.22,6.57,23.88,6.57,21,.33,29.66-5,7.85-5.28,7.85-24Z"/>
                <path fill={color} stroke={color} strokeMiterlimit={10} strokeWidth={'2px'} d="M1182.1,245q14.43,0,23.56-5.61t9.14-15.71q0-9-5.45-12.5-5.61-3.68-14.91-4L1165,204.61q-21-1.92-34.14-14.1-13.45-11.87-13.78-33.18.32-25.65,18.11-38.47Q1152.45,106,1179.05,106a133.72,133.72,0,0,1,20.68,1.44,103.64,103.64,0,0,1,17.15,4.49,98,98,0,0,1,27.57,16.83L1223.77,153a103.94,103.94,0,0,0-20.35-11.38,68.61,68.61,0,0,0-24.37-4q-15.71,0-22,5.6a18.09,18.09,0,0,0-6.57,14.43q-.33,5.61,5,9.78,4.82,4.65,17,5.93l27.57,2.08q26,1.92,37.51,15.71,10.73,13.46,10.73,34.14-.32,24.19-19.71,37.34-19.23,13.47-49.21,13.78-40.71,0-73.24-29.81l23.4-22.92Q1149.88,245,1182.1,245Z"/>
                <path fill={color} stroke={color} strokeMiterlimit={10} strokeWidth={'2px'} d="M1288.28,57.48h33.5v50.64h36.16v25.49h-36.16v97q-.32,12.66,11.86,12.34h12.5v31.57h-17.47q-17.62,0-28.85-10.9-11.53-10.41-11.54-30.13V133.61h-16.67V108.12h16.67Z"/>
            </svg>
        )
    }
};
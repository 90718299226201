

import React from 'react';

interface ISVGProps {
    bookfill?: string;
    bookside?: string;
    bookfront?: string;
    color?: string;
    width?: string;
    height?: string;
}

type AllProps = ISVGProps;

export class TvIcon extends React.Component<AllProps> {

 render() {
    let { color } = this.props;
    if (!color) {
        color="#EEEEEE";
    }
    return (
        <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="25" height="14" stroke={color}/>
            <line y1="17.5" x1="6" x2="19" y2="17.5" stroke={color}/>
        </svg>
    )
    }
};
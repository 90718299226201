import { COUNTRY_OPTIONS } from '../Search/Types'
import { IFingerprintBucketPair } from '../SpotDetails/Types'
import { initialParentInfo, IParentInfo } from '../User/Types'

export enum FormulaireActionTypes {
  GET_FORMULAIRE_LIST = '@@formulaire/GET_FORMULAIRE_LIST', // endpoint /formulaire/list
  GET_FORMULAIRE_LIST_ERROR = '@@formulaire/GET_FORMULAIRE_LIST_ERROR',
  GET_FORMULAIRE_LIST_SUCCESS = '@@formulaire/GET_FORMULAIRE_LIST_SUCCESS',

  GET_FORMULAIRE_PREVIEW = '@@formulaire/GET_FORMULAIRE_PREVIEW', // endpoint spot/' + bucketId + '/' + spotId
  GET_FORMULAIRE_PREVIEW_ERROR = '@@formulaire/GET_FORMULAIRE_PREVIEW_ERROR',
  GET_FORMULAIRE_PREVIEW_SUCCESS = '@@formulaire/GET_FORMULAIRE_PREVIEW_SUCCESS',

  FETCH_FORMULAIRE = '@@formulaire/FETCH_FORMULAIRE',
  FETCH_FORMULAIRE_ERROR = '@@formulaire/FETCH_FORMULAIRE_ERROR',
  FETCH_FORMULAIRE_SUCCESS = '@@formulaire/FETCH_FORMULAIRE_SUCCESS',
  FETCH_FORMULAIRE_SUCCESS_PDF = '@@formulaire/FETCH_FORMULAIRE_SUCCESS_PDF',
  FETCH_FORMULAIRE_FAILURE_PDF = '@@formulaire/FETCH_FORMULAIRE_FAILURE_PDF',

  SAVE_FORMULAIRE = '@@formulaire/SAVE_FORMULAIRE',
  SAVE_FORMULAIRE_ERROR = '@@formulaire/SAVE_FORMULAIRE_ERROR',
  SAVE_FORMULAIRE_SUCCESS = '@@formulaire/SAVE_FORMULAIRE_SUCCESS',

  DOWNLOAD_PDF = '@@formulaire/DOWNLOAD_PDF',

  SET_MEDIA_NAME = '@@formulaire/SET_MEDIA_NAME',
  FETCH_EXPORTS_DECLARATIONS = '@@formulaire/FETCH_EXPORTS_DECLARATIONS',
  FETCH_EXPORTS_DECLARATIONS_SUCCESS = '@@formulaire/FETCH_EXPORTS_DECLARATIONS_SUCCESS',
  EXPORTS_DECLARATIONS = '@@formulaire/EXPORTS_DECLARATIONS',
  EXPORTS_DECLARATIONS_SUCCESS = '@@formulaire/EXPORTS_DECLARATIONS_SUCCESS',
  EXPORTS_DECLARATIONS_ERROR = '@@formulaire/EXPORTS_DECLARATIONS_ERROR',
  CLEAN_FILE_DOWNLOAD = '@@formulaire/CLEAN_FILE_DOWNLOAD',
}

export interface IFormulaireFilters {
  product: any
  code_arpp: any
  email: any
  code_pub_id: any
  user_group: any
  created_at_min: any
  created_at_max: any
}

export enum IMusicType {
  NEW = 'new',
  EXISTING = 'existing',
  SIGNATURE = 'signature',
}

export enum IRole {
  ARTIST = 'artist',
  AUTHOR = 'author',
  COMPOSER = 'composer',
  ARRANGER = 'arranger',
  EDITOR = 'editor',
}

export enum CMusicGemaType {
  WERK = 'werk',
  JINGLE = 'jingle',
}

export interface IRightHolders {
  name: string
  roles: IRole[]
}

export const CRightHolders = {
  name: '',
  roles: [],
}

export interface IMusicInfo {
  title: string
  usage_duration: number
  type: IMusicType | null
  code_isrc: string
  code_iswc: string
  code: string
  right_holders: IRightHolders[]
}

export interface IMusicState {
  label: string
  id: number
}

export interface IMusicInfoGema {
  artist: string
  authors: string
  bucket_name: string
  composers: string
  arrangers: string
  editor: string
  end_time: number
  fgp_id: number
  film_id: number
  id: number
  isrc: string
  iswc: string
  master_filepath: string
  music_gema_type: CMusicGemaType
  music_state: IMusicState | null
  start_time: number
  title: string
  sek: any
  ak: any
  product_year: any
  product_country: any
}

export interface ISocietyInfo {
  id: number
  date: string
  email: string
  name: string
  surname: string
  signature: string
  user_group_info: IParentInfo
}

export interface IFormulaireState {
  [COUNTRY_OPTIONS.FRANCE]: IFormulaireFranceState
  [COUNTRY_OPTIONS.GERMANY]: IFormulaireGemaState
  isLoading: boolean
  isReceived: boolean
  formulaires: FormulairesResponse
  formulairesPDF?: { file: Blob, fileURL: string }
}

export interface IFormulaireParent {
  _id?: any
  bucket_id: number
  created_at?: IDate
  is_validated: boolean
  is_signed: boolean
  last_modified?: IDate
  spot_id: number
  version: number
  validation_state?: IValidationState
  user_email?: string
  user_group?: string
  download?: boolean
}

export interface IFormulaireFranceState extends IFormulaireParent {
  agency: string
  announcer?: string
  codes_arpp?: string
  codes_pub_id?: string
  country: COUNTRY_OPTIONS | null
  diffuser: string
  diffusion_date: string
  duration: number
  envelope: string
  filepath: string
  fingerprint_list: IFingerprintBucketPair[]
  identifier: number
  marque: string
  master_filepath: string
  master_video_filepath: string
  media: IMedia
  music: IMusicInfo[] | IMusicInfoGema[]
  product_name: string
  product_nature: string
  regrouping_id: string
  society: ISocietyInfo
  spot_title: string
  user_id: number
}

export interface IFormulaireGemaState extends IFormulaireParent {
  address_facture: string
  agency: string
  duration: any
  announcer: string
  broadcast_date: string
  diffusion_online: boolean
  editor_localization_date: string
  editor_member_number: string
  editor_signature: string
  facture_online: boolean
  facture_production: boolean
  facture_third_party: boolean
  gema_av_number: string
  is_property: boolean
  mandat_localization_date: string
  mandat_signature: string
  master_filepath: string
  master_video_filepath: string
  filepath: string
  media_type_cinema: boolean
  media_type_tv: boolean
  media_type_radio: boolean
  music: IMusicInfoGema[]
  music_duration: number
  product_country: string
  product_duration: number
  product_name: string
  product_year: number
  region_europe: boolean
  region_germain_speaking: boolean
  region_germany: boolean
  region_other: boolean
  region_other_comment: string
  spot_alt_title: string
  spot_title: string
  unik_id: any
  type_1: boolean
  type_2: boolean
  type_3: boolean
  type_4: boolean
  website_url: string
  user_id?: number
}
export type IOrder = 'asc' | 'desc'

export interface IOrderBy {
  field: string
  sorting: IOrder
}
export interface IFetchFormulaireRequest {
  fingerprint_list: IFingerprintBucketPair[]
}

export interface IValidationState {
  code: number
  message: string
}

export interface IFormulaireListState {
  _id?: any
  agency: string
  bucket_id: number
  codes_arpp: string
  codes_pub_id: string
  created_at?: IDate
  fingerprint_list: IFingerprintBucketPair[]
  is_validated: boolean
  is_signed: boolean
  last_modified?: IDate
  filepath: string
  master_filepath: string
  master_video_filepath: string
  media: IMedia
  product_name: string
  pdf_filepath: string
  user_email: string
  user_group: string
  spot_id: number
  regrouping_id: string
  validation_state?: IValidationState
  version: number
  announcer: string
  country: string
  diffuser: string
  diffusion_date: string
  download: boolean
  duration: number
  identifier: number
  marque: string
  music: any
  product_nature: string
  society: {
    date: string
    email: string
    id: number
    name: string
    signature: string
    surname: string
    user_group_info: {
      email: string | null
      id: number
      localisation: string[]
      name: string
      phone: any
    }
  }
  spot_title: string
  user_id: number
}

export interface IFormulaireList {
  limit: number
  page: number
  results: IFormulaireListState[]
  total_count: number
  total_pages: number
  loadingFormulaireList: boolean
  file_downloaded: any
  formulaires: FormulairesResponse
}

export const CMusicInfoGemaInitial: IMusicInfoGema = {
  artist: '',
  editor: '',
  authors: '',
  arrangers: '',
  bucket_name: '',
  composers: '',
  end_time: 0,
  fgp_id: 0,
  film_id: 0,
  id: 0,
  isrc: '',
  iswc: '',
  master_filepath: '',
  music_gema_type: CMusicGemaType.WERK,
  music_state: null,
  start_time: 0,
  title: '',
  ak: '',
  sek: '',
  product_year: '',
  product_country: '',
}
export const initialMusicInfoGema: IMusicInfoGema[] = [
  { ...CMusicInfoGemaInitial },
]
export const CFormulaireGemaInitialState: IFormulaireGemaState = {
  address_facture: '',
  agency: '',
  duration: 0,
  announcer: '',
  broadcast_date: '',
  bucket_id: 0,
  diffusion_online: false,
  editor_localization_date: '',
  editor_member_number: '',
  editor_signature: '',
  facture_online: false,
  facture_production: false,
  facture_third_party: false,
  gema_av_number: '',
  is_property: false,
  is_validated: false,
  is_signed: false,
  mandat_localization_date: '',
  mandat_signature: '',
  master_filepath: '',
  master_video_filepath: '',
  filepath: '',
  media_type_cinema: false,
  media_type_tv: true,
  media_type_radio: false,
  music: initialMusicInfoGema,
  music_duration: 0,
  product_country: '',
  product_duration: 0,
  product_name: '',
  product_year: 0,
  region_europe: false,
  region_germain_speaking: false,
  region_germany: false,
  region_other: false,
  region_other_comment: '',
  spot_id: 0,
  spot_alt_title: '',
  spot_title: '',
  type_1: false,
  type_2: false,
  type_3: false,
  type_4: false,
  version: 1,
  website_url: '',
  unik_id: '',
}

export const CMusicInfoInitial: IMusicInfo = {
  title: '',
  usage_duration: 0,
  type: null,
  code_isrc: '',
  code_iswc: '',
  code: '',
  right_holders: [CRightHolders],
}

export const CSocietyInfoInitial: ISocietyInfo = {
  id: 0,
  date: '',
  name: '',
  surname: '',
  signature: '',
  email: '',
  user_group_info: initialParentInfo,
}
export interface IMedia {
  codeiso: string
  country: string
  id: number
  name: string
  type: string
}

export const CMediaInitial: IMedia = {
  codeiso: '',
  country: '',
  id: -1,
  name: '',
  type: '',
}

export const CFormulaireFranceInitial: IFormulaireFranceState = {
  agency: '',
  announcer: '',
  bucket_id: -1,
  codes_arpp: '',
  codes_pub_id: '',
  country: null,
  diffuser: '',
  diffusion_date: '',
  duration: 0,
  envelope: '',
  filepath: '',
  fingerprint_list: [],
  identifier: -1,
  is_validated: false,
  is_signed: false,
  marque: '',
  master_filepath: '',
  master_video_filepath: '',
  media: CMediaInitial,
  music: [],
  product_name: '',
  product_nature: '',
  regrouping_id: '',
  society: CSocietyInfoInitial,
  spot_id: -1,
  spot_title: '',
  user_id: -1,
  version: 1,
}

export const formulairesEmpty: FormulairesResponse = {
  limit: 0,
  page: 0,
  results: [],
  status: '',
  status_code: '',
  total_count: 0,
  total_pages: 0,
}

export const CFormulaireListInitialState: IFormulaireList = {
  limit: 25,
  page: 1,
  results: [],
  total_count: 0,
  total_pages: 0,
  loadingFormulaireList: false,
  file_downloaded: null,
  formulaires: formulairesEmpty,
}

export const CFormulaireInitial: IFormulaireState = {
  [COUNTRY_OPTIONS.FRANCE]: CFormulaireFranceInitial,
  [COUNTRY_OPTIONS.GERMANY]: CFormulaireGemaInitialState,
  isLoading: true,
  isReceived: false,
  formulaires: formulairesEmpty,
}

export interface FormulairesResponse {
  limit: number
  page: number
  results: Formulaire[]
  status: string
  status_code: string
  total_count: number
  total_pages: number
}

export interface Formulaire {
  _id: Id
  agency: string
  announcer: string
  bucket_id: number
  codes_arpp: string
  codes_pub_id: string
  country: string
  created_at: IDate
  diffuser: string
  diffusion_date: string
  download: boolean
  duration: number
  envelopeID?: string
  envelope?: string
  filepath: string
  fingerprint_list: FingerprintList[]
  identifier: number
  is_signed?: boolean
  is_validated: boolean
  last_modified: IDate
  marque: string
  master_filepath: string
  master_video_filepath: string
  media: Media
  music: Music[]
  product_name: string
  product_nature: string
  regrouping_id: string
  society: Society
  spot_id: number
  spot_title: string
  user_email: string
  user_group: string
  user_id: number
  version: number
}

export interface Id {
  $oid: string
}

export interface IDate {
  $date: number
}

export interface FingerprintList {
  bucket_id: number
  fgp_id: number
}

export interface Media {
  codeiso: string
  country: string
  id: number
  name: string
  type: string
}

export interface Music {
  code: string
  code_isrc?: string
  code_iswc?: string
  right_holders: RightHolder[]
  title: string
  type: string
  usage_duration: any
}

export interface RightHolder {
  name: string
  roles: string[]
}

export interface Society {
  date: string
  email: string
  id: number
  name: string
  signature: string
  surname: string
  user_group_info: UserGroupInfo
}

export interface UserGroupInfo {
  email?: string
  id: number
  localisation: string[]
  name: string
  phone: string
}

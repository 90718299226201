import Moment from "moment";

export enum ISearchActionTypes {
    LAUNCH_SEARCH = '@@search/LAUNCH_SEARCH',
    LAUNCH_SEARCH_SUCCESS = '@@search/LAUNCH_SEARCH_SUCCESS',
    LAUNCH_SEARCH_ERROR = '@@search/LAUNCH_SEARCH_ERROR',
    PAGINATE_SEARCH = '@@search/PAGINATE_SEARCH',
    PAGINATE_SEARCH_SUCCESS = '@@search/PAGINATE_SEARCH_SUCCESS',
    PAGINATE_SEARCH_ERROR = '@@search/PAGINATE_SEARCH_ERROR',
    SET_COUNTRY  = '@@search/SET_COUNTRY',
    SEARCH_REINIT_PARAMS = '@@search/SEARCH_REINIT_PARAMS',
    GET_MEDIA_WEB = '@@search/GET_MEDIA_WEB',
    GET_MEDIA_WEB_SUCCESS = '@@search/GET_MEDIA_WEB_SUCCESS',
    GET_MEDIA_WEB_ERROR = '@@search/GET_MEDIA_WEB_ERROR',
    
    SAVE_TIME_SELECTION = '@@search/SAVE_TIME_SELECTION',
    UPDATE_COUNTRY_PARAMS = '@@search/UPDATE_COUNTRY_PARAMS',
}

export enum IMusicStateTypes {
    IDENTIFIED = 1,
    NON_IDENTIFIED,
    NO_MUSIC
}
export const IAdtypes = [
    {
        name: "TOUS",
        value: "TOUS"
    }, 
    {
        name: "SPONSORING",
        value: "SP"
    }, 
    {
        name: "TRAILER",
        value: "TR"
    },
    {
        name: "EP",
        value: "EP"
    }, 
];
export enum IAdType {
    NORMAL = "N",
    SPONSORING = "SP",
    TRAILER = "TR",
    ECRAN_PUBLICATAIRE = "EP",
    SC = "SC"
}
export enum COUNTRY_OPTIONS {
    FRANCE='fr',
    GERMANY='de',
}
export interface IBucketMedia {
    FR_TV: number[],
    FR_RADIO: number[],
    DE_TV: number[],
    DE_RADIO: number[],
    DE_WEB: number[]
}
export const BucketMedia:IBucketMedia = {
    FR_TV: [1, 18],
    FR_RADIO: [87],
    DE_TV: [57],
    DE_RADIO: [61],
    DE_WEB: [62]
}
export enum IOrderValue {
    DESC = "desc",
    ASC = "asc"
}

export interface IMediaSupportSimple {
    id: number;
    name: string;
}

export interface IMediaSupport {
    id: number;
    name: string;
    subitems: IMediaSupportSimple[];
}

export interface IOrderByParams {
    field: string;
    value: IOrderValue;
}

export interface ISearchPageParams {
    page_size: number;
    page: number;
    order_by: IOrderByParams[];
}

export interface IMedia {
    id: number;
    codeiso: string;
    country: string;
    name: string;
    type: string;
}

export interface IMusicRight {
    id: number;
    music_annex_metadata_id: number;
    music_author: string;
    music_composer: string;
    music_duration: number;
    music_origin_country: string;
    music_publisher: string;
    music_title: string;
}

export interface IMusicBroadcasts {
    ad_filepath: string;
    artist: string;
    authors: string;
    bed: string;
    bucket_name: string;
    music_state_id: number;
    composers: string;
    end_time: any;
    fgp_id: string;
    film_id: string;
    gain: string;
    id: string;
    isrc: string;
    iswc: string;
    master_filepath: string;
    offset_end: string;
    offset_start: string;
    start_time: any;
    title: string;
}

export interface IAdvertiser {
    address: string;
    city: string;
    code: string;
    country_code: string;
    name: string;
    tva_number: string | null;
}

export interface ISearchParams {
    start_date: Moment.Moment | string;
    end_date: Moment.Moment | string;
    params: ISearchPageParams;
    query: string;
    search_ad_data: string;
    search_code: string;
    search_music_data: string;
    first_broadcast: boolean;
    media: EMediaTypes[];
    country: COUNTRY_OPTIONS | null;
    bucket_id: number[];
    music_states?: IMusicStateTypes[];
    non_validated_spots?: boolean;
    child_media_id?: number[];
    ad_type: IAdType[];
}

export interface IMetadatas {
    agency_name: string | null;
    arpp_code: string | null;
    bucket_id: number;
    bucket_name: string;
    duration: number;
    filmid_label: string | null;
    fingerprint_id: number;
    nielsen_id: number;
    master_filepath: string | null;
    master_video_filepath: string | null;
    master_video_thumbnail: string | null;
    music_arpp_no_music: boolean | null;
    reference_film_id: string | null;
    product_label: string | null;
    product_code: string | null;
    title: string | null;
    media: IMedia | null;
    advertiser: IAdvertiser | null;
}

export interface IAds {
    music_broadcast: IMusicBroadcasts[];
    metadatas: IMetadatas[];
    music_state?: IMusicStateTypes;
}

export interface ISpotRegroupement {
    ads: IAds;
    id: string;
    end_date: Moment.Moment | string | null;
    start_date: Moment.Moment | string | null;
    has_formulaire: boolean;
    master_filepath?: string;
    state?: any
}

export interface ISearchState {
    params: ISearchParams;
    results: ISpotRegroupement[];
    total_count: number;
    total_pages: number;
    total_spots: number;
    isLoading: boolean;
    supportType: IMediaSupport[];
    initialMediaWebId: number[];
    timeFilters: ITimeFilters | null;
}

export const CAdvertiserInitialState = {
    address: "",
    city: "",
    code: "",
    country_code: "",
    name: "",
    tva_number: null,
}

export type IDateType = 'annual' | 'semester' | 'quarter' | 'month' | 'calendar'

export interface ITimeFilters {
    dateType: IDateType,
    endDate: Date,
    endDateToSend: Date,
    isFullYearSelected: boolean,
    isSem1Activated: boolean,
    isSem2Activated: boolean,
    isTrim1Selected: boolean,
    isTrim2Selected: boolean,
    isTrim3Selected: boolean,
    isTrim4Selected: boolean,
    selectedMonthIndex: number,
    selectedYear: number,
    startDate: Date
}
export enum EMediaTypes {
    TV = "TV",
    RADIO = "Radio",
    Online = 'Online'
}

export const CMediaInitialState: IMedia = {
    id: -1,
    codeiso: '',
    country: '',
    name: '',
    type: '',
}

export const CInitialOrderBy: IOrderByParams = {
    field: 'ads.metadatas.advertiser.name.raw',
    value: IOrderValue.ASC
}


export const CMetadatasInitialState: IMetadatas = {
    agency_name: null,
    arpp_code: null,
    bucket_id: -1,
    bucket_name: '',
    duration: -1,
    filmid_label: null,
    fingerprint_id: -1,
    nielsen_id: -1,
    master_filepath: null,
    master_video_filepath: null,
    master_video_thumbnail: null,
    music_arpp_no_music: null,
    reference_film_id: null,
    product_label: null,
    product_code: null,
    title: null,
    media: null,
    advertiser: null,
}

export const CAdsInitalState: IAds =  {
    music_broadcast: [],
    metadatas: [],
}

export const CSearchDetailsInitialState: ISpotRegroupement = {
    ads: CAdsInitalState,
    id: '',
    end_date: null,
    start_date: null,
    has_formulaire: false
}


const now = new Date();
let month : number | string = now.getMonth() + 1;
let day : number | string  = now.getDate();

if (month < 10) {
    month = '0'+ month;
} else {
    month = '' + month;
}

if (day < 10) {
    day = '0'+ day;
} else {
    day = '' + day;
}

export const CInitialSearchParams: ISearchParams = {
    start_date: Moment().startOf('year'),
    end_date: Moment(),
    params: {
        page_size: 100, 
        page: 1,
        order_by: [CInitialOrderBy]
    },
    query: '',
    search_ad_data: '',
    search_code: '',
    search_music_data: '',
    first_broadcast: false,
    media: [EMediaTypes.TV],
    music_states: [IMusicStateTypes.IDENTIFIED, IMusicStateTypes.NON_IDENTIFIED, IMusicStateTypes.NO_MUSIC],
    non_validated_spots: true,
    country: null,
    bucket_id: BucketMedia.FR_TV,
    ad_type: []
}

export const CSearchInitialState: ISearchState  = {
    params: CInitialSearchParams,
    results: [],
    isLoading: false,
    total_count: 0,
    total_pages: 0,
    total_spots: 0,
    supportType: [],
    initialMediaWebId: [],
    timeFilters: null
}


import * as effects from 'redux-saga/effects'

import { Backend } from '../../Backend/Backend'
import { CMediaInitial, FormulaireActionTypes, IFormulaireState } from './Types'
import {
  getFormulairePreview,
  getFormulairePreviewSuccess,
  getFormulairePreviewError,
  getFormulaireList,
  getFormulaireListSuccess,
  getFormulaireListError,
  fetchFormulaire,
  fetchFormulaireError,
  fetchFormulaireSuccess,
  saveFormulaire,
  saveFormulaireSuccess,
  saveFormulaireError,
  downloadPdf,
  exportsDeclarationsSuccess,
  exportsDeclarationsError,
  fetchExportsDeclarations,
  fetchFormulaireSuccessPDF,
  fetchFormulaireFailurePDF,
} from './Actions'
import {
  AdGroupNielsenParams,
  ESpotDetailsSource,
  IFingerprintBucketPair,
} from '../SpotDetails/Types'

import { setError, setSuccessMessage } from '../NotificationBar/Actions'
import { setMediaName } from '../SpotDetails/Actions'
import { IApplicationState } from '..'
import { Config } from '../../config'

function* handleGetFormulairePreview(
  action: ReturnType<typeof getFormulairePreview>
) {
  try {
    const regroupingId = action.payload[0] as string
    const bucketId = action.payload[1] as number
    const spotId = action.payload[2] as number
    const fingerprint_list = action.payload[3] as IFingerprintBucketPair[]
    let identic_spots_results = []
    let user = yield effects.select((state) => state.user)
    const backend = Backend.getInstance()

    const results = yield effects.apply(backend, backend.getSpotDetails, [
      bucketId,
      spotId,
    ])
    if (results.results && results.results.length > 0) {
      const resultsRegrouping = yield effects.apply(
        backend,
        backend.getSpotRegrouping,
        [bucketId, spotId]
      )
      let media = CMediaInitial
      if (
        resultsRegrouping.ads &&
        resultsRegrouping.ads.metadatas &&
        resultsRegrouping.ads.metadatas.length > 0
      ) {
        if (
          resultsRegrouping.ads.metadatas[0].media &&
          resultsRegrouping.ads.metadatas[0].media.name &&
          resultsRegrouping.ads.metadatas[0].media.name !== ''
        ) {
          media = resultsRegrouping.ads.metadatas[0].media
          yield effects.put(setMediaName(media))
        }
      }

      let country = yield effects.select(
        (state) => state.searchResults.params.country
      )

      if (results.results[0].filmid_label !== undefined) {
        identic_spots_results = yield effects.apply(
          backend,
          backend.getIdenticSpots,
          [results.results[0].filmid_label]
        )
      }
      if (
        results.results[0].broadcast &&
        results.results[0].broadcast.filmid_label !== undefined
      ) {
        identic_spots_results = yield effects.apply(
          backend,
          backend.getIdenticSpots,
          [results.results[0].filmid_label]
        )
      }
      if (country.toUpperCase() === 'DE') {
        let params: AdGroupNielsenParams = {
          bucket_id: bucketId,
          ad_id: spotId,
          external_id: results.results[0].external_id,
        }
        identic_spots_results = yield effects.apply(
          backend,
          backend.getIDenticSpotsNielsen,
          [params]
        )
      }
      yield effects.putResolve(
        getFormulairePreviewSuccess(
          regroupingId,
          bucketId,
          results.results,
          user.userInfo,
          fingerprint_list,
          media,
          identic_spots_results,
          country
        )
      )
    }
  } catch (err) {
    yield effects.put(getFormulairePreviewError())
  }
}

function* handleGetFormulaireList(
  action: ReturnType<typeof getFormulaireList>
) {
  try {
    const backend = Backend.getInstance()
    const page = action.payload[0]
    const perPage = action.payload[1]
    let country = yield effects.select(
      (state) => state.searchResults.params.country
    )
    const results = yield effects.apply(backend, backend.getFormulaireList, [
      country,
      page,
      perPage,
      action.payload[2],
      action.payload[3],
      false,
      action.payload[4],
    ] as any)
    if (results && results.results) {
      // TODO: check if result is FormulairesResponse type
      yield effects.put(
        setSuccessMessage('successfully getting formulaire list')
      )
      return yield effects.putResolve(getFormulaireListSuccess(results))
    }
  } catch (err) {
    yield effects.put(getFormulaireListError())
  }
}

function* handleFetchFormulaire(action: ReturnType<typeof fetchFormulaire>) {
  try {
    const fingerprintList = action.payload
    const backend = Backend.getInstance()
    const results = yield effects.apply(backend, backend.fetchFormulaire, [
      {
        fingerprint_list: fingerprintList,
      },
    ])
    const resultsPDF = yield effects.apply(backend, backend.fetchFormulairePDF, [
      {
        fingerprint_list: fingerprintList,
      },
    ])

    if (resultsPDF) {
      yield effects.put(fetchFormulaireSuccessPDF(resultsPDF))
    } else {
      yield effects.put(fetchFormulaireFailurePDF())
    }


    if (results.results) {
      let country = yield effects.select(
        (state) => state.searchResults.params.country
      )
      return yield effects.putResolve(
        fetchFormulaireSuccess(results.results, country)
      )
    } else {
      return yield effects.put(
        getFormulairePreview(
          '',
          fingerprintList[0].bucket_id,
          fingerprintList[0].fgp_id,
          fingerprintList
        )
      )
    }
  } catch (err) {

    yield effects.put(fetchFormulaireError())
  }
}

function* handleSaveFormulaire(action: ReturnType<typeof saveFormulaire>) {
  try {
    const params = action.payload
    delete params._id
    delete params.created_at
    delete params.last_modified
    const backend = Backend.getInstance()
    if (!params['filepath']) {
      const spot_id = params.spot_id
      const bucket_id = params.bucket_id
      const detailsResults = yield effects.apply(
        backend,
        backend.getSpotDetails,
        [bucket_id, spot_id]
      )
      if (
        detailsResults &&
        detailsResults.results &&
        detailsResults.results.length > 0
      ) {
        let filepath =
          bucket_id === ESpotDetailsSource.KANTAR ||
            bucket_id === ESpotDetailsSource.FR_RADIO
            ? detailsResults.results[0].master_filepath
            : detailsResults.results[0].master_video_filepath !== null
              ? detailsResults.results[0].master_video_filepath
              : detailsResults.results[0].master_filepath
        params['filepath'] = filepath
      }
    }
    let filepath = params['filepath']
    if (filepath && filepath.includes('/media/')) {
      filepath = '/' + filepath.split('/media/')[1]
    }
    if (filepath && filepath.startsWith('/items/ads')) {
      filepath = '/mnt/nas02/S3/fullzap' + filepath
    }
    params['filepath'] = filepath
    params['master_filepath'] = filepath

    const diffusion = params['diffusion_date']
    if (diffusion) {
      params['diffusion_date'] = new Date(diffusion).toISOString()
    }

    const results = yield effects.apply(backend, backend.saveFormulaire, [
      params,
    ])
    if (results.results) {
      let country = yield effects.select(
        (state) => state.searchResults.params.country
      )
      yield effects.putResolve(saveFormulaireSuccess(results.results, country))
      if (params.download) {
        yield effects.put(downloadPdf())
      }
    }
  } catch (err) {

    yield effects.put(saveFormulaireError(err))
  }
}

function* handleDownloadPdfFormulaire(action: ReturnType<typeof downloadPdf>) {
  try {
    let country = yield effects.select(
      (state) => state.searchResults.params.country
    )
    let formulaire = yield effects.select(
      (state: IApplicationState) =>
        state.formulaire[country as keyof IFormulaireState]
    )
    if (formulaire.spot_id !== -1) {
      const url =
        Config.backendAPIURL +
        '/formulairepdf/' +
        formulaire.bucket_id +
        '/' +
        formulaire.spot_id
      window.open(url, '_blank')
    }
  } catch (err) {

  }
}
function* handleSaveFormulaireError(
  action: ReturnType<typeof saveFormulaireError>
) {
  yield effects.put(setError(new Error(action.payload)))
}
function* handleFetchExportsDeclarations(
  action: ReturnType<typeof fetchExportsDeclarations>
) {
  try {
    const backend = Backend.getInstance()
    let country = yield effects.select(
      (state) => state.searchResults.params.country
    )
    let results = yield effects.apply(backend, backend.getFormulaireList, [
      country,
      1,
      action.payload[0],
      action.payload[1],
      action.payload[2],
      true,
      action.payload[3],
    ] as any)
    if (results && results.results && results.status_code === 200) {
      yield effects.put(exportsDeclarationsSuccess(results.results))
    } else {
      yield effects.put(exportsDeclarationsError())
    }
  } catch (err) {

    yield effects.put(exportsDeclarationsError())
  }
}
function* watchFormulaire() {
  yield effects.takeEvery(
    FormulaireActionTypes.GET_FORMULAIRE_PREVIEW,
    handleGetFormulairePreview
  )
  yield effects.takeEvery(
    FormulaireActionTypes.FETCH_FORMULAIRE,
    handleFetchFormulaire
  )
  yield effects.takeEvery(
    FormulaireActionTypes.SAVE_FORMULAIRE,
    handleSaveFormulaire
  )
  yield effects.takeEvery(
    FormulaireActionTypes.SAVE_FORMULAIRE_ERROR,
    handleSaveFormulaireError
  )
  yield effects.takeEvery(
    FormulaireActionTypes.DOWNLOAD_PDF,
    handleDownloadPdfFormulaire
  )
  yield effects.takeEvery(
    FormulaireActionTypes.FETCH_EXPORTS_DECLARATIONS,
    handleFetchExportsDeclarations
  )
}

export function* FormulaireSaga() {
  yield effects.all([effects.fork(watchFormulaire)])
}

function* watchFormulaireList() {
  yield effects.takeEvery(
    FormulaireActionTypes.GET_FORMULAIRE_LIST,
    handleGetFormulaireList
  )
}

export function* FormulaireListSaga() {
  yield effects.all([effects.fork(watchFormulaireList)])
}

import * as React from 'react';
import * as MD from '@material-ui/core';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { Details } from '../Pages/Details';
import { Home } from '../Pages/Home';
import { Login } from '../Pages/Login';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { logout, saveExternalToken } from '../Store/User/Actions';
import { IUserState } from '../Store/User/Types';
import { IApplicationState } from '../Store';
import { Declarations } from '../Pages/Declarations';
import { FormulairePage } from '../Pages/Formulaire';
import withStyles, { WithStyles } from '@material-ui/styles/withStyles';
import { Authentication } from './Authentication';
import queryString from 'query-string';

const styles = MD.createStyles({
    container: {
        userSelect: 'none',
    },
});


interface IPropsFromState {
    user: IUserState;
    router: RouteComponentProps<{}>;
}

interface IPropsFromDispatch {
    logout: typeof logout;
    saveExternalToken: typeof saveExternalToken;
}

type AllProps =
    WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}>;

export class RouterComponent extends React.Component<AllProps> {
    public render(): React.ReactNode {
        let nowDate = new Date();
        let searchPath = queryString.parse(this.props.router.location.search);
        if (Object.hasOwnProperty.bind(searchPath)('token')) {
            this.props.saveExternalToken(searchPath.token as string);
            return  <Redirect to='/authentication' />
        }
        return (
            <MuiPickersUtilsProvider
                moment={moment}
                utils={MomentUtils}
            >
                    <MD.CssBaseline />
                    <MD.Grid
                        container={true}
                        direction='row'
                        justifyContent='center'
                        alignItems='center'
                        style={{ height: '100%' }}
                    >
                    {
                      (this.props.user && this.props.user.expires > nowDate && this.props.user.username !== '') ?
                        <Switch>
                          <Route exact={true} path='/' component={Home} />
                          <Route exact={true} path='/declarations' component={Declarations} />
                          <Route exact={true} path='/details/:bucket/:fgpid' component={Details} />
                          <Route exact={true} path='/formulaire/:bucket/:fgpid' component={FormulairePage} />
                          <Route
                              exact={true}
                              path='/logout'
                              render={() => {
                                  // this.props.logout();
                                  return <Redirect to='/' />;
                              }}
                          />
                          <Route exact={true} path='/authentication' render={() => {
                                return <Redirect to='/' />;
                            }}/>
                          <Route component={() => <div>Not Found</div>} />
                        </Switch> :
                        <Switch>
                            <Route exact={true} path='/authentication' component={Authentication} />
                            <Route component={Login} />
                        </Switch>
                      }
                    </MD.Grid>
            </MuiPickersUtilsProvider>
        );
    }
}


const mapStateToProps = ({ user }: IApplicationState, ownProps: RouteComponentProps<{}>) => ({
    user,
    router: ownProps
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: () => dispatch(logout()),
    saveExternalToken: (token: string) => dispatch(saveExternalToken(token)),
});


export const Router = withStyles(styles)((
    connect(mapStateToProps, mapDispatchToProps)(RouterComponent)
) as any);

import { IUserState } from "../Store/User/Types";

export function hasPermissionMediaPassagesToEdit(user: IUserState) {
    if (user.permissions && user.permissions.show_media_passages && user.permissions.show_media_passages['edit']) {
      return true;
    }
    return false
  }
  
export function hasPermissionMediaPassagesToRead(user: IUserState) {
  if (user.permissions && user.permissions.show_media_passages && user.permissions.show_media_passages['read']) {
    return true;
  }
  return false
}
  
export function hasPermissionMediaPassagesToAdd(user: IUserState) {
  if (user.permissions && user.permissions.show_media_passages && user.permissions.show_media_passages['add']) {
    return true;
  }
  return false
}

export function hasPermissionMediaPassagesToDelete(user: IUserState) {
  if (user.permissions && user.permissions.show_media_passages && user.permissions.show_media_passages['delete']) {
    return true;
  }
  return false
}

export function hasPermissionAllDeclarationsToRead(user: IUserState) {
  if (user.permissions && user.permissions.show_all_declarations && user.permissions.show_all_declarations['read']) {
    return true;
  }
  return false
}

export function hasPermissionExportPassages(user: IUserState) {
  if (user.permissions && user.permissions.permissions_export_passages && user.permissions.permissions_export_passages['read']) {
    return true;
  }
  return false
}
export function hasPermissionExportDeclarations(user: IUserState) {
  if (user.permissions && user.permissions.permissions_export_declarations && user.permissions.permissions_export_declarations['read']) {
    return true;
  }
  return false
}

import * as effects from 'redux-saga/effects';
import Moment from "moment";

import { Backend } from '../../Backend/Backend';
import { ISpotDetailsActionTypes, IBroadcastPassagesRequest, IFingerprintBucketPair, IReportSpotDetails, IBroadcastPassagesWebRequest, AdGroupNielsenParams, IPaginationParams } from './Types';
import {
    getBroadcastsPassages,
    getBroadcastsPassagesSuccess,
    getBroadcastsPassagesError,
    getSpotDetails,
    getSpotDetailsSuccess,
    getSpotDetailsError,
    getIdenticSpotsSuccess,
    getIdenticSpotsNielsenSuccess,
    getSpotFormulairesSuccess,
    getSpotFormulaires,
    getSpotFormulairesError,
    setMediaName,
    getSpotDetailsFromRegrouping,
    setSpotFingerprintBucketList, downloadCSV, downloadCSVError, reportSpotProblem, reportSpotProblemError, getBroadcastWebPassages, getBroadcastsWebPassagesSuccess, getBroadcastsWebPassagesError, getBroadcastsWebPassagesById, getBroadcastsWebPassagesByIdSuccess, getBroadcastsWebPassagesByIdError, setSpotStartDate, setAdvertiser, getBroadcastsPassagesByFgpID, setSpotFgpid, downloadCSVSuccess
} from './Actions';
import { logout } from '../User/Actions';
import { setError } from '../NotificationBar/Actions';
import { BucketMedia, COUNTRY_OPTIONS } from '../Search/Types';

function* handleGetBroadcastPassagesByFgpID(action: ReturnType<typeof getBroadcastsPassagesByFgpID>) {
    try {
        const backend = Backend.getInstance();
        const fgpid = action.payload[0];
        const bucketid = action.payload[1];
        const startDate = action.payload[2] as Moment.Moment;
        const endDate = action.payload[3] as Moment.Moment;
        const media_id: any = action.payload[5]
        let request: any = {
            fgpid: fgpid,
            bucketid: bucketid,
            startdate: startDate.format("YYYY-MM-DDTHH:mm:ss"),
            enddate: endDate.format("YYYY-MM-DDTHH:mm:ss"),
            params: action.payload[4]
        }
        if (media_id) {
            request = {
                ...request,
                media_id: media_id
            }
        }
        const results = yield effects.apply(backend, backend.getBroadcastPassages, [request])
        yield effects.put(getBroadcastsPassagesSuccess(results.results, results.total_count, results.medias));
    } catch (err) {
        yield effects.put(getBroadcastsPassagesError());
    }
}
function* handleGetBroadcastPassages(action: ReturnType<typeof getBroadcastsPassages>) {
    try {
        const backend = Backend.getInstance();
        const startDate = action.payload[1] as Moment.Moment;
        const endDate = action.payload[2] as Moment.Moment;
        const request: IBroadcastPassagesRequest = {
            fingerprint_list: action.payload[0] as IFingerprintBucketPair[],
            order_by: "DESC",
            page: 1,
            limit: 100,
            startdate: startDate.format("YYYY-MM-DDTHH:mm:ss"),
            enddate: endDate.format("YYYY-MM-DDTHH:mm:ss"),
        }
        const results = yield effects.apply(backend, backend.getBroadcastPassages, [request])
        yield effects.put(getBroadcastsPassagesSuccess(results.results, results.count, results.medias));
    } catch (err) {
        yield effects.put(getBroadcastsPassagesError());
    }
}
function* handleGetBroadcastWebPassages(action: ReturnType<typeof getBroadcastWebPassages>) {
    try {
        const backend = Backend.getInstance();
        const startDate = action.payload[0] as Moment.Moment;
        const endDate = action.payload[1] as Moment.Moment;
        const group_id: number = action.payload[2] as any;
        const page = action.payload[3] as any;
        const limit = action.payload[4] as any;

        const request: IBroadcastPassagesWebRequest = {
            order_by: "DESC",
            page: page,
            limit: limit,
            start_date: startDate.format("YYYY-MM-DDTHH:mm:ss"),
            end_date: endDate.format("YYYY-MM-DDTHH:mm:ss"),
            group_id
        }
        const results = yield effects.apply(backend, backend.getBroadcastWebPassages, [request])
        yield effects.put(getBroadcastsWebPassagesSuccess(results.results, results.count));
    } catch (err) {
        yield effects.put(getBroadcastsWebPassagesError());
    }
}
function* handleGetBroadcastWebPassagesById(action: ReturnType<typeof getBroadcastsWebPassagesById>) {
    try {
        const backend = Backend.getInstance();
        const id: number = action.payload
        const results = yield effects.apply(backend, backend.getBroadcastWebPassagesById, [id])
        yield effects.put(getBroadcastsWebPassagesByIdSuccess(results.results));
    } catch (err) {
        yield effects.put(getBroadcastsWebPassagesByIdError());
    }
}
function* handleGetSpotFormulaires(action: ReturnType<typeof getSpotFormulaires>) {
    try {
        const fingerprintBucketList = action.payload;
        const backend = Backend.getInstance();

        const formulaires = yield effects.apply(backend, backend.getSpotFormulaires, [{
            fingerprint_list: fingerprintBucketList
        }]);
        if (formulaires && formulaires.results) {
            yield effects.put(getSpotFormulairesSuccess(formulaires.results))
        }
    } catch (err) {
        yield effects.put(getSpotFormulairesError());
    }
}

function* handleGetSpotDetailsRegroupement(action: ReturnType<typeof getSpotDetailsFromRegrouping>) {
    const backend = Backend.getInstance();
    const bucketId = action.payload[0];
    const spotId = action.payload[1];
    const results = yield effects.apply(backend, backend.getSpotRegrouping, [bucketId, spotId]);
    let media: any = null;
    let advertiser: any = null;
    try {
        let fingerprintBucketList: IFingerprintBucketPair[] = [];
        let params = yield effects.select(state => state.searchResults);
        let spotDetails = yield effects.select(state => state.spotDetails);
        let bucket_id = spotDetails.metadatas.bucket_id;
        const fgp_id = spotDetails.fingerprint_id;
        const groupId = spotDetails.groupId;
        if (results && results.ads && results.ads.metadatas.length > 0) {
            // set music_broadcast for DE
            bucket_id = results.ads.metadatas[0].bucket_id
            yield effects.put(setSpotStartDate(results.start_date));
            let music_broadcast = results.ads.music_broadcast;
            //yield effects.put(setMusicBroacast(music_broadcast))
            for (let i = 0; i < results.ads.metadatas.length; i += 1) {
                fingerprintBucketList.push({
                    fgp_id: results.ads.metadatas[i].fingerprint_id,
                    bucket_id: results.ads.metadatas[i].bucket_id
                });
                if (results.ads.metadatas[i].media && results.ads.metadatas[i].media.name && results.ads.metadatas[i].media.name !== "") {
                    media = results.ads.metadatas[i].media;
                }
                if (results.ads.metadatas[i].advertiser && results.ads.metadatas[i].advertiser.name && results.ads.metadatas[i].advertiser.name !== "") {
                    advertiser = results.ads.metadatas[i].advertiser;
                }
            }

            yield effects.put(setAdvertiser(advertiser));
            yield effects.put(setMediaName(media));
        } else {
            fingerprintBucketList.push({
                bucket_id: bucketId,
                fgp_id: fgp_id
            })
        }
        if (bucketId === BucketMedia.DE_WEB[0]) {
            yield effects.put(getBroadcastWebPassages(Moment(params.params.start_date), Moment(params.params.end_date), groupId, 1, 100));
        } else {
            let order_params: IPaginationParams = {
                "page_size": 100,
                "page": 1,
                "order_by": [
                    {
                        "field": "start_date",
                        "value": "desc"
                    }
                ]
            }
            if (fgp_id !== null) {
                yield effects.put(getBroadcastsPassagesByFgpID(fgp_id, bucketId, Moment(params.params.start_date), Moment(params.params.end_date), order_params))
            }
        }
        yield effects.put(getSpotFormulaires(fingerprintBucketList));
        yield effects.put(setSpotFingerprintBucketList(fingerprintBucketList));

    } catch (err) {

    }
}

function* handleSpotDetails(action: ReturnType<typeof getSpotDetails>) {
    try {
        const backend = Backend.getInstance();
        const bucketId = action.payload[0];
        const spotId = action.payload[1];
        const results = yield effects.apply(backend, backend.getSpotDetails, [bucketId, spotId])
        if (results.results && results.results.length > 0) {
            let group_id = results.results[0].group_id;
            yield effects.putResolve(getSpotDetailsSuccess(bucketId, results.results, group_id));
            let identic_spots_results: any = []
            let country: COUNTRY_OPTIONS | null = yield effects.select(state => state.searchResults.params.country);
            if (country !== null && country === COUNTRY_OPTIONS.GERMANY) {
                let params: AdGroupNielsenParams = { bucket_id: bucketId, ad_id: spotId, external_id: results.results[0].external_id }
                const identic_spots_results_nielsen = yield effects.apply(backend, backend.getIDenticSpotsNielsen, [params]);
                yield effects.put(getIdenticSpotsNielsenSuccess(identic_spots_results_nielsen.results));
            }
            else if (results.results[0].filmid_label !== undefined) {
                identic_spots_results = yield effects.apply(backend, backend.getIdenticSpots, [results.results[0].filmid_label]);
                yield effects.put(getIdenticSpotsSuccess(identic_spots_results));
            }
            else if (results.results[0].broadcast && results.results[0].broadcast.filmid_label !== undefined) {
                identic_spots_results = yield effects.apply(backend, backend.getIdenticSpots, [results.results[0].filmid_label]);
                yield effects.put(getIdenticSpotsSuccess(identic_spots_results));
            }
            else if (results.results[0].broadcast && results.results[0].broadcast.fgp_id) {
                yield effects.put(setSpotFgpid(results.results[0].broadcast.fgp_id))
            }
            if (identic_spots_results && identic_spots_results.length > 0) {
                let ads = identic_spots_results;
                yield effects.put(setSpotFgpid(ads[0].id))
            }
            yield effects.put(getSpotDetailsFromRegrouping(bucketId, spotId));
            let params = yield effects.select(state => state.searchResults);
            let spotDetails = yield effects.select(state => state.spotDetails);
            let fingerprintBucketList = spotDetails.fingerprintBucketList;
            if (bucketId === BucketMedia.DE_WEB[0]) {
                yield effects.put(getBroadcastWebPassages(Moment(params.params.start_date), Moment(params.params.end_date), group_id, 1, 100));
            } else if (fingerprintBucketList.length > 0) {
                yield effects.put(getBroadcastsPassages(fingerprintBucketList, Moment(params.params.start_date), Moment(params.params.end_date)));
                yield effects.put(getSpotFormulaires(fingerprintBucketList));
            }

        }
        else if (results.status_code === 401) {
            yield effects.put(logout())
        }
        else {
            yield effects.put(getSpotDetailsError())
            yield effects.put(setError(new Error("Bad arpp response")));
            throw new Error("Bad arpp response")
        }
    } catch (err) {

        yield effects.put(getSpotDetailsError());
    }
}

function* handleDownloadCsv(action: ReturnType<typeof downloadCSV>) {
    try {
        const startDate = action.payload[0] as Moment.Moment;
        const endDate = action.payload[1] as Moment.Moment;
        const backend = Backend.getInstance();
        let spotDetails = yield effects.select(state => state.spotDetails);
        const request: IBroadcastPassagesRequest = {
            fingerprint_list: spotDetails.fingerprintBucketList,
            order_by: "DESC",
            startdate: startDate.format("YYYY-MM-DDTHH:mm:ss"),
            enddate: endDate.format("YYYY-MM-DDTHH:mm:ss"),
        }

        let result = yield effects.apply(backend, backend.downloadCSV, [request]);
        yield effects.put(downloadCSVSuccess(result))

    } catch (err) {

        yield effects.put(downloadCSVError());
    }
}
function* handleReportSpotProblem(action: ReturnType<typeof reportSpotProblem>) {
    try {
        const params: IReportSpotDetails = action.payload
        const backend = Backend.getInstance();
        yield effects.apply(backend, backend.reportSpotProblem, [params] as any);
    } catch (err) {

        yield effects.put(reportSpotProblemError(err));
    }
}
function* watchSpotDetails() {
    yield effects.takeEvery(ISpotDetailsActionTypes.GET_BROADCASTS_PASSAGES, handleGetBroadcastPassages);
    yield effects.takeEvery(ISpotDetailsActionTypes.GET_BROADCASTS_PASSAGES_BY_FGID, handleGetBroadcastPassagesByFgpID);
    yield effects.takeEvery(ISpotDetailsActionTypes.GET_SPOT_DETAILS, handleSpotDetails);
    yield effects.takeEvery(ISpotDetailsActionTypes.GET_SPOT_FORMULAIRES, handleGetSpotFormulaires);
    yield effects.takeEvery(ISpotDetailsActionTypes.GET_SPOT_DETAILS_REGROUPEMENT, handleGetSpotDetailsRegroupement);
    yield effects.takeEvery(ISpotDetailsActionTypes.DOWNLOAD_CSV, handleDownloadCsv);
    yield effects.takeEvery(ISpotDetailsActionTypes.REPORT_SPOT_PROBLEM, handleReportSpotProblem);
    yield effects.takeEvery(ISpotDetailsActionTypes.GET_BROADCASTS_WEB_PASSAGES, handleGetBroadcastWebPassages)
    yield effects.takeEvery(ISpotDetailsActionTypes.GET_BROADCASTS_WEB_PASSAGES_BY_ID, handleGetBroadcastWebPassagesById)
}

export function* SpotDetailsSaga() {
    yield effects.all([
        effects.fork(watchSpotDetails),
    ]);
}

import * as MD from '@material-ui/core';

import styled from 'styled-components';
import { createStyles, withStyles } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';

import MuiButton from '@material-ui/core/Button';
import { TextField, NativeSelect, Tooltip } from '@material-ui/core';
import MuiRadio from '@material-ui/core/Radio';
import MuiCheckbox from '@material-ui/core/Checkbox';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiTableCell from '@material-ui/core/TableCell';

//Router
import {
  Link
} from "react-router-dom";

export const ButtonDensed = MD.withStyles((theme: any) => ({
    root: {
        padding: '1px 8px',
        fontSize: '12px',
        color: theme.palette.text.secondary,
        textTransform: 'none',
        backgroundColor: theme.palette.background.paper
    }
}))(MuiButton);

export const ButtonDensedNoColor = withStyles({
  root: {
      padding: '1px 8px',
      fontSize: '12px',
  }
})(MuiButton);

export const NativeSelectGema = styled(NativeSelect)`
.MuiNativeSelect-root {
  width: max-content;
}
.MuiNativeSelect-icon {
  color: black;
}
`
export const TextFieldGema = styled(TextField)`
.MuiInputBase-input {
  padding: 2px;
  margin: 6px;
  margin-bottom: 15px;
  font-size: 12px;
  background-color: aliceblue;
  color: black
}
.MuiInputBase-input:focus {
  background-color: white;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 0px;
}
.MuiOutlinedInput-notchedOutline {
  border-radius: 0px;
}
.MuiOutlinedInput-root {
  color: #343a40;
  border-radius: 0px;
  border: 1px solid; 
  box-shadow: 3px 3px darkgray;
}
.MuiInputLabel-outlined {
  color: black;
  margin-top: 10px;
  margin-left: -10px;
  font-size: 11px;
  font-weight: bold;
}
`;
export const TextFieldGemaNoBackground = styled(TextFieldGema)`
.MuiInputBase-input {
  padding: 2px;
  margin: 6px;
  margin-bottom: 15px;
  font-size: 13px;
  background-color: unset;
}
`;

export const TextFieldNoBorderGema = styled(TextField)`
.MuiInputBase-input {
  padding: 4px;
  font-size: 13px;
  color: #343a40;
}
.MuiInputLabel-formControl {
  top: -7px;
  font-size: 13px;
  font-weight: 300;
}
.MuiFormLabel-root.Mui-focused {
  top: 0;
}
.MuiInputLabel-shrink {
  top: 0 !important;
}
.MuiOutlinedInput-root {
  color: #343a40;
}
`;

export const DensedTextField = styled(TextField)`
.MuiInputBase-input {
  padding: 4px;
  font-size: 13px;
}
.MuiInputLabel-formControl {
  top: -7px;
  font-size: 13px;
  font-weight: 300;
}
.MuiFormLabel-root.Mui-focused {
  top: 0;
}
.MuiInputLabel-shrink {
  top: 0 !important;
}
`;

export const DensedTextFieldDark = styled(TextField)`
.MuiInputBase-input {
  padding: 4px;
  font-size: 13px;
}
.MuiInputLabel-formControl {
  top: -7px;
  font-size: 13px;
  font-weight: 100;
}
.MuiFormLabel-root.Mui-focused {
  top: 0;
}
.MuiInputLabel-shrink {
  top: 0 !important;
}
input {
  color: #000
}
`;
export const TableRowGema = styled(MD.TableRow)`
.MuiTableRow-root {
}
`

export const CustomTooltip = withStyles((theme: any) => ({
  tooltip: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    fontSize:'11px',
    opacity: "0.93 !important",
  }
}))(Tooltip);

export const DarkRadio = withStyles({
    root: {
        color: grey[400],
        '&$checked': {
            color: grey[600],
        },
        
    },
  
    checked: {},
  })(MuiRadio);

export const DarkCheckbox = withStyles({
    root: {
      color: grey[300],
    },
    
    checked: {},
})(MuiCheckbox);

export const SmallCheckbox = withStyles({
  root: {
      color: grey[300],
      '& svg': {
        height: '14px',
        width: '14px'
      },
  },
  checked: {},
})(MuiCheckbox);

export const FormControlLabelMedium = withStyles({
  label: {
    fontSize: '14px'
  }
})(MuiFormControlLabel);

export const FormControlLabelSmall = withStyles({
    label: {
      fontSize: '13px'
    }
})(MuiFormControlLabel);

export const FormControlLabelTopLeftPlacement = withStyles({
  label: {
    fontSize: '14px',
    display: 'flex',
    alignSelf: 'flex-start'
  }
})(MuiFormControlLabel);

export const StyledLink = styled(Link)`
    text-decoration: none;
    color: #fff;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;
export const TableHeaderCellGema = withStyles(theme => ({ 
  root: {
    padding: '0px 5px',
    fontSize: '12px',
    fontWeight: 500,
    color: 'black',
    borderRightStyle: "outset",
    borderRightWidth: "thin",
    borderCollapse: 'collapse',
    fontFamily: 'Roboto',
  }
}))(MuiTableCell);

export const TableHeaderCell = withStyles(theme => ({ 
  root: {
    padding: '0px 5px',
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: 500,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary
  }
}))(MuiTableCell);

export const TableCell = withStyles(theme => ({
  root: {
    padding: '2px',
    fontSize: '11px',
    fontWeight: 500,
    color: theme.palette.text.primary,
    // borderBottom: '1px solid #EEEEEE'
  }
}))(MuiTableCell);
export const TableCellGema = withStyles(theme => ({
  root: {
    padding: '2px',
    fontSize: '11px',
    fontWeight: 500,
    color: "black",
    borderRightStyle: "outset",
    borderRightWidth: "thin",
    borderCollapse: 'collapse',
    height: 'auto !important'
  }
}))(MuiTableCell);
export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(MD.LinearProgress);

import { Reducer } from 'redux'

import { Logger } from '../../Utils/Logger'
import { TypedStorage } from '../../Utils/TypedStorage'
import { isNullOrUndefined } from '../../Utils/Various'
import { initialUserInfo, IUserInfo } from '../User/Types'

import {
  ISearchActionTypes,
  ISearchState,
  CSearchInitialState,
  ISearchParams,
  EMediaTypes,
  COUNTRY_OPTIONS,
  BucketMedia,
  IMediaSupport,
  ITimeFilters,
} from './Types'

const savedSearchedParamsState: ISearchParams | null = TypedStorage.get(
  'savedSearchedParamsState',
  null
)

let initialState = { ...CSearchInitialState }

if (initialState.params.country === null) {
  let userInfo: IUserInfo = TypedStorage.get('userInfo', initialUserInfo)
  let country: any = userInfo.country
  if (country && country.length > 0 && !isNullOrUndefined(country[0])) {
    initialState = {
      ...CSearchInitialState,
      params: {
        ...CSearchInitialState.params,
        country: country[0].toLowerCase(),
      },
    }
  }
}

if (savedSearchedParamsState !== null) {
  let newSavedSearchedParamsState = { ...savedSearchedParamsState }
  Logger.info('Reusing previous search state')

  if (
    savedSearchedParamsState.media &&
    savedSearchedParamsState.media.length === 0
  ) {
    newSavedSearchedParamsState.media = [EMediaTypes.TV]
    if (
      savedSearchedParamsState.bucket_id &&
      savedSearchedParamsState.bucket_id.length === 0
    ) {
      if (savedSearchedParamsState.country === COUNTRY_OPTIONS.FRANCE) {
        newSavedSearchedParamsState.bucket_id = BucketMedia.FR_TV
      } else if (savedSearchedParamsState.country === COUNTRY_OPTIONS.GERMANY) {
        newSavedSearchedParamsState.bucket_id = BucketMedia.DE_TV
      }
    }
  }
  initialState = {
    ...CSearchInitialState,
    params: { ...newSavedSearchedParamsState },
  }
}

const reducer: Reducer<ISearchState> = (state = initialState, action) => {
  switch (action.type) {
    case ISearchActionTypes.SEARCH_REINIT_PARAMS: {
      return { ...CSearchInitialState }
    }
    case ISearchActionTypes.LAUNCH_SEARCH: {
      let newState = {
        ...state,
        isLoading: true,
        params: action.payload,
      }
      TypedStorage.set('savedSearchedParamsState', newState.params)
      return newState
    }
    case ISearchActionTypes.LAUNCH_SEARCH_SUCCESS: {
      let newState = {
        ...state,
        ...action.payload,
        isLoading: false,
      }

      TypedStorage.set('savedSearchedParamsState', newState.params)
      return newState
    }
    case ISearchActionTypes.SET_COUNTRY: {
      let country = action.payload
      let newState = { ...state }
      let params = { ...state.params }
      if (country === COUNTRY_OPTIONS.FRANCE) {
        if (
          params.media.includes(EMediaTypes.TV) ||
          params.media.includes(EMediaTypes.Online)
        ) {
          params.media = [EMediaTypes.TV]
          params.bucket_id = BucketMedia.FR_TV
        } else if (params.media.includes(EMediaTypes.RADIO)) {
          params.bucket_id = BucketMedia.FR_RADIO
        }
      } else if (country === COUNTRY_OPTIONS.GERMANY) {
        params.child_media_id = []
        if (params.media.includes(EMediaTypes.TV)) {
          params.bucket_id = BucketMedia.DE_TV
        } else if (params.media.includes(EMediaTypes.RADIO)) {
          params.bucket_id = BucketMedia.DE_RADIO
        }
      }
      params.country = country
      newState.params = params
      return newState
    }
    case ISearchActionTypes.LAUNCH_SEARCH_ERROR: {
      let newState = {
        ...state,
        isLoading: false,
        results: [],
      }

      TypedStorage.set('savedSearchedParamsState', newState.params)
      return newState
    }
    case ISearchActionTypes.PAGINATE_SEARCH: {
      if (action.payload && action.payload.length === 2) {
        let page = action.payload[0]
        let limit = action.payload[1]
        let params = state.params
        params.params.page = page
        params.params.page_size = limit
        let newState = {
          ...state,
          isLoading: true,
          params: { ...params },
        }

        TypedStorage.set('savedSearchedParamsState', newState.params)
        return newState
      }

      return state
    }
    case ISearchActionTypes.PAGINATE_SEARCH_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      }
    }
    case ISearchActionTypes.PAGINATE_SEARCH_ERROR: {
      return {
        ...state,
        isLoading: false,
        results: {},
      }
    }
    case ISearchActionTypes.GET_MEDIA_WEB_SUCCESS: {
      let results: any = action.payload
      let items: IMediaSupport[] = []
      let ids: number[] = []
      items[0] = {
        id: 0,
        name: 'Panel Web',
        subitems: [],
      }
      items[1] = {
        id: 1,
        name: 'YouTube',
        subitems: [],
      }
      items[2] = {
        id: 2,
        name: 'Facebook',
        subitems: [],
      }
      results.map((x: any, index: number) => {
        if (x.media.indexname.indexOf('YOUTUBE') > -1) {
          items[1].subitems.push({
            id: x.media.id,
            name: x.media.name,
          })
          ids.push(x.media.id)
        }
        if (x.media.indexname.indexOf('FACEBOK') > -1) {
          items[2].subitems.push({
            id: x.media.id,
            name: x.media.name,
          })
          ids.push(x.media.id)
        }
        if (x.media.indexname.toUpperCase() === 'PANELWEB') {
          items[0].subitems.push({
            id: x.media.id,
            name: x.media.name,
          })
          ids.push(x.media.id)
        }
      })
      return {
        ...state,
        supportType: items,
        initialMediaWebId: ids,
      }
    }
    case ISearchActionTypes.GET_MEDIA_WEB_ERROR: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case ISearchActionTypes.SAVE_TIME_SELECTION: {
      let params: ITimeFilters = action.payload
      return {
        ...state,
        timeFilters: params,
      }
    }
    case ISearchActionTypes.UPDATE_COUNTRY_PARAMS: {
      if (action.payload) {
        let country = action.payload
        return {
          ...state,
          params: {
            ...state.params,
            country: country.toLowerCase(),
          },
        }
      }
      return state
    }
    default: {
      return state
    }
  }
}

export { reducer as SearchReducer }

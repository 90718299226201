import * as React from 'react';
import { Layout } from '../Components/Layout';
import { setDrawerContent, setPageContent, hideDrawer } from '../Store/Layout/Actions';
import { Route, RouteComponentProps } from 'react-router-dom';
import { IApplicationState, IConnectedReduxProps } from '../Store';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FormulaireContent } from '../Components/Declarations/Formulaire';
import { RouterState } from 'connected-react-router';

interface IPropsFromState {
    router: RouterState;
}

interface IState {
    bucketId: number;
    fgpId: number;
}


interface IPropsFromDispatch {
    setDrawerContent: typeof setDrawerContent;
    setPageContent: typeof setPageContent;
    hideDrawer: typeof hideDrawer;
}

type AllProps =
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class FormulairePageComponent extends React.Component<AllProps, IState> {

    public constructor(props: AllProps) {
        super(props);
        this.state = {
            bucketId: 0,
            fgpId: 0,
        }
    }

    public componentDidMount() {
        const pathstate = this.extractIdFomPath(this.props.router.location.pathname);

        if (pathstate === null) {
            this.setState({
                bucketId: 0,
                fgpId: 0,
            });
        } else {
            this.setState({
                bucketId: pathstate.bucketId,
                fgpId: pathstate.fgpId,
            });
        }
        
        this.props.hideDrawer();
        this.props.setPageContent(<Route component={(props: any) => <FormulaireContent {...props} bucketId={this.state.bucketId} fgpId={this.state.fgpId}/>} />); 

    }

    private extractIdFomPath = (pathname: string) => {
        const ids = pathname.match(/\d+/g);
        if (ids !== null && ids.length === 2) { 
            const bucketId = parseInt(ids[0]);
            const fgpId = parseInt(ids[1]);
            if (bucketId && fgpId) {
                return {
                    bucketId: bucketId,
                    fgpId: fgpId,
                }
            }
        }
        return null;
    }    

    public render(): React.ReactNode {
        if (this.state.bucketId === 0 || this.state.fgpId === 0) {
            return <Route component={() => <div>Not Found</div>} />
        }
        return (
            <Route component={Layout} />
        );
    }
}

const mapStateToProps = ({ router }: IApplicationState) => ({
    router
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setDrawerContent: (c: React.ReactNode) => dispatch(setDrawerContent(c)),
    setPageContent: (c: React.ReactNode) => dispatch(setPageContent(c)),
    hideDrawer: () => dispatch(hideDrawer()),
});

export const FormulairePage = connect(mapStateToProps, mapDispatchToProps)(FormulairePageComponent);

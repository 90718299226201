import Moment from "moment";
import { IMedia, IMusicBroadcasts, IMusicRight, IAdvertiser, CMediaInitialState, CAdvertiserInitialState } from "../Search/Types";
import { IFormulaireFranceState, IFormulaireGemaState } from "../Formulaire/Types";
import { AnyTxtRecord } from "dns";

export enum ISpotDetailsActionTypes {
    GET_BROADCASTS_PASSAGES = '@@spot_details/GET_BROADCASTS_PASSAGES',
    GET_BROADCASTS_PASSAGES_SUCCESS = '@@spot_details/GET_BROADCASTS_PASSAGES_SUCCESS',
    GET_BROADCASTS_PASSAGES_ERROR = '@@spot_details/GET_BROADCASTS_PASSAGES_ERROR',
    
    GET_BROADCASTS_PASSAGES_BY_FGID = '@@spot_details/GET_BROADCASTS_PASSAGES_BY_FGID',
    GET_BROADCASTS_PASSAGES_BY_FGID_SUCCESS = '@@spot_details/GET_BROADCASTS_PASSAGES_BY_FGID_SUCCESS',
    GET_BROADCASTS_PASSAGES_BY_FGID_ERROR = '@@spot_details/GET_BROADCASTS_PASSAGES_BY_FGID_ERROR',
    
    GET_BROADCASTS_WEB_PASSAGES = '@@spot_details/GET_BROADCASTS_WEB_PASSAGES',
    GET_BROADCASTS_WEB_PASSAGES_SUCCESS = '@@spot_details/GET_BROADCASTS_WEB_PASSAGES_SUCCESS',
    GET_BROADCASTS_WEB_PASSAGES_ERROR = '@@spot_details/GET_BROADCASTS_WEB_PASSAGES_ERROR',

    GET_BROADCASTS_WEB_PASSAGES_BY_ID = '@@spot_details/GET_BROADCASTS_WEB_PASSAGES_BY_ID',
    GET_BROADCASTS_WEB_PASSAGES_BY_ID_SUCCESS = '@@spot_details/GET_BROADCASTS_WEB_PASSAGES_BY_ID_SUCCESS',
    GET_BROADCASTS_WEB_PASSAGES_BY_ID_ERROR = '@@spot_details/GET_BROADCASTS_WEB_PASSAGES_BY_ID_ERROR',

    SET_SPOT_DETAILS  = '@@spot_details/SET_SPOT_DETAILS',
    SET_SPOT_FINGERPRINT_BUCKET_LIST  = '@@spot_details/SET_SPOT_FINGERPRINT_BUCKET_LIST',
    SET_MEDIA_NAME  = '@@spot_details/SET_MEDIA_NAME',
    SET_ADVERTISER  = '@@spot_details/SET_ADVERTISER',

    GET_SPOT_DETAILS = '@@spot_details/GET_SPOT_DETAILS',
    GET_SPOT_DETAILS_SUCCESS = '@@spot_details/GET_SPOT_DETAILS_SUCCESS',
    GET_SPOT_DETAILS_ERROR = '@@spot_details/GET_SPOT_DETAILS_ERROR',

    GET_IDENTIC_SPOTS_NIELSEN = '@@spot_details/GET_IDENTIC_SPOTS_NIELSEN',
    GET_IDENTIC_SPOTS_NIELSEN_SUCCESS = '@@spot_details/GET_IDENTIC_SPOTS_NIELSEN_SUCCESS',
    GET_IDENTIC_SPOTS_NIELSEN_ERROR = '@@spot_details/GET_IDENTIC_SPOTS_NIELSEN_ERROR',

    GET_SPOT_DETAILS_REGROUPEMENT = '@@spot_details/GET_SPOT_DETAILS_REGROUPEMENT',
    GET_SPOT_DETAILS_REGROUPEMENT_SUCCESS = '@@spot_details/GET_SPOT_DETAILS_REGROUPEMENT_SUCCESS',
    GET_SPOT_DETAILS_REGROUPEMENT_ERROR = '@@spot_details/GET_SPOT_DETAILS_REGROUPEMENT_ERROR',

    GET_IDENTIC_SPOTS = '@@spot_details/GET_IDENTIC_SPOTS',
    GET_IDENTIC_SPOTS_SUCCESS = '@@spot_details/GET_IDENTIC_SPOTS_SUCCESS',
    GET_IDENTIC_SPOTS_ERROR = '@@spot_details/GET_IDENTIC_SPOTS_ERROR',

    GET_SPOT_FORMULAIRES = '@@spot_details/GET_SPOT_FORMULAIRES',
    GET_SPOT_FORMULAIRES_SUCCESS = '@@spot_details/GET_SPOT_FORMULAIRES_SUCCESS',
    GET_SPOT_FORMULAIRES_ERROR = '@@spot_details/GET_SPOT_FORMULAIRES_ERROR',
    
    DOWNLOAD_CSV = '@@spot_details/DOWNLOAD_CSV',
    DOWNLOAD_CSV_SUCCESS = '@@spot_details/DOWNLOAD_CSV_SUCCESS',
    DOWNLOAD_CSV_ERROR = '@@spot_details/DOWNLOAD_CSV_ERROR',
    
    SELECT_MUSIC = '@@spot_details/SELECT_MUSIC',
    UPDATE_SELECTED_MUSIC_INTERVAL = '@@spot_details/UPDATE_SELECTED_MUSIC_INTERVAL',
    
    SAVE_REGIONS_SURFER = '@@spot_details/SAVE_REGIONS_SURFER',
    SAVE_REGIONS = '@@spot_details/SAVE_REGIONS',

    REPORT_SPOT_PROBLEM = '@@spot_details/REPORT_SPOT_PROBLEM',
    REPORT_SPOT_PROBLEM_ERROR = '@@spot_details/REPORT_SPOT_PROBLEM_ERROR',
    REPORT_SPOT_PROBLEM_SUCCESS = '@@spot_details/REPORT_SPOT_PROBLEM_SUCCESS',

    SET_MUSIC_BROADCAST = '@@spot_details/SET_MUSIC_BROADCAST',
    SET_SPOT_START_DATE = '@@spot_details/SET_SPOT_START_DATE',

    SET_SPOT_FGPID = '@@spot_details/SET_SPOT_FGPID',
}

export interface IMediasList {
    count: number
    id: number
    name: string
}
export interface IPaginationParams {
    "page_size": number,
    "page": number,
    "order_by": [
        {
            "field": string,
            "value": "desc" | "asc"
        }
    ]
}
export const SPOT_STATUS_FRANCE = {
    VALIDATED: [11, 12],
    NON_VALIDATED: [6, 8, 10]
} as any

export const GROUP_STATUS_FRANCE =  {
    CREATED: 'CREATED',
    BROADCASTED: 'BROADCASTED',
    WAITING_METADATA: 'WAITING_METADATA',
    MASTER_RAW_AUDIO_OK: 'MASTER_RAW_AUDIO_OK',
    MUSIC_RECO_OK: 'MUSIC_RECO_OK',
    VIDEO_ADJUSTED: 'VIDEO_ADJUSTED',
    MASTER_VIDEO_OK: 'MASTER_VIDEO_OK',
    VALIDATED: 'VALIDATED',
    IN_PROCESS: 'IN_PROCESS',
    REPORTED: 'REPORTED',
    NEW_MUSIC_DETECTED: 'NEW_MUSIC_DETECTED',
    DELETED: 'DELETED'
}

export const SPOT_STATUS_GERMANY = {
    NEW_MUSIC_DETECTED: 'NEW_MUSIC_DETECTED',//12
    MUSIC_RECO_OK: 'MUSIC_RECO_OK', //6
    VALIDATED: 'VALIDATED', // 8, 10, 11
    CREATED: 'CREATED'
} as any

export interface AdGroupNielsenParams{
    bucket_id: number, 
    external_id: number, 
    ad_id: number
}
export interface ISpotDetailsRequest {
    spotId: number;
    bucketId: ESpotDetailsSource;
}

export enum ESpotDetailsSource {
    ARPP = 1,
    KANTAR = 18,
    NILSON = 0,
    FR_RADIO = 87
}

export interface IIdenticSpot {
    arpp_code: string;
    filmid_label: string;
    film_id: string;
}
export interface IIdenticSpotNielsen {
    ad_id: number;
    external_id: number;
    title: string;
    master_filepath: string;
}
export interface IMediaInfo {
    id: number;
    name: string;
}

export interface IMetadatasDetails {
    advertiser: IAdvertiser;
    agency_name: string;
    arpp_code: string;
    arpp_internal_id: string;
    created: Moment.Moment | null;
    duration: number;
    group_id: number;
    film_id_label: string;
    first_broadcast: Moment.Moment | null;
    master_filepath: string;
    media_id: number;
    modified: Moment.Moment | null;
    music_arpp_no_music: boolean;
    music_broadcast: IMusicBroadcasts[];
    music_rights: IMusicRight[];
    product_code: string;
    product_label: string;
    reference_film_id: string;
    title: string;
    nb_films?: number;
    start_date?: string;
}

export interface ISpotDetailsDe {
    created:string
    creative_agency:any
    duration:any
    external_id:number
    filepath:string | null
    fingerprint:any
    first_air_date:string
    group:{action_logs: any[], created: string, first_diffusion: string, id: number, master_filepath: string,
    master_url:string,
    master_video_thumbnail:any,
    modified:any,
    music_broadcasts:any[],
    reference_ad_id:number,
    state:string},
    group_id:number
    id:number
    master_path:string
    master_url:string
    media_type:string
    modified:string
    nielsen_master_url:string
    product:{id: number, name: string}
    sector:{id: number, label: string}
}

export interface ISpotDetails {
    bucket_id: number;
    bucket_name: string;
    broadcast?: any | null;
    duration: number;
    has_formulaire: boolean;
    end_date: Moment.Moment | null;
    start_date: Moment.Moment | null;
    filepath: string;
    thumbnail_path: string;
    media: IMedia;
    media_type?: string;
    media_id: number;
    metadatas?: IMetadatasDetails;
    music_state?: any;
}

export interface ISpotDetailsState {
    broadcastPassages: IBroadcastPassage[];
    broadcastPassagesCount: number;
    medias: IMediasList[];
    broadcastPassagesWeb: IBroadcastPassageWeb[];
    broadcastPassagesWebCount: number;
    isLoadingBroadcastPassages: boolean;
    isLoadingBroadcastPassagesWeb: boolean;
    bucketId: number;
    groupId: number;
    metadatas: ISpotDetails;
    endDate: Moment.Moment | null;
    formulaireList: (IFormulaireFranceState | IFormulaireGemaState)[];
    loadingFormulaireList: boolean | null;
    identicSpots: IIdenticSpot[];
    identicSpotsNielsen: IIdenticSpotNielsen[];
    fingerprintBucketList: IFingerprintBucketPair[];
    fingerprint_id: number | null;
    startDate: Moment.Moment | null;
    regrouping_id: string;
    spotDetailsLoading: boolean;
    selectedMusicID: string | null;
    regions: any;
    surfer: any;
    file_downloaded: any;
}

export const CMetadatasDetailsInitialState: IMetadatasDetails = {
    advertiser: CAdvertiserInitialState,
    agency_name: "",
    arpp_code: "",
    arpp_internal_id: "",
    created: null,
    duration: -1,
    group_id: -1,
    film_id_label: "",
    first_broadcast: null,
    master_filepath: "",
    media_id: -1,
    modified: null,
    music_arpp_no_music: false,
    music_broadcast: [],
    music_rights: [],
    product_code: "",
    product_label: "",
    reference_film_id: "",
    title: "",
}
export const CSpotMetadatasInfoInitialState: ISpotDetails = {
    bucket_id: -1,
    bucket_name: '',
    duration: -1,
    broadcast: null,
    end_date: null,
    has_formulaire: false,
    filepath: '',
    thumbnail_path: '',
    // id: -1,
    media: CMediaInitialState,
    media_id: -1,
    metadatas: CMetadatasDetailsInitialState,
    start_date: null,
    music_state: ''
}

export const CSpotDetailsInitialState: ISpotDetailsState = {
    broadcastPassages: [],
    broadcastPassagesCount: 0,
    broadcastPassagesWeb:[],
    medias: [],
    loadingFormulaireList: null,
    broadcastPassagesWebCount: 0,
    isLoadingBroadcastPassages: false,
    isLoadingBroadcastPassagesWeb: false,
    bucketId: ESpotDetailsSource.ARPP,
    groupId: -1,
    fingerprintBucketList: [],
    fingerprint_id: null,
    metadatas: CSpotMetadatasInfoInitialState,
    endDate: null,
    formulaireList: [],
    identicSpots: [],
    identicSpotsNielsen: [],
    startDate: null,
    regrouping_id: '',
    spotDetailsLoading: false,
    selectedMusicID: null,
    regions: {},
    surfer: null,
    file_downloaded: null
}

export interface IMediaSimple {
    id: number;
    name: string;
}

export interface IBucketSImple {
    id: number;
    name: string;
}

export interface IConfigSimple {
    id: number;
    progress_date: string;
}

export interface IMasterSequenceSimple {
    start: number;
    end: number;
}

export interface IBroadcastPassage {
    id: number;
    created: string;
    modified: string;
    start_date: string;
    end_date: string;
    media: IMediaSimple;
    fingerprint_id: number;
    bucket: IBucketSImple;
    bed: boolean;
    gain: number;
    config:  IConfigSimple;
    master_sequences: IMasterSequenceSimple;
    filepath: string;
}
export interface IMediaWeb {
    external_id: number,
    id: number,
    label: string,
    media_type: string
}
export interface IBroadcastType {
    external_id: number,
    id: number,
    name: string
}
export interface IBroadcastPassageWeb {
    ad_id: number,
    ad_imp: number,
    date: string,
    euro: any,
    group: any,
    group_id: any,
    id: string,
    media: IMediaWeb,
    media_id: number,
    type: IBroadcastType
}
export interface IFingerprintBucketPair  {
    fgp_id: number;
    bucket_id: number;
}

export interface IBroadcastPassagesRequest {
    fingerprint_list: IFingerprintBucketPair[];
    order_by: string;
    page?: number;
    limit?: number;
    startdate: string;
    enddate: string;
}
export interface IBroadcastPassagesWebRequest {
    order_by: string;
    page?: number;
    limit?: number;
    start_date: string;
    end_date: string;
    group_id: number;
}
export interface IReportSpotDetails {
    spot_id: number;
    email: string; 
    comment:string;
    product_label: string;
    title: string;
    duration: string | number;
    first_broadcast: any;
    advertiser: string;
    media_name: string;
    identic_spots: any[];
    spot_link: string;
}

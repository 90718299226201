import { Reducer } from 'redux'
import Moment from 'moment'

import {
  IFormulaireState,
  CFormulaireInitial,
  FormulaireActionTypes,
  IRightHolders,
  CMusicInfoInitial,
  IFormulaireList,
  CFormulaireListInitialState,
  CMusicGemaType,
  IMusicInfoGema,
  FormulairesResponse,
  formulairesEmpty,
} from './Types'
import {
  ESpotDetailsSource,
  IFingerprintBucketPair,
  SPOT_STATUS_GERMANY,
} from '../SpotDetails/Types'
import { COUNTRY_OPTIONS } from '../Search/Types'
import { isNullOrUndefined } from '../../Utils/Various'
import { dateDiff } from '../../Utils/Time'

function retrieveArppCodes(identicSpots: any) {
  let results = ''
  if (!identicSpots) {
    return results
  }
  for (let i = 0; i < identicSpots.length; i += 1) {
    let spot = identicSpots[i]
    if (
      spot.hasOwnProperty('clearance_metadata') &&
      spot.clearance_metadata !== null
    ) {
      results +=
        spot.clearance_metadata.clearance_decision.clearance_decision_number
    }
    if (i < identicSpots.length - 1) {
      results += ', '
    }
  }
  return results
}

function retrievePubIdCodes(identicSpots: any) {
  let results = ''
  if (!identicSpots) {
    return results
  }
  for (let i = 0; i < identicSpots.length; i += 1) {
    let spot = identicSpots[i]
    let filmid_label = spot.filmid_label
    results += filmid_label
    if (i < identicSpots.length - 1) {
      results += ', '
    }
  }
  return results
}

const reducer: Reducer<IFormulaireState> = (
  state = CFormulaireInitial,
  action
) => {
  switch (action.type) {
    case FormulaireActionTypes.FETCH_FORMULAIRE: {
      return CFormulaireInitial
    }
    case FormulaireActionTypes.FETCH_FORMULAIRE_SUCCESS_PDF: {
      return {
        ...state,
        isLoading: false,
        isReceived: true,
        formulairesPDF: action.payload,
      }
    }

    case FormulaireActionTypes.FETCH_FORMULAIRE_FAILURE_PDF: {
      return {
        ...state,
        isLoading: false,
        isReceived: true,
        formulairesPDF: undefined,
      }
    }
    case FormulaireActionTypes.FETCH_FORMULAIRE_SUCCESS: {
      let formulaire = action.payload[0]
      let country = action.payload[1] as COUNTRY_OPTIONS
      return {
        ...state,
        [country as keyof IFormulaireState]: { ...formulaire },
        isLoading: false,
        isReceived: true,
      }
    }
    case FormulaireActionTypes.SAVE_FORMULAIRE_SUCCESS: {
      let formulaire = action.payload[0]
      let country = action.payload[1] as COUNTRY_OPTIONS

      return {
        ...state,
        [country as keyof IFormulaireState]: { ...formulaire },
      }
    }

    // For France only
    case FormulaireActionTypes.SET_MEDIA_NAME: {
      if (action.payload) {
        return {
          ...state,
          [COUNTRY_OPTIONS.FRANCE as keyof IFormulaireState]: {
            ...state[COUNTRY_OPTIONS.FRANCE],
            media: action.payload.name,
          },
        }
      }
      return state
    }
    case FormulaireActionTypes.GET_FORMULAIRE_PREVIEW_SUCCESS: {
      let regroupingId = action.payload[0] as string
      let bucketId = action.payload[1]
      let result = action.payload[2]
      let media = action.payload[5]
      let identicSpots = action.payload[6]
      let country = action.payload[7]
      const arpp_codes = retrieveArppCodes(identicSpots)
      const pub_id_codes = retrievePubIdCodes(identicSpots)
      const userInfo = action.payload[3]
      const fingerprint_list = action.payload[4] as IFingerprintBucketPair[]
      let first_broadcast = ''
      let musics: any[] = []
      if (result && result.length > 0) {
        result = result[0]
      }
      const master_filepath = result.master_filepath
      const master_video_filepath = result.master_video_filepath || ''
      let newState = { ...state }
      // France
      if (country === COUNTRY_OPTIONS.FRANCE) {
        let broadcast: any = null
        if (
          bucketId === ESpotDetailsSource.KANTAR ||
          bucketId === ESpotDetailsSource.FR_RADIO
        ) {
          broadcast = result.broadcast
        }
        if (result.group && result.group.broadcasts) {
          broadcast = result.group.broadcasts[0]
        }
        if (broadcast !== undefined && broadcast !== null) {
          first_broadcast = broadcast.start_date
        }
        if (
          result.group &&
          result.group.music_broadcasts &&
          result.group.music_broadcasts.length > 0
        ) {
          musics = result.group.music_broadcasts.filter(
            (x: any) => x.music_state !== 'DELETED'
          )
          musics = musics.map((music: any) => {
            let rightHolders: IRightHolders[] = []
            let rightHoldersDict: any = {}

            if (
              music.artist !== undefined &&
              music.artist !== null &&
              music.artist !== ''
            ) {
              const persons = music.artist
                .split(',')
                .map((person: string) => person.trim())
              for (let i = 0; i < persons.length; i += 1) {
                if (rightHoldersDict.hasOwnProperty(persons[i])) {
                  rightHoldersDict[persons[i]].roles.push('artist')
                } else {
                  rightHoldersDict[persons[i]] = {
                    name: persons[i],
                    roles: ['artist'],
                  }
                }
              }
            }

            if (
              music.composers !== undefined &&
              music.composers !== null &&
              music.composers !== ''
            ) {
              const persons = music.composers
                .split(',')
                .map((person: string) => person.trim())
              for (let i = 0; i < persons.length; i += 1) {
                if (rightHoldersDict.hasOwnProperty(persons[i])) {
                  rightHoldersDict[persons[i]].roles.push('composer')
                } else {
                  rightHoldersDict[persons[i]] = {
                    name: persons[i],
                    roles: ['composer'],
                  }
                }
              }
            }
            if (
              music.authors !== undefined &&
              music.authors !== null &&
              music.authors !== ''
            ) {
              const persons = music.authors
                .split(',')
                .map((person: string) => person.trim())
              for (let i = 0; i < persons.length; i += 1) {
                if (rightHoldersDict.hasOwnProperty(persons[i])) {
                  rightHoldersDict[persons[i]].roles.push('author')
                } else {
                  rightHoldersDict[persons[i]] = {
                    name: persons[i],
                    roles: ['author'],
                  }
                }
              }
            }

            for (const [key, value] of Object.entries(rightHoldersDict)) {
              rightHolders.push(value as IRightHolders)
            }
            let usage_duration =
              parseInt(music.end_time) - parseInt(music.start_time)
            return {
              title: music.title,
              usage_duration,
              type: null,
              code_isrc: music.isrc,
              code_iswc: music.iswc,
              code: '',
              right_holders: rightHolders,
            }
          })
        } else {
          musics = [CMusicInfoInitial]
        }

        let marque = ''
        let product_name = ''
        let agency = ''
        let spot_title = ''
        let duration: any = ''

        if (
          result.hasOwnProperty('creative_agency_metadata') &&
          result.creative_agency_metadata !== null
        ) {
          if (
            result.creative_agency_metadata.hasOwnProperty('advertiser') &&
            result.creative_agency_metadata.advertiser !== null
          ) {
            marque = result.creative_agency_metadata.advertiser.internal_name
          }
          if (
            result.creative_agency_metadata.hasOwnProperty('product') &&
            result.creative_agency_metadata.product !== null
          ) {
            product_name = result.creative_agency_metadata.product.internal_name
          }
          if (
            result.creative_agency_metadata.hasOwnProperty('creative_agency') &&
            result.creative_agency_metadata.creative_agency !== null
          ) {
            agency =
              result.creative_agency_metadata.creative_agency.internal_name
          }
          spot_title = result.creative_agency_metadata.title
          if (
            !isNullOrUndefined(result.creative_agency_metadata) &&
            result.creative_agency_metadata.duration
          ) {
            duration = result.creative_agency_metadata.duration
          } else if (broadcast !== null && broadcast !== undefined) {
            duration = dateDiff(
              new Date(broadcast.end_date),
              new Date(broadcast.start_date)
            ).sec
          }
        }

        newState = {
          ...state,
          [COUNTRY_OPTIONS.FRANCE as keyof IFormulaireState]: {
            ...state[COUNTRY_OPTIONS.FRANCE],
            diffusion_date: first_broadcast,
            bucket_id: bucketId,
            spot_id:
              bucketId === ESpotDetailsSource.KANTAR ||
                bucketId === ESpotDetailsSource.FR_RADIO
                ? result.broadcast.id
                : result.id,
            fingerprint_list,
            regrouping_id: regroupingId,
            master_filepath,
            master_video_filepath,
            marque: marque,
            country: COUNTRY_OPTIONS.FRANCE,
            codes_arpp: arpp_codes,
            codes_pub_id: pub_id_codes,
            product_name: product_name,
            agency: agency,
            spot_title: spot_title,
            duration: duration,
            media: media,
            music: musics,
            society: {
              id: userInfo.id,
              name: userInfo.firstname,
              surname: userInfo.name,
              email: userInfo.email,
              date: new Date().toISOString().substring(0, 10),
              user_group_info: userInfo.parent_info,
              signature: '',
            },
            diffuser: media.name,
          },
        }
        //newState = {...state}
      } else if (country === COUNTRY_OPTIONS.GERMANY && result.advertiser) {
        let group = result.group
        let diffusion_date: any = ''
        let product_year: any = ''
        musics = result.music_broadcasts || []
        if (group) {
          diffusion_date = group.first_diffusion
          product_year = new Date(diffusion_date).getFullYear()
          musics = group.music_broadcasts
        }
        musics = musics.filter((x: any) => x.music_state !== 'DELETED')
        let music_duration: any = 0
        musics = musics.map((item: any) => {
          let music: IMusicInfoGema = item
          music_duration += item.end_time - item.start_time
          music_duration =
            item.music_state === SPOT_STATUS_GERMANY.VALIDATED
              ? music_duration
              : ''
          if (!item.music_gema_type) {
            music = {
              ...item,
              music_gema_type: CMusicGemaType.WERK,
            }
          }
          music = {
            ...item,
            sek: music_duration,
            product_year,
            product_country: item.country,
          }
          return music
        })
        let type_1,
          type_2,
          type_3 = false
        let ad_type = result.ad_type
        if (ad_type !== null && ad_type !== undefined) {
          type_1 = ad_type.id === 1
          type_2 = ad_type.name.toUpperCase().indexOf('SPONSORING') > -1
          type_3 = ad_type.name.toUpperCase().indexOf('TRAILER') > -1
        }

        if (music_duration && group.state === SPOT_STATUS_GERMANY.VALIDATED) {
          music_duration = parseFloat(
            ('' + music_duration.toFixed(2)).replace(/0+$/, '')
          )
        } else {
          music_duration = ''
        }

        let product_country = result.country
        let media_type = result.media_type
        let mandat_localization_date: any = result.mandat_localization_date
        if (!mandat_localization_date) {
          mandat_localization_date = Moment(new Date()).format('DD-MM-YYYY')
        }
        let editor_localization_date = result.editor_localization_date
        if (!editor_localization_date) {
          editor_localization_date = Moment(new Date()).format('DD-MM-YYYY')
        }
        let agency = result.creative_agency ? result.creative_agency.title : ''
        if (agency.toUpperCase() === 'KEINE AGENTUR GELIEFERT') {
          agency = ''
        }
        newState = {
          ...state,
          [COUNTRY_OPTIONS.GERMANY as keyof IFormulaireState]: {
            ...state[COUNTRY_OPTIONS.GERMANY],
            diffusion_date: Moment(result.first_air_date),
            announcer: result.advertiser.title,
            bucket_id: bucketId,
            country: COUNTRY_OPTIONS.GERMANY,
            spot_id: result.id,
            fingerprint_list,
            regrouping_id: regroupingId,
            master_filepath,
            master_video_filepath,
            marque: result.master_path,
            music_duration: music_duration,
            product_name: result.product.name,
            agency,
            spot_title: result.ad_title,
            duration: result.duration,
            media: media,
            music: musics,
            product_year,
            product_country,
            media_type_cinema: media_type === 'CINEMA',
            media_type_tv: media_type === 'TV',
            media_type_radio: media_type.toUpperCase() === 'RADIO',
            diffusion_online: media_type.toUpperCase() === 'WEB',
            mandat_localization_date,
            editor_localization_date,
            spot_alt_title: identicSpots.length,
            type_1,
            type_2,
            type_3,
            society: {
              id: userInfo.id,
              name: userInfo.firstname,
              surname: userInfo.name,
              email: userInfo.email,
              date: new Date().toISOString().substring(0, 10),
              user_group_info: userInfo.parent_info,
              signature: '',
            },
          },
        }
      }
      return newState
    }
    default: {
      return { ...state }
    }
  }
}

const reducerList: Reducer<IFormulaireList> = (
  state = CFormulaireListInitialState,
  action
) => {
  switch (action.type) {
    case FormulaireActionTypes.GET_FORMULAIRE_LIST: {
      return {
        ...state,
        limit: 25,
        page: 1,
        results: [],
        total_count: 0,
        total_pages: 0,
        loadingFormulaireList: true,
        formulaires: formulairesEmpty,
      }
    }
    case FormulaireActionTypes.GET_FORMULAIRE_LIST_SUCCESS: {
      let results = action.payload as FormulairesResponse
      return {
        ...state,
        formulaires: results,
        loadingFormulaireList: false,
      }
    }
    case FormulaireActionTypes.GET_FORMULAIRE_LIST_ERROR: {
      return {
        ...state,
        formulaires: formulairesEmpty,
      }
    }

    case FormulaireActionTypes.EXPORTS_DECLARATIONS: {
      return {
        ...state,
        file_downloaded: null,
      }
    }
    case FormulaireActionTypes.EXPORTS_DECLARATIONS_SUCCESS: {
      return {
        ...state,
        file_downloaded: action.payload,
      }
    }

    case FormulaireActionTypes.CLEAN_FILE_DOWNLOAD: {
      return {
        ...state,
        file_downloaded: null,
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

export { reducer as FormulaireReducer }
export { reducerList as FormulaireListReducer }

export function floorMinute(d: Date) {
    if ((d.getSeconds() === 0) && (d.getMilliseconds() === 0)) {
        return d;
    }
    return new Date(
        new Date(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            d.getHours(),
            d.getMinutes(),
            0,
            0,
        ),
    );
}
export function formatTime(seconds:any) {
    return [Math.floor(seconds / 60), Math.floor(seconds % 60)]
      .map(x => x.toString())
      .map(x => (x.length === 1 ? `0${x}` : x))
      .join(":");
}

export function ceilMinute(d: Date) {
    if ((d.getSeconds() === 0) && (d.getMilliseconds() === 0)) {
        return d;
    }
    return new Date(
        new Date(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            d.getHours(),
            d.getMinutes(),
            0,
            0,
        ).getTime() + 60000,
    );
}

export function dateFromProto(val: number | Long): Date {
    if (!(typeof val === 'number')) {
        if (
            (val.toNumber() === 0) ||
            (val.compare(-8640000000000) === -1) ||
            (val.compare(8640000000000) === 1)
        ) {
            throw new Error('Invalid date');
        }
        return new Date(val.toNumber() * 1000);
    }
    return new Date(val * 1000);
}
export function diff_minutes(dt2:Date, dt1:Date) 
{
    var diff =(dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    let minutes = Math.abs(Math.round(diff));
    return minutes
}
export function dateDiff(date1:any, date2:any){
    let diff = {
        sec:0,
        min:0,
        hour:0,
        day:0
    };                                      // Initialisation du retour
    let tmp = Math.abs(date2 - date1);
    
    tmp = Math.floor(tmp/1000);             // Nombre de secondes entre les 2 dates
    diff.sec = tmp % 60;                    // Extraction du nombre de secondes
 
    tmp = Math.floor((tmp-diff.sec)/60);    // Nombre de minutes (partie entière)
    diff.min = tmp % 60;                    // Extraction du nombre de minutes
 
    tmp = Math.floor((tmp-diff.min)/60);    // Nombre d'heures (entières)
    diff.hour = tmp % 24;                   // Extraction du nombre d'heures
     
    tmp = Math.floor((tmp-diff.hour)/24);   // Nombre de jours restants
    diff.day = tmp;
     
    return diff;
}
export function adjustForTimezone(date:Date):Date{
    var timeOffsetInMS:number = date.getTimezoneOffset() * 60000;
    
    return new Date(date.getTime() - timeOffsetInMS);
}
export function fromUTC(date:Date) {
    let newDate = new Date(date);
    var timeOffsetInMS:number = newDate.getTimezoneOffset() * 60000;
    
    return new Date(newDate.getTime() + timeOffsetInMS);
}
export function getDateOneDayBeforeTodayHourZero(todayHourZero:Date){
    let date = new Date();
    return  new Date(date.getFullYear(), date.getMonth(), todayHourZero.getDate() -1, 0, 0, 0);
}
export function getDateHourZero(date:Date){
    return  new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
}

export function getDateTodayHourZero(){
    let date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
}

import { Reducer } from 'redux';
import { CWaveformInitialState, IWaveformState, IWaveformActionTypes } from "./Types";

const reducer: Reducer<IWaveformState> = (state = CWaveformInitialState, action) => {
    switch (action.type) {
        case IWaveformActionTypes.UPDATE_CURSOR: {
            const { cursor } = action.payload;
            return {
                cursor,
                force: !state.force
            }
        }
        default: return state;
    }
}

export { reducer as WaveformReducer };

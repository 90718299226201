import { connectRouter, LocationChangeAction, RouterState } from 'connected-react-router';
import { History } from 'history';
import { Action, AnyAction, combineReducers, Dispatch } from 'redux';
import * as effects from 'redux-saga/effects';
import { Reducer } from 'typesafe-actions';

import { LayoutReducer } from './Layout/Reducer';
import { LayoutSaga } from './Layout/Sagas';
import { ILayoutState } from './Layout/Types';

import { UserReducer } from './User/Reducer';
import { UserSaga } from './User/Sagas';
import { IUserState } from './User/Types';

import { I18NReducer } from './I18n/Reducer';
import { I18NSaga } from './I18n/Sagas';
import { I18NState } from './I18n/Types';

import { ISearchState } from './Search/Types';
import { SearchReducer } from './Search/Reducer';
import { SearchSaga } from './Search/Sagas';
import { ISpotDetailsState } from './SpotDetails/Types';
import { SpotDetailsReducer } from './SpotDetails/Reducer';
import { SpotDetailsSaga } from './SpotDetails/Sagas';
import { IFormulaireState, IFormulaireList } from './Formulaire/Types';
import { FormulaireReducer, FormulaireListReducer } from './Formulaire/Reducer';
import { FormulaireSaga, FormulaireListSaga } from './Formulaire/Sagas';

import { NotificationBarReducer } from './NotificationBar/Reducer';
import { INotificationBarState } from './NotificationBar/Types';
import { IWaveformState } from './Waveform/Types';
import { WaveformReducer } from './Waveform/Reducer';

export interface IApplicationState {
  i18n: I18NState;
  layout: ILayoutState;
  formulaire: IFormulaireState;
  formulaireList: IFormulaireList;
  notificationBar: INotificationBarState;
  router: RouterState;
  searchResults: ISearchState;
  spotDetails: ISpotDetailsState;
  user: IUserState;
  waveform: IWaveformState;
}

export interface IConnectedReduxProps<A extends Action = AnyAction> {
    dispatch: Dispatch<A>;
}

export const RootReducer = (history: History) => combineReducers<IApplicationState>({
  i18n: I18NReducer,
  layout: LayoutReducer,
  formulaire: FormulaireReducer,
  formulaireList: FormulaireListReducer,
  notificationBar: NotificationBarReducer,
  router: connectRouter(history) as Reducer<RouterState, LocationChangeAction>,
  searchResults: SearchReducer,
  spotDetails: SpotDetailsReducer,
  user: UserReducer,
  waveform: WaveformReducer,
});

export function* RootSaga() {
    yield effects.all([
      effects.fork(I18NSaga),
      effects.fork(FormulaireSaga),
      effects.fork(FormulaireListSaga),
      effects.fork(LayoutSaga),
      effects.fork(UserSaga),
      effects.fork(SearchSaga),
      effects.fork(SpotDetailsSaga),
    ]);
}

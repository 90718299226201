import * as bunyan from 'browser-bunyan';

import { Config } from '../config';

export class MAConsoleStream extends bunyan.ConsoleFormattedStream {
    public write(): boolean {
        const args = Array.from(arguments);

        const retVal = super.write.apply(this, args as any) as any;

        /*
        */
        if (typeof args[0] === 'object') {
            const props: any = {};
            const defaultKeys: string[] = [
                'level',
                'levelName',
                'msg',
                'name',
                'src',
                'time',
                'v',
            ];

            Object.keys(args[0]).forEach((key) => {
                if (defaultKeys.indexOf(key) === -1) {
                    props[key] = args[0][key];
                }
            });
            if (Object.keys(props).length > 0) {
                // tslint:disable: no-console
                if (
                    (typeof console.groupCollapsed === 'function') &&
                    (typeof console.table === 'function')
                ) {
                    console.groupCollapsed('Extra data');
                    console.table(props);
                    console.groupEnd();
                } else {
                    console.log(props);
                }
                // tslint:enable: no-console
            }
        }
        return retVal;
    }
}

const createLogger = () => bunyan.createLogger({
    name: 'raw',
    src: true,
    streams: [
        {
            level: Config.logLevel,
            stream: new MAConsoleStream(),
        },
    ],
});

export const Logger = createLogger();

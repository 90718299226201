import { Reducer } from 'redux';

import { Backend } from '../../Backend/Backend';
import { Logger } from '../../Utils/Logger';
import { TypedStorage } from '../../Utils/TypedStorage';

import { IUserActionTypes, IUserState, initialUserState, initialUserInfo, CPermissionInitial } from './Types';

const initialUser = TypedStorage.get('userName', '');
const initialUserDate = TypedStorage.get('userDate', 0);
const initialUserToken = TypedStorage.get('userToken', '');
const initialPlayerToken = TypedStorage.get('tokenPlayer', '');
const initialUserInfoTS = TypedStorage.get('userInfo', initialUserInfo);
const initialPermissions = TypedStorage.get('permissions', CPermissionInitial);

let initialState = {...initialUserState};

if ((initialUserToken !== '') && (initialUser !== '') && (initialUserDate !== 0)) {
    if (initialUserDate > Date.now()) {
        Logger.info('Reusing previous session');
        initialState = {
            expires: new Date(initialUserDate),
            token: initialUserToken,
            username: initialUser,
            userInfo: initialUserInfoTS,
            tokenExternal: null,
            permissions: initialPermissions,
            tokenPlayer: initialPlayerToken
        };
        Backend.getInstance().setToken(initialUserToken);
    } else {
        Logger.info('Cancelling expired previous session');
        TypedStorage.set('userName', '');
        TypedStorage.set('userToken', '');
        TypedStorage.set('userDate', 0);
        TypedStorage.set('userInfo', initialUserInfo);
        TypedStorage.set('permissions', CPermissionInitial);
    }
}

const reducer: Reducer<IUserState> = (state = initialState, action) => {
    switch (action.type) {
        case IUserActionTypes.LOGOUT_SUCCESS: {
            TypedStorage.set('userName', '');
            TypedStorage.set('userToken', '');
            TypedStorage.set('userDate', 0);
            TypedStorage.set('userInfo', initialUserInfo);
            TypedStorage.set('permissions', CPermissionInitial);
            return {...initialUserState};
        }
        case IUserActionTypes.LOGIN_ERROR: {
            TypedStorage.set('userName', '');
            TypedStorage.set('userToken', '');
            TypedStorage.set('userDate', 0);
            TypedStorage.set('userInfo', initialUserInfo);
            TypedStorage.set('permissions', CPermissionInitial);
            return {
                token: '',
                tokenExternal: '',
                username: '',
                expires: new Date(0),
                userInfo: initialUserInfo,
                permissions: initialPermissions,
                tokenPlayer: ''
            };
        }
        case IUserActionTypes.LOGIN_SUCCESS: {
            TypedStorage.set('userName', action.payload[0]);
            TypedStorage.set('userToken', action.payload[1]);
            TypedStorage.set('userDate', action.payload[2]);
            TypedStorage.set('userInfo', action.payload[3]);
            if (action.payload[4] !== undefined) {
                TypedStorage.set('permissions', action.payload[4]);
            }
            return {
                ...state,
                token: action.payload[1],
                tokenExternal: null,
                username: action.payload[0],
                expires: new Date(action.payload[2]),
                userInfo: action.payload[3],
                permissions: action.payload[4]
            };
        }
        case IUserActionTypes.SAVE_EXTERNAL_TOKEN: {
            TypedStorage.set('userName', '');
            return {
                ...state,
                tokenExternal: action.payload,
                username: ''
            }
        }
        case IUserActionTypes.GET_TOKEN_PLAYER_SUCCESS: {
            TypedStorage.set('tokenPlayer', action.payload[1]);
            return {
                ...state,
                tokenPlayer: action.payload[1]
            }
        }

        default: {
            return state;
        }
    }
};

export { reducer as UserReducer };

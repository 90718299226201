export enum IWaveformActionTypes {
    UPDATE_CURSOR = '@@waveform/UPDATE_CURSOR',
}

export interface IWaveformState {
    cursor: number;
    force: boolean;
}

export const CWaveformInitialState: IWaveformState = {
    cursor: 0,
    force: false
}
import * as React from 'react';
import * as MD from '@material-ui/core';
import { History } from 'history';
import { Dispatch, Store } from 'redux';
import { IApplicationState } from '../Store';
import { connect } from 'react-redux';

import { verifyExternalToken } from '../Store/User/Actions';
interface IPropsFromDispatch {
  verifyExternalToken: typeof verifyExternalToken;
}

interface IOwnProps {
  store: Store<IApplicationState>;
  history: History;
}

type AllProps = IPropsFromDispatch & IOwnProps;


class AuthenticationComponent extends React.Component<AllProps> {

    public componentDidMount() {
        this.props.verifyExternalToken();
    }

    public render() {
        return (
          <MD.Grid container direction='column'>
            <MD.Grid item>
                <MD.LinearProgress variant="query" color="secondary" />
            </MD.Grid>
            <MD.Grid item style={{textAlign: 'center', padding: '10px'}}>
                <MD.Typography style={{fontWeight: 100, fontSize: '16px'}}>
                    Vérification des droits en cours...
                </MD.Typography>
            </MD.Grid>
          </MD.Grid>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  verifyExternalToken: () => dispatch(verifyExternalToken()),
});

const Authentication = connect( null, mapDispatchToProps)(AuthenticationComponent);

export { Authentication };

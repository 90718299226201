
import React, { Component } from 'react';
import * as MD from '@material-ui/core';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { IApplicationState } from '../Store';
import { I18N } from '../Store/I18n/Types';
import { formatTime } from '../Utils/Time';


const styles = MD.createStyles({
});

export interface IPropsFromState {
    i18n: I18N;
}
export interface IPropsFromDispatch {
}
export interface IOwnProps {
    index: number;
    selectedMusic: any;
    handleProgressSeek: (e:any, value:any) => void;
}
export interface IState {
}

type AllProps =
    MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    IOwnProps;

export class PlayerTimelineComponent extends Component<AllProps, IState> {

    public constructor(props: AllProps) {
        super(props);
        this.state = {
        }
    }


    componentDidMount() {}

    private buildMarks(min:number) {
        let marks:any[]=[];
        let j = 0;
        for(let i=0; i<=min; i++) {
            marks.push({
                value: j,
                label: formatTime(j)
            })
            j+=60;
        }
        return marks
    }
    public render() {
        const { index, selectedMusic } = this.props;
        const duration = selectedMusic.duration;
        const currentTime = selectedMusic.currentTime;
        
        return (
            duration === 0 ? 
            <div style={{fontSize: 13, fontWeight: 300}} key={index}>
                {''}
            </div> :
            <MD.Box key={index} display="flex" alignItems="center" width="50%" padding="10px"
            style={{margin: "10px", marginTop: "25px"}}
            >
              <MD.Slider
              min={0}
              max={duration}
              valueLabelDisplay="auto"
              valueLabelFormat={(x:any) => formatTime(x)}
              value={currentTime}
              marks={this.buildMarks(Math.floor(duration / 60))}
              onChange={(e:any, value:any) => this.props.handleProgressSeek(e, value)}
              onChangeCommitted={(e:any) => {e.preventDefault(); e.stopPropagation()}}
              />
              <MD.Box minWidth={35} style={{marginBottom:25, marginLeft: 15}}>
                <MD.Typography variant="body2" color="textSecondary">{`${formatTime(duration)}`}</MD.Typography>
              </MD.Box>
            </MD.Box>
        )
    }
}

const mapStateToProps = ({ i18n }: IApplicationState) => ({
    i18n: i18n.i18n,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
});

export const PlayerTimeline =
    connect(mapStateToProps, mapDispatchToProps)(
        MD.withStyles(styles)(PlayerTimelineComponent),
    );

import {
    colors,
    createTheme,
    Theme,
} from '@material-ui/core';

import WebFont from 'webfontloader';

import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';
declare module '@material-ui/core/styles/overrides' {

    type CustomType = {
        MuiPickersBasePicker: {
            pickerView: {
                maxWidth?: string;
            };
        };
    };
    
    type overridesNameToClassKey = {
        [P in keyof Required<MuiPickersOverrides>]: keyof MuiPickersOverrides[P]
    };
    interface ComponentNameToClassKey extends overridesNameToClassKey {}
    export interface ComponentNameToClassKey extends CustomType {}
}

export enum PaletteMode {
    DARK='dark',
    LIGHT='light'
};

interface ICustomThemeDimensionsProps {
    appBarHeight: number;
    drawerWidth: number;
}

interface ICustomThemeColorsProps {
    chipInvalid: string;
    chipInvalidText: string;
    chipValid: string;
    chipValidText: string;
    errorText: string;
    snackErrorBG: string;
    snackErrorText: string;
    snackInfoBG: string;
    snackInfoText: string;
    snackSuccessBG: string;
    snackSuccessText: string;
    accent: string;
}

interface ICustomThemeFontsProps {
    time: string;
}

interface ICustomThemeModalProps {
  maxWidth: string,
  backgroundColor: string,
  padding: string,
  border: string,
  borderRadius: string,
  outline: string,
  maxHeight?: string,
  overflow?: string,
}

interface ICustomThemeProps {
    colors: ICustomThemeColorsProps;
    dimensions: ICustomThemeDimensionsProps;
    fonts: ICustomThemeFontsProps;
    modalContainer: ICustomThemeModalProps;
    modalNewListContainer: ICustomThemeModalProps;
}

interface ICustomProps {
    Adtrack: ICustomThemeProps;
}

export type AdtrackTheme = Theme & ICustomProps;

WebFont.load({
    google: {
        families: [
            'Lato:100,200,300,400',
            'Anonymous Pro:300,400,700',
            'Roboto:300,400,500',
            'monospace',
        ],
    },
});

export const getTheme = (paletteMode?: PaletteMode): AdtrackTheme => {
    const theme = createTheme({
        palette: {
            // Used by `getContrastText()` to maximize the contrast between the background and
            // the text.
            contrastThreshold: 3,
            error: colors.red,
            primary: {
                main: "#C54CFB",
                light: "#C4C4C4",
                dark: "#35394B"

            },
            secondary: {
                main: '#6561E4',
                light: '#42475E',
                dark: '#EEEEEE'
            },
            text: {
                primary: paletteMode === PaletteMode.LIGHT ? '#42475E' : '#EEEEEE',
                secondary: paletteMode === PaletteMode.LIGHT ? '#000000' : '#C4C4C4'
            },
            // Used to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset: 0.2,
            type: paletteMode,
            background: {
                default: paletteMode === PaletteMode.LIGHT ? '#FFFFFF' : '#42475E',
                paper: paletteMode === PaletteMode.LIGHT ? '#EEEEEE' : '#35394B'
            }
        },
        typography: {
            fontFamily: '"Lato"',
        },
        overrides: {
            MuiPickersYear: {
                root: {
                    fontSize: "14px"
                },
                yearSelected: {
                    fontSize: "x-large"
                }
            }
        }
    });

    const custom: ICustomProps = {
        Adtrack: {
            colors: {
                chipInvalid: '#993336',
                chipInvalidText: '#fff',
                chipValid: '#4DCC78',
                chipValidText: '#000',
                errorText: 'red',
                snackErrorBG: '#993336',
                snackErrorText: '#fff',
                snackInfoBG: '#35394B',
                snackInfoText: '#fff',
                snackSuccessBG: '#43a047',
                snackSuccessText: '#fff',
                accent: '#00b0ff'
            },
            dimensions: {
                appBarHeight: 50,
                drawerWidth: 320,
            },
            fonts: {
                time: "'Anonymous Pro', monospace",
            },
            modalContainer: {
              maxWidth: '80%',
              backgroundColor: '#555',
              padding: '15px 15px',
              border: '2px solid #000',
              borderRadius: '5px',
              outline: 'none',
            //   maxHeight: '90vh',
              overflow: 'auto'
            },
            modalNewListContainer: {
              maxWidth: '70%',
              backgroundColor: theme.palette.text.primary,
              padding: '15px 25px',
              border: '2px solid #000',
              borderRadius: '5px',
              outline: 'none'
            }
        },
    };
    return { ...custom, ...theme };
};

export const theme = getTheme(PaletteMode.DARK)
export const lightTheme = getTheme(PaletteMode.LIGHT)


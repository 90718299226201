import { action } from 'typesafe-actions';

import { COUNTRY_OPTIONS, ISearchActionTypes, ISearchParams, ISpotRegroupement, ITimeFilters } from './Types';

export const launchSearch = (params: ISearchParams) => action(ISearchActionTypes.LAUNCH_SEARCH, params);
export const launchSearchSuccess = (results: ISpotRegroupement) => action(ISearchActionTypes.LAUNCH_SEARCH_SUCCESS, results);
export const launchSearchError = () => action(ISearchActionTypes.LAUNCH_SEARCH_ERROR);

export const paginateSearch = (page: number, limit: number) => action(ISearchActionTypes.PAGINATE_SEARCH, [page, limit]);
export const paginateSearchSuccess = (results: ISpotRegroupement) => action(ISearchActionTypes.PAGINATE_SEARCH_SUCCESS, results);
export const paginateSearchError = () => action(ISearchActionTypes.PAGINATE_SEARCH_ERROR);
export const setCountry = (country: COUNTRY_OPTIONS) => action(ISearchActionTypes.SET_COUNTRY, country);

export const getMediaWeb = () => action(ISearchActionTypes.GET_MEDIA_WEB, [])
export const getMediaWebSuccess = (res:any) => action(ISearchActionTypes.GET_MEDIA_WEB_SUCCESS, res)
export const getMediaWebError = (err:any) => action(ISearchActionTypes.GET_MEDIA_WEB_ERROR, err)

export const searchReinitParams = () => action(ISearchActionTypes.SEARCH_REINIT_PARAMS);
export const saveTimeFilters = (filters: ITimeFilters) => action(ISearchActionTypes.SAVE_TIME_SELECTION, filters)
export const updateCountryParams = (country: string) => action(ISearchActionTypes.UPDATE_COUNTRY_PARAMS, country)

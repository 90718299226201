import * as React from 'react';
import { Layout } from '../Components/Layout';
import { setDrawerContent, setPageContent, hideDrawer } from '../Store/Layout/Actions';
import { Route, RouteComponentProps } from 'react-router-dom';
import { IConnectedReduxProps } from '../Store';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { DeclarationContent } from '../Components/Declarations/Content';


interface IPropsFromDispatch {
    setDrawerContent: typeof setDrawerContent;
    setPageContent: typeof setPageContent;
    hideDrawer: typeof hideDrawer;
}

type AllProps =
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class DeclarationsPage extends React.Component<AllProps> {

    componentDidMount() {
      this.props.hideDrawer();
      this.props.setPageContent(<Route component={DeclarationContent} />);
    }
    public render(): React.ReactNode {
      return (
          <Route component={Layout} />
      );
    }
}


const mapDispatchToProps = (dispatch: Dispatch) => ({
    setDrawerContent: (c: React.ReactNode) => dispatch(setDrawerContent(c)),
    setPageContent: (c: React.ReactNode) => dispatch(setPageContent(c)),
    hideDrawer: () => dispatch(hideDrawer()),
});

export const Declarations = connect(null, mapDispatchToProps)(DeclarationsPage);

import React from 'react'
import Paper, { PaperProps } from '@material-ui/core/Paper'
import Draggable from 'react-draggable'

export function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{ overflow: 'hidden', background: 'none', boxShadow: 'none' }}
      />
    </Draggable>
  )
}

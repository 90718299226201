import { ISearchParams } from "../Store/Search/Types";
import { ISpotDetailsRequest } from "../Store/SpotDetails/Types";
import { CPermissionInitial, initialUserInfo, IPermission, IUserInfo } from "../Store/User/Types";
import { PaletteMode } from "../Themes";

//const deserializeBoolean = (value: string) => value === 'true';
const deserializeString = (value: string) => value;
const deserializeNumber = (value: string) => Number.parseFloat(value);
const deserializeObject = (value: string) =>  {
    return JSON.parse(value);
}
const deserializePaletteMode = (value: string) => value as PaletteMode;
//const serializeBoolean = (value: boolean) => value ? 'true' : 'false';
const serializeString = (value: string) => value;
const serializeNumber = (value: number) => value.toString();
const serializeObject = (value: object | null) => JSON.stringify(value);
const serializePaletteMode = (value: PaletteMode) => value as string;

export interface IKeyValueStorage {
    locale: string;
    userDate: number;
    userInfo: IUserInfo;
    userName: string;
    userToken: string;
    savedSearchedParamsState: ISearchParams | null;
    savedSpotDetailsRequest: ISpotDetailsRequest | null;
    theme: PaletteMode;
    permissions: IPermission;
    tokenPlayer: string;
}

const keyValueStorageDeserializers:
    { [key in KeyValueStorageKey]: (value: string) => IKeyValueStorage[key] } = {
        locale: deserializeString,
        userDate: deserializeNumber,
        userInfo: deserializeObject,
        userName: deserializeString,
        userToken: deserializeString,
        savedSearchedParamsState: deserializeObject,
        savedSpotDetailsRequest: deserializeObject,
        theme: deserializePaletteMode,
        permissions: deserializeObject,
        tokenPlayer: deserializeString,
};

export function clearTypedStorage() {
    TypedStorage.set('userName', '');
    TypedStorage.set('userToken', '');
    TypedStorage.set('tokenPlayer', '');
    TypedStorage.set('userDate', 0);
    TypedStorage.set('userInfo', initialUserInfo);
    TypedStorage.set('permissions', CPermissionInitial);
}

const keyValueStorageSerializers:
    { [key in KeyValueStorageKey]: (value: IKeyValueStorage[key]) => string } = {
        locale: serializeString,
        userDate: serializeNumber,
        userInfo: serializeObject,
        userName: serializeString,
        userToken: serializeString,
        savedSearchedParamsState: serializeObject,
        savedSpotDetailsRequest: serializeObject,
        theme: serializePaletteMode,
        permissions: serializeObject,
        tokenPlayer: serializeString,
};

export type KeyValueStorageKey = keyof IKeyValueStorage;

export interface IUserStorage {
    del<T extends KeyValueStorageKey>(key: T): void;
    get<T extends KeyValueStorageKey>(key: T, defaultValue: IKeyValueStorage[T]):
        IKeyValueStorage[T];
    reset(): void;
    set<T extends KeyValueStorageKey>(key: T, value: IKeyValueStorage[T]): void;
}

export const TypedStorage: IUserStorage = {
    del: (key) => { localStorage.removeItem(key); },
    get: (key, defaultValue) => {
        const serialized = window.localStorage.getItem(key);

        if (serialized !== null) {
            return (keyValueStorageDeserializers[key](serialized) as any);
        }
        return defaultValue;
    },
    reset: () => { localStorage.clear(); },
    set: (key, value) => {
        const serializer = (keyValueStorageSerializers[key] as (t: any) => string);
        localStorage.setItem(key, serializer(value));
    },
};

import * as React from 'react'
import { Route } from 'react-router'
import { Router } from './Router'
import { ConnectedRouter } from 'connected-react-router'
import { History } from 'history'
import { Dispatch, Store } from 'redux'
import { IApplicationState } from '../Store'
import { connect, Provider } from 'react-redux'

import { setLocale } from '../Store/I18n/Actions'
import { Locales } from '../Store/I18n/Constants'
import { getTheme } from '../Themes'
import { TypedStorage } from '../Utils/TypedStorage'

import { ThemeProvider } from '@material-ui/core/styles'
import { getTokenPlayer } from '../Store/User/Actions'
import { IUserState } from '../Store/User/Types'

interface IPropsFromDispatch {
  [key: string]: any
}

interface IOwnProps {
  store: Store<IApplicationState>
  history: History
}
interface IPropsFromState {
  user: IUserState
}
type AllProps = IPropsFromDispatch & IOwnProps & IPropsFromState

class MainComponent extends React.Component<AllProps> {
  public componentDidMount() {
    this.props.loadI18n(TypedStorage.get('locale', Locales.French))
    if (
      this.props.user.tokenPlayer === '' &&
      TypedStorage.get('userName', '') !== ''
    ) {
      //this.props.getTokenPlayer(true, true, TypedStorage.get('userName',''))
    }
  }

  public render() {
    const { store, history, theme } = this.props
    return (
      <ThemeProvider theme={getTheme(theme)}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Route component={Router} />
          </ConnectedRouter>
        </Provider>
      </ThemeProvider>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadI18n: (locale: Locales) => dispatch(setLocale(locale)),
  getTokenPlayer: (music: boolean, readjusted: boolean, userName: string) =>
    dispatch(getTokenPlayer(music, readjusted, userName)),
})

const mapStateToProps = ({ layout, user }: IApplicationState) => ({
  theme: layout.theme,
  user,
})

const Main = connect<{}, IPropsFromDispatch, IOwnProps, IApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(MainComponent)

export { Main }

import * as MD from '@material-ui/core'
import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { Dispatch } from 'redux'
import styled from 'styled-components'

import { I18N } from '../Store/I18n/Types'
import { IApplicationState, IConnectedReduxProps } from '../Store'
import { login } from '../Store/User/Actions'
import { SvgIcon } from '@material-ui/core'
import { AdtrackLogoIcon } from '../Themes/svg/adtrack-logo'
import { Config } from '../config'

import { getTheme } from '../Themes'

import MuiTextField from '@material-ui/core/TextField'
import { NotificationBar } from '../Components/NotificationBarComponent'
import { INotificationBarState } from '../Store/NotificationBar/Types'
import { ILayoutState } from '../Store/Layout/Types'
import withStyles, { WithStyles } from '@material-ui/styles/withStyles'

interface IPropsFromState {
  i18n: I18N
  layout: ILayoutState
  notificationBar: INotificationBarState
}

interface IOwnState {
  username: string
  password: string
}

interface IPropsFromDispatch {
  login: typeof login
}

type AllProps = WithStyles<typeof styles> &
  IPropsFromState &
  IPropsFromDispatch &
  RouteComponentProps<{}> &
  IConnectedReduxProps

const theme = getTheme()

const styles = MD.createStyles({
  button: {
    margin: theme.spacing(1),
    marginLeft: 0,
    marginRight: 0,
    textTransform: 'uppercase',
  },
  card: {
    maxWidth: '40%',
    minWidth: '20%',
    marginTop: '50px',
  },
  cardContent: {
    width: '100%',
  },
  errorContainer: {
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    color: 'red',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  media: {
    height: 140,
  },
})

// const TextFieldDensed = MD.withStyles({
//     root: {
//       padding: 0,
//       marginLeft: 0
//     },
//     input: {
//         padding: '0 6px'
//     }
//   })(MuiTextField);

const DensedTextField = styled(MuiTextField)`
  .MuiInputBase-input {
    padding: 4px 6px;
    font-size: 13px;
  }
  .MuiInputLabel-formControl {
    top: -7px;
    font-size: 13px;
    font-weight: 300;
  }
`

export class LoginPage extends React.Component<AllProps, IOwnState> {
  public constructor(props: AllProps) {
    super(props)
    this.state = {
      password: '',
      username: '',
    }
  }

  public render(): React.ReactNode {
    const { classes, layout } = this.props
    const theme = getTheme(layout.theme)
    let errorMessage: any = null
    if (this.props.notificationBar.autherror) {
      errorMessage = this.props.notificationBar.autherror.message as string
    }

    if (errorMessage) {
      errorMessage = this.props.i18n._(errorMessage)
    }

    return (
      <MD.Card className={classes.card}>
        <MD.CardActionArea></MD.CardActionArea>
        <MD.CardActions>
          <MD.CardContent className={classes.cardContent}>
            <MD.Typography gutterBottom={true} variant="h4" component="h2">
              <SvgIcon
                component={AdtrackLogoIcon}
                htmlColor={theme.palette.text.primary}
              />
            </MD.Typography>

            <MD.Typography
              variant="caption"
              color="textSecondary"
              component="p"
            ></MD.Typography>
            <form
              className={classes.form}
              noValidate={true}
              onSubmit={this.onSubmit.bind(this)}
            >
              <DensedTextField
                autoComplete="username"
                required
                label={this.props.i18n._('Username')}
                onChange={this.onUsernameChange.bind(this)}
                value={this.state.username}
              />
              <DensedTextField
                autoComplete="off"
                required
                label={this.props.i18n._('Password')}
                margin="normal"
                onChange={this.onPasswordChange.bind(this)}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    this.onSubmit()
                  }
                }}
                type="password"
                value={this.state.password}
              />
              <MD.Button
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={this.onSubmit.bind(this)}
              >
                {this.props.i18n._('Log in')}
              </MD.Button>
              <MD.Grid item xs style={{ textAlign: 'end' }}>
                <MD.Link
                  target="_blank"
                  href={`${Config.UsersUrl}/reset`}
                  variant="body2"
                >
                  {this.props.i18n._('Forgot password?')}
                </MD.Link>
              </MD.Grid>
              <MD.Grid className={classes.errorContainer}>
                {errorMessage}
              </MD.Grid>
            </form>
          </MD.CardContent>
        </MD.CardActions>

        <MD.CardActions>
          <MD.CardContent className={classes.cardContent}>
            <h3><b>NOUVEAU !</b>
              <br />
              Mise en ligne des nouvelles fonctionnalités AdTrack :</h3>
            <ul>
              <li><p>« Signature électronique » de vos fiches techniques publicitaires</p></li>
              <li><p>« Fingerprint » des musiques originales ou adaptations utilisées en synchros publicitaires</p></li>
            </ul>

          </MD.CardContent>
        </MD.CardActions >

        <NotificationBar />
      </MD.Card>
    )
  }

  private onUsernameChange(ev: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ username: ev.target.value })
  }

  private onPasswordChange(ev: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ password: ev.target.value })
  }

  private onSubmit() {
    this.props.login(this.state.username, this.state.password)
  }
}

const mapStateToProps = ({
  i18n,
  layout,
  notificationBar,
}: IApplicationState) => ({
  i18n: i18n.i18n,
  layout,
  notificationBar,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  login: (u: string, p: string) => dispatch(login(u, p)),
})

export const Login = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LoginPage))
